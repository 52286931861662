import React, {
  setGlobal,
  useGlobal,
  useState,
  useLayoutEffect,
  useEffect,
  useMemo,
  useRef,
} from "reactn";

import { FaInfo, FaCalendarAlt } from "react-icons/fa";

import RegionMap from "components/region-map";
import Timeline from "components/timeline";

import styled from "styled-components";

import {
  languages,
  getLanguage,
  formatDate,
  t,
  tDate,
} from "localization/translate";
import { Box } from "components/box";
import { Title1 } from "components/title1";
import { Title2 } from "components/title2";
import { Subtitle } from "components/subtitle";

const { default: flip } = require("@turf/flip");
import {
  INTERVALS_CASES,
  INTERVALS_TESTS,
  COLOR_SECONDARY1,
  COLOR_PRIMARY,
  COLOR_SECONDARY2,
} from "variables";

const geoBoundaries = flip(require("data/spatial/geo_czechia_bounds.json"))
  .features[0];

const czechiaDict = require("data/dictionaries/czechia_orp.json");
const dataGeo = flip(require("data/spatial/geo_czechia_orp_simplified.json"));

//interface ICzechiaPageProps extends RouteComponentProps {}
interface ICzechiaPageProps { }

import { IInterval, ITopic, IDataValue, IDataRegion } from "models";
import { useQuery, useQueryClient } from "react-query";
import api from "api";
import classNames from "classnames";
import { ThreeDots } from "react-loading-icons";

const initialTopics: ITopic[] = [
  {
    id: "cases",
    data: {},
    intervals: INTERVALS_CASES,
    timelineTop: 2500,
    timelineTickValues: [500, 1000, 1500, 2000, 2500],
    buttonId: "cases-button",
    titleId: "cases-title",
    subtitleId: "cases-subtitle",
    legendTitleId: "cases-legend-title",
  },
  {
    id: "tests",
    data: {},
    intervals: INTERVALS_TESTS,
    timelineTop: 2500,
    timelineTickValues: [500, 1000, 1500, 2000, 2500],
    buttonId: "tests-button",
    titleId: "tests-title",
    subtitleId: "tests-subtitle",
    legendTitleId: "tests-legend-title",
  },
];

const CzechiaPage = ({ }: ICzechiaPageProps) => {
  const [lang, setLang] = useGlobal("lang");

  // load cases data
  const queryClient = useQueryClient();
  const {
    status: statusCases,
    data: dataCases,
    error: errorCases,
    isFetching: isFetchingCases,
  } = useQuery(
    ["cases"],
    async (): Promise<IDataRegion> => {
      const res = await api.czechiaCasesForecast();
      return res;
    },
    { enabled: true, initialData: {} }
  );

  // load tests data
  const {
    status: statusTests,
    data: dataTests,
    error: errorTests,
    isFetching: isFetchingTests,
  } = useQuery(
    ["tests"],
    async (): Promise<IDataRegion> => {
      const res = await api.czechiaTestsForecast();
      return res;
    },
    { enabled: true, initialData: {} }
  );

  const isDataLoaded = useMemo(() => {
    return (
      !!dataCases && !!dataTests && "1000" in dataCases && "1000" in dataTests
    );
  }, [dataCases, dataTests]);

  // when new cases data are loaded, put them into the topic state object
  useEffect(() => {
    if (dataCases) {
      const newTopics = [...topics];
      newTopics[0]["data"] = dataCases;
      setTopics(newTopics);
    }
  }, [dataCases, dataTests]);

  // when new tests data are loaded, put them into the topic state object
  useEffect(() => {
    if (dataTests) {
      const newTopics = [...topics];
      newTopics[1]["data"] = dataTests;
      setTopics(newTopics);
    }
  }, [dataCases, dataTests]);

  const allDates = useMemo(() => {
    return isDataLoaded && dataCases ? Object.keys(dataCases["1000"]) : [];
  }, [dataCases, dataTests]);

  const nowDate = useMemo(
    () => (allDates.length > 5 ? allDates[allDates.length - 4] : ""),
    [allDates]
  );

  useEffect(() => {
    if (allDates.length > 5 && selectedDate === "")
      setSelectedDate(allDates[allDates.length - 3]);
  }, [allDates]);

  const [topics, setTopics] = useState<ITopic[]>(initialTopics);

  const wrapperRef = useRef<HTMLDivElement>(null);
  const [wrapperWidth, setWrapperWidth] = useState(0);
  const [selectedRegion, setSelectedRegion] = useState<
    { id: string; fullname: string; id_ruian: string } | false
  >(false);

  const [selectedDate, setSelectedDate] = useState<string>("");
  const [selectedTopic, setSelectedTopic] = useState<ITopic>(topics[0]);
  const [helpModalOpen, setHelpModalOpen] = useState<boolean>(false);

  useEffect(() => {
    const handleResize = () => {
      if (wrapperRef.current) {
        //console.log("setting width", wrapperRef.current.offsetWidth);
        setWrapperWidth(wrapperRef.current.offsetWidth);
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
  }, [wrapperRef.current]);

  const selectRegion = (newId: string | false) => {
    setSelectedRegion(
      czechiaDict.find(
        (r: { id: string; fullname: string; id_ruian: string }) => r.id == newId
      ) || false
    );
  };

  const allRegionsTranslation = t("czechia", "allregions", lang, {}) as string;

  const timelineData = useMemo(() => {
    if (dataCases && dataTests && isDataLoaded) {
      if (1000 in selectedTopic.data) {
        const allCzechiaData = selectedTopic.data[1000];
        const datasets = [
          {
            id: "1",
            label: allRegionsTranslation,
            color: COLOR_SECONDARY1,
            data: allCzechiaData,
            displayPolygon: true,
          },
        ];
        if (selectedRegion) {
          datasets.push({
            id: "2",
            label: selectedRegion.fullname,
            color: COLOR_PRIMARY,
            data: selectedTopic.data[selectedRegion.id],
            displayPolygon: true,
          });
        }
        return datasets;
      }
    }
    return [];
  }, [dataCases, dataTests, selectedRegion, selectedTopic, lang, topics]);

  const mapData = useMemo(() => {
    const regionsData: { [id: string]: { v: number; c: number } } = {};

    Object.keys(selectedTopic.data).forEach((regionId: string) => {
      const regionData: IDataValue = selectedTopic.data[regionId][selectedDate];
      if (regionData) {
        regionsData[regionId] = {
          v: regionData.m,
          c: regionData.ch,
        };
      }
    });
    return regionsData;
  }, [selectedDate, selectedTopic]);

  const renderHelpModal = () => {
    return (
      <ModalDiv className="modal is-active">
        <ModalBackgroundDiv
          onClick={() => {
            setHelpModalOpen(false);
          }}
        />
        <ModalCardDiv>
          <ModalCardHeader>
            <div className="modal-card-title">
              <Title2>{t("czechia", "instructions-title", lang)}</Title2>
            </div>
            <button
              className="delete"
              aria-label="close"
              onClick={() => {
                setHelpModalOpen(false);
              }}
            ></button>
          </ModalCardHeader>
          <ModalCardBody>
            {t("czechia", "instructions-content", lang)}
          </ModalCardBody>
        </ModalCardDiv>
      </ModalDiv>
    );
  };

  const renderDataProblem = () => {
    return (errorCases || errorTests ? (
      <article className="message is-danger">
        <div className="message-header">
          <p>{t("general", "dataproblem-title", lang)}</p>
        </div>
        <div className="message-body">
          {t("general", "dataproblem-text", lang)}
        </div>
      </article>

    ) : (<div />))
  };
  const renderDataLoading = () => {
    return (isFetchingTests || isFetchingCases ? (
      <ThreeDots fill={COLOR_PRIMARY}
        style={{
          height: "2em",
          verticalAlign: "middle",
          margin: "0 auto",
        }}
        stroke={COLOR_PRIMARY} />

    ) : (<div />))
  };

  console.log(selectedDate);

  return (
    <WrapperDiv ref={wrapperRef}>
      {helpModalOpen && renderHelpModal()}

      <HeaderSection>
        {isDataLoaded && (
          <TopicSelectionSection>
            {/* <Title2>{translate("czechia", "select_topic")}</Title2> */}

            <TopicSelectButtonGroup>
              {topics.map((topic) => {
                return (
                  <TopicButton
                    selected={selectedTopic === topic}
                    key={topic.id}
                    onClick={() => setSelectedTopic(topic)}
                  >
                    {t("czechia", topic.buttonId, lang)}
                  </TopicButton>
                );
              })}
            </TopicSelectButtonGroup>
          </TopicSelectionSection>
        )}
        <InstructionsButton onClick={() => setHelpModalOpen(true)}>
          <InstructionsButtonIcon>
            <FaInfo />
          </InstructionsButtonIcon>
          {t("czechia", "instructions-title", lang)}
        </InstructionsButton>
        {isDataLoaded && (
          <DateSelect>
            <div className="control has-icons-left">
              <div className="select">
                <select
                  value={selectedDate}
                  onChange={(e: React.FormEvent<HTMLSelectElement>) => {
                    const newValue = e.currentTarget.value;
                    setSelectedDate(newValue);
                  }}
                >
                  {allDates.map((date, di) => {
                    const toDate = tDate(date, lang);
                    const fromDate = tDate(date, lang, {
                      delta: { days: -7 },
                    });

                    return (
                      <option
                        value={date}
                        key={di}
                      >{`${fromDate} - ${toDate}`}</option>
                    );
                  })}
                </select>
              </div>
              <div className="icon is-small is-left">
                <FaCalendarAlt />
              </div>
            </div>
          </DateSelect>
        )}
        <Title1>{t("czechia", selectedTopic.titleId, lang)}</Title1>
        <Subtitle>{t("czechia", selectedTopic.subtitleId, lang)}</Subtitle>
      </HeaderSection>

      {renderDataLoading()}
      {renderDataProblem()}

      {isDataLoaded && timelineData && selectedDate && (
        <TimelineDiv>
          <Box>
            {isDataLoaded && (
              <Timeline
                width={wrapperWidth}
                height={400}
                lines={timelineData}
                timeSlices={allDates}
                todaySlice={nowDate}
                selectedSlice={selectedDate}
                topValue={selectedTopic.timelineTop}
                tickValues={selectedTopic.timelineTickValues}
                handleChangeSelected={(newDate: string) => {
                  setSelectedDate(newDate);
                }}
              />
            )}
          </Box>
        </TimelineDiv>
      )}

      {isDataLoaded && (
        <MapDiv>
          <Box>
            <RegionMap
              center={[49.8, 15.5]}
              width="100%"
              height="700px"
              regionsDict={czechiaDict}
              regionsGeo={
                dataGeo.features as {
                  properties: { region: string };
                  geometry: { coordinates: [][] };
                }[]
              }
              boundaries={geoBoundaries}
              data={mapData}
              intervals={selectedTopic.intervals}
              selectedRegionId={selectedRegion ? selectedRegion.id : false}
              legendTitle={
                t("czechia", selectedTopic.legendTitleId, lang) as string
              }
              selectedColor={COLOR_PRIMARY}
              selectRegion={(newRegionId) => {
                selectRegion(newRegionId);
              }}
            />
          </Box>
        </MapDiv>
      )}
      {isDataLoaded && (
        <FooterDiv>
          <Box>
            <div>{t("czechia", "footer", lang) as string}</div>
          </Box>
        </FooterDiv>
      )}
    </WrapperDiv>
  );
};

const HeaderSection = styled.div`
  margin: 10px;
  padding-top: 20px;
  padding-bottom: 10px;
`;

const DateSelect = styled.div.attrs(() => ({
  className: "",
}))`
  margin-top: 2.5em;
  margin-bottom: -2em;
  .control .icon {
    color: black;
  }
`;

const WrapperDiv = styled.div.attrs(() => ({
  className: "content",
}))`
  padding-top: 2em;
`;

const TimelineDiv = styled.div``;

const MapDiv = styled.div``;

const TopicSelectionSection = styled.div`
  margin-top: -30px;
  margin-bottom: 20px;
`;

interface ITopicButton {
  selected: boolean;
}

const TopicButton = styled.button.attrs(({ selected }: ITopicButton) => ({
  className: "button is-medium",
}))`
  background-color: ${({ selected }: ITopicButton) =>
    selected ? COLOR_PRIMARY : "white"};
  color: ${({ selected }: ITopicButton) =>
    selected ? "white" : COLOR_PRIMARY};
  font-weight: ${({ selected }: ITopicButton) =>
    selected ? "bold" : "normal"};
  text-decoration: ${({ selected }) => (selected ? "underline" : "none")};
`;

const TopicSelectButtonGroup = styled.div.attrs(({ }: {}) => ({
  className: "buttons has-addons",
}))``;

const FooterDiv = styled.div``;

/**
 * Instructions button
 */
const InstructionsButton = styled.button.attrs(({ }: {}) => ({
  className: `button`,
}))`
  margin-bottom: -2rem;
  display: block;
  font-size: 13px;
  background: ${COLOR_SECONDARY2};
  color: white;
  font-weight: bold;
  border: none;
`;

const InstructionsButtonIcon = styled.span.attrs(({ }: {}) => ({}))`
  display: inline;
  margin-right: 5px;
  font-size: 13px;
  vertical-align: middle;
`;

/**
 * Modal
 */
const ModalDiv = styled.div.attrs(({ active }: { active: boolean }) => ({
  className: `modal ${active && "is-active"}`,
}))``;

const ModalBackgroundDiv = styled.div.attrs(({ }) => ({
  className: "modal-background",
}))`
  z-index: 30;
`;

const ModalCardDiv = styled.div.attrs(({ }) => ({
  className: "modal-card",
}))`
  z-index: 40;
`;
const ModalCardHeader = styled.div.attrs(({ }) => ({
  className: "modal-card-head",
}))`
  z-index: 40;
  background-color: ${COLOR_SECONDARY2};
  .modal-card-title {
    .title {
      color: white;
    }
  }
`;
const ModalCardBody = styled.section.attrs(({ }) => ({
  className: "modal-card-body",
}))`
  z-index: 40;
  .button-like {
    background-color: ${COLOR_PRIMARY};
    color: white;
    justify-content: center;
    padding: 4px 8px;
    border-radius: 2px;
    text-align: center;
  }
`;

//export default withRouter(CzechiaPage);
export default CzechiaPage;
