import React, { setGlobal, useGlobal } from "reactn";
import * as ReactDOM from "react-dom/client";

import { App } from "./app";
import "main.sass";

import SaxonyPage from "pages/saxony";
import CzechiaPage from "pages/czechia";
import AboutPage from "pages/about";
import TeamPage from "pages/team";
import ContactPage from "pages/contact";
import NewsPage from "pages/news";
import OptimizationPage from "pages/optimization";
import PublicationsPage from "pages/publications";
import RiskCalculatorPage from "pages/riskcalculator";
import RetirementPage from "pages/retirement";
import DolnoslaskiPage from "pages/dolnoslaski";
import CorrelationPage from "pages/correlation";
import WastewaterPage from "pages/wastewater";
import COVIDSimPage from "pages/covidsim";
import { envelope } from "turf";

export const tabs = [
  {
    id: "retirement",
    url: "/retirement",
    label: "Retirement Home",
    component: RetirementPage,
  },
  {
    id: "riskcalculator",
    url: "/riskcalculator",
    label: "Office Strategy Calculator",
    component: RiskCalculatorPage,
  },
  {
    id: "optimization",
    url: "/optimization",
    label: "Optimization",
    component: OptimizationPage,
  },
  {
    id: "saxony",
    url: "/saxony",
    label: "Number of Cases in Saxony",
    component: SaxonyPage,
    group: "maps",
  },
  {
    id: "czechia",
    url: "/czechia",
    label: "Czechia",
    component: CzechiaPage,
    group: "maps",
  },
  {
    id: "dolnoslaski",
    url: "/dolnoslaski",
    label: "Dolnoslaski",
    component: DolnoslaskiPage,
    group: "maps",
  },
  {
    id: "correlation",
    url: "/correlation",
    label: "Region Correlation",
    component: CorrelationPage,
    group: "maps",
    hideInNavBar: true,
  },
  {
    id: "wastewater",
    url: "/wastewater",
    label: "Wastewater",
    component: WastewaterPage,
    group: "maps",
  },
  {
    id: "blog",
    url: "/blog",
    label: "Blog",
    component: NewsPage,
    group: "more",
  },
  {
    id: "about",
    url: "/about",
    label: "About Us",
    component: AboutPage,
    group: "more",
  },
  {
    id: "contact",
    url: "/contact",
    label: "Contact",
    component: ContactPage,
    group: "more",
  },
  {
    id: "covidsim",
    url: "/covidsim",
    label: "COVID Sim",
    component: COVIDSimPage,
    group: "maps",
  },
  // {
  //   id: "team",
  //   label: "Team",
  //   component: TeamPage,
  //   group: "more",
  // },
  // {
  //     id: "publications",
  //     label: "Publications",
  //     component: PublicationsPage,
  // },
];

const initialPath = window.location.pathname;
const initialTab = tabs.find((tab) => tab.url === initialPath);
if (initialTab) {
  setGlobal({
    activeTab: initialTab.id,
    lang: "de",
  });
} else {
  setGlobal({
    activeTab: "retirement",
    lang: "de",
  });
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(<App />);
