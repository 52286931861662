import React from "react";

import styled from "styled-components";

import { COLOR_SECONDARY1 } from "variables";

export const Title2 = ({ children }: { children: React.ReactNode }) => {
  return <Title2Div>{children}</Title2Div>;
};

const Title2Div = styled.div.attrs(({ color }: { color: string }) => ({
  className: "title is-4",
}))`
  font-size: 1.5em;
  font-weight: bold;
  color: ${({ color }) => color || COLOR_SECONDARY1};
  //text-transform: capitalize;
`;
