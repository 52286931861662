import api from "api";
import { Box } from "components/box";
import { Modal } from "components/modal";
import { ParameterView } from "components/parameterview";
import { Title } from "components/title";
import { t } from "localization/translate";
import { IDataRegion, IDataRegionOneTime } from "models";
import { BiNetworkChart, BiTestTube } from "react-icons/bi";
import { BsInfoCircle, BsInfoCircleFill } from "react-icons/bs";
import {
  FaBook,
  FaBriefcase,
  FaInfo,
  FaMap,
  FaSyringe,
  FaUserCog,
  FaUsers,
  FaUsersCog,
  FaViruses,
} from "react-icons/fa";
import { MdPlace, MdWarning } from "react-icons/md";
import { Circle, Group, Layer, Line, Stage, Text } from "react-konva";
//import { withRouter, RouteComponentProps } from "react-router-dom";
import {
  MapContainer,
  Pane,
  Polygon,
  TileLayer,
  Tooltip as LeafletTooltip,
} from "react-leaflet";
import { useQuery, useQueryClient } from "react-query";
import React, { useEffect, useGlobal, useMemo, useRef, useState } from "reactn";
import styled from "styled-components";
import {
  COLOR_PRIMARY,
  COLOR_SECONDARY1,
  COLOR_SECONDARY2,
  INTERVALS_CASES,
} from "variables";

const { default: flip } = require("@turf/flip");

const saxonyDict = require("data/dictionaries/saxony_county.json");
const dataGeo = flip(require("data/spatial/geo_saxony_county_simplified.json"));
const geoBoundaries = flip(require("data/spatial/geo_saxony_bounds.json"))
  .features[0];

//interface OptimizationPageProps extends RouteComponentProps {}
interface OptimizationPageProps { }

const contactsHigh = 0.15;
const contactsMid = 0.1;
const contactsLow = 0.05;

const entryPassword = "n2PGztBp";

const contactRates = [
  { id: "high", value: contactsHigh, color: "#d7191c", label: "high" },
  { id: "mid", value: contactsMid, color: "#fdae61", label: "mid" },
  { id: "low", value: contactsLow, color: "#a6d96a", label: "low" },
];

const defaultRegion = "14000";
const round = (value: string | number) => {
  var number: number = +value;
  return Math.trunc(Math.round(number));
};

const validate = (
  value: number | string,
  minValue: number,
  maxValue: number
) => {
  let newValue = parseFloat(value as string);
  if (newValue > maxValue) {
    newValue = maxValue;
  }
  if (newValue < minValue) {
    newValue = minValue;
  }
  return newValue;
};

/* CHART */
const margins = {
  t: 30,
  l: 35,
  r: 30,
  b: 60,
  s: 60, // vertical space between charts
};

interface ChartCircleProps {
  x: number;
  y: number;
  fill: string;
}
const ChartCircle = ({ x, y, fill }: ChartCircleProps) => {
  return (
    <Circle
      y={y}
      x={x}
      radius={5}
      stroke={"white"}
      fill={fill}
      strokeWidth={1.5}
    // shadowEnabled={true}
    // shadowColor={"black"}
    // shadowBlur={3}
    // shadowOffset={{ x: 2, y: 2 }}
    //shadowOpacity={0.5}
    />
  );
};

interface IDataLineProps {
  points: any;
  stroke: string;
}

const DataLine = ({ points, stroke }: IDataLineProps) => {
  return (
    <Line
      points={points}
      stroke={stroke}
      dash={[6, 3]}
      strokeWidth={1.5}
      shadowEnabled={true}
      shadowColor={"black"}
      shadowBlur={3}
      shadowOffset={{ x: 2, y: 2 }}
      shadowOpacity={0.5}
    />
  );
};

interface HaloTextProps {
  text: string;
  x: number;
  y: number;
  fill: string;
}
const HaloText = ({ text, x, y, fill }: HaloTextProps) => {
  return (
    <Text
      fontSize={14}
      text={text}
      x={x}
      y={y}
      fill={fill}
      align="left"
      fontFamily="Ubuntu"
      strokeWidth={3}
      fillAfterStrokeEnabled
      stroke="white"
    />
  );
};

const OptimizationPage = ({ }: OptimizationPageProps) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [wPage, setWPage] = useState<number>(0);

  // parameters
  const [parHW, setParHW] = useState(0.5);
  const [parNoE, setParNoE] = useState(250);
  const [parInc, setParInc] = useState(500);
  const [parVacc, setParVacc] = useState(60);
  //const [parVacc, setParVacc] = useState(100);
  //const [parVaccLocal, setParVaccLocal] = useState(defaultVaccinationRate);
  const [parTest, setParTest] = useState(14);
  const [parMutation, setParMutation] = useState(0);
  const [parContactRate, setParContactRate] = useState("high");

  const [entered, setEntered] = useState<boolean>(true);
  const [displayErrorLogin, setDisplayErrorLogin] = useState<boolean>(false);
  const [enterPasswordTyped, setEnterPasswordTyped] = useState<string>("");

  const [lang, setLang] = useGlobal("lang");
  //const [incidenceMap, setIncidenceMap] = useState<false | any>(false);

  // load data
  const queryClient = useQueryClient();
  const {
    status: statusCases,
    data: thisWeekIncidence,
    error: errorCases,
    isFetching: isFetchingCases,
  } = useQuery(
    ['incidence'],
    async (): Promise<IDataRegionOneTime> => {
      const res = await api.saxonyCasesForecast();
      const regions = Object.keys(res);
      const dates = Object.keys(res[regions[0]]);
      const thisWeekDate = dates[dates.length - 5];

      const thisWeekIncidence: IDataRegionOneTime = {};
      regions.forEach(
        (region) => (thisWeekIncidence[region] = res[region][thisWeekDate].m)
      );
      return thisWeekIncidence;
      //return {} as IDataRegion;

    },
    { enabled: true, initialData: {} as IDataRegionOneTime }
  );


  const getKappaInterval = (
    contactRateCategory: {
      id: string;
      value: number;
      color: string;
      label: string;
    },
    employees_all: number,
    occup: number,
    scale: number
  ) => {
    const kappaLow = getKappa(
      contactRates[2]["value"],
      employees_all,
      occup,
      scale
    );
    const kappaMedium = getKappa(
      contactRates[1]["value"],
      employees_all,
      occup,
      scale
    );
    const kappaHigh = getKappa(
      contactRates[0]["value"],
      employees_all,
      occup,
      scale
    );

    const i1t = Math.floor(kappaLow + (kappaMedium - kappaLow) / 2);
    const i2t = Math.ceil(kappaMedium + (kappaHigh - kappaMedium) / 2);

    if (contactRateCategory["id"] === "low") {
      return `0-${i1t}`;
    }
    if (contactRateCategory["id"] === "mid") {
      return `${i1t}-${i2t}`;
    }
    if (contactRateCategory["id"] === "high") {
      return `${t("optimization", "summary-kappa-interval-more", lang)} ${i2t}`;
    }
    return ``;
  };

  // parMutation = 0
  // 0.1
  const betau = useMemo(() => {
    return (0.04 * parMutation) / 100 + (1 - parMutation / 100) * 0.1;
  }, [parMutation]);

  // 0.03
  const betav = useMemo(() => {
    return (1 - 0.7) * betau;
  }, [betau]);

  const [parIncMapOpen, setParIncMapOpen] = useState<boolean>(false);
  const [parIncMapRegionSelected, setParIncMapRegionSelected] =
    useState<string>(defaultRegion);

  const [inputModalOpen, setInputModalOpen] = useState<boolean>(false);
  const [helpModalOpen, setHelpModalOpen] = useState<boolean>(false);
  const [exampleModalOpen, setExampleModalOpen] = useState<boolean>(false);
  const [warningModalOpen, setWarningModalOpen] = useState<boolean>(true);

  const [mouseOcc, setMouseOcc] = useState<false | number>(50);

  const [customStrategyActive, setcustomStrategyActive] =
    useState<boolean>(false);

  const [extendedParamsDisplay, setExtendedParamsDisplay] =
    useState<boolean>(false);

  const tryToEnter = () => {
    if (enterPasswordTyped === entryPassword) {
      setEntered(true);
      setWarningModalOpen(true);
    } else {
      setDisplayErrorLogin(true);
      setTimeout(() => {
        setDisplayErrorLogin(false);
      }, 2000);
    }
    setEnterPasswordTyped("");
  };

  const tickFontSize = 14;
  const tickFontSizeSmall = 12;

  // open disclaimer when the language is changed
  useEffect(() => {
    setWarningModalOpen(true);
  }, [lang]);

  // background risk in percents
  const backgroundRisk = useMemo(() => {
    return (parInc / 100000) * 100;
  }, [parInc]);

  const contactRateCategory = useMemo(() => {
    return (
      contactRates.find((cr) => cr.id === parContactRate) || contactRates[0]
    );
  }, [parContactRate]);

  // const maxInf = useMemo(() => {
  //   return backgroundRisk * 4;
  // }, [backgroundRisk]);

  const [maxInf, setMaxInf] = useState<number>(1);

  const maxProd = 100;

  // const tickY1Values: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((v) => {
  //   const firstChar = Number(maxInf.toExponential(0).charAt(0));

  //   const multiplier = firstChar < 5 ? 0.5 : 1;
  //   return (
  //     multiplier *
  //     Number(((v * Number(maxInf.toPrecision(1))) / firstChar).toPrecision(1))
  //   );
  // });
  // const tickY2Values: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((v) => {
  //   const firstChar = Number(maxProd.toExponential(0).charAt(0));

  //   const multiplier = firstChar < 5 ? 0.5 : 1;
  //   return (
  //     multiplier *
  //     Number(((v * Number(maxProd.toPrecision(1))) / firstChar).toPrecision(1))
  //   );
  // });

  const xSlices = 101;

  //wPage
  const wSummary = useMemo(() => {
    return wPage / 2;
  }, [wPage]);

  const wChartWrapper = useMemo(() => {
    return wPage / 2;
  }, [wPage]);

  const wChart = useMemo(() => {
    return wPage / 2 - margins.r - margins.l;
  }, [wPage]);

  const hChartWrapper = useMemo(() => {
    return 400;
  }, [wPage]);

  const hChart = useMemo(() => {
    return (hChartWrapper - margins.t - margins.b - margins.s) / 2;
  }, [hChartWrapper]);

  // infection
  const oneValueH1: number = useMemo(() => {
    return hChart / maxInf;
  }, [hChart, maxInf]);

  const oneValueH2: number = useMemo(() => {
    return hChart / maxProd;
  }, [hChart, maxInf]);

  const oneSliceW: number = useMemo(() => {
    return wChart / xSlices;
  }, [xSlices, wChart]);

  const xCoord = (val: number) => margins.l + oneSliceW * val;

  // top = infection
  const yInfCoord = (val: number) => {
    // console.log(val);
    return margins.t + (maxInf - val) * oneValueH1;
  };

  // bottom = productivity
  const yProdCoord = (val: number) => {
    return margins.t + hChart + margins.s + (maxProd - val) * oneValueH2;
  };

  // mouseover
  // const handleChartMove = (e: KonvaEventObject<MouseEvent>) => {
  //   //@ts-ignore
  //   const offX = e.evt.layerX;
  //   if (offX < wChartWrapper) {
  //     const chartMouseX = offX - margins.l;
  //     const occMouse = round(100 * (chartMouseX / wChart));
  //     if (occMouse > 1 && occMouse < 101) {
  //       setMouseOcc(occMouse);
  //     }
  //   }
  // };
  // const handleChartOut = () => {
  //   setMouseOcc(false);
  // };

  const strategy = useMemo(() => {
    const vaccinated = Math.round(parNoE * (parVacc / 100));
    const infProbability = backgroundRisk / 700;

    const [occs, prods, infs] = calculateStrategyOutcomes(
      parNoE,
      vaccinated,
      betau,
      betav,
      contactRateCategory ? contactRateCategory.value : 0.15,
      parHW,
      infProbability,
      parTest
    );

    setMaxInf(infs[100]);

    const backgroundRiskY2 = yInfCoord(backgroundRisk);

    const firstInfOver = infs.find((i) => i > backgroundRisk);
    const backgroundRiskOcc = firstInfOver ? infs.indexOf(firstInfOver) - 1 : 0;
    const backgroundRiskProd = prods[backgroundRiskOcc];

    const backgroundRiskX = xCoord(backgroundRiskOcc);
    const backgroundRiskY1 = yProdCoord(backgroundRiskProd);

    const backgroundRiskAreaXY = [backgroundRiskX, backgroundRiskY2];
    infs.forEach((inf, i) => {
      if (i > backgroundRiskOcc) {
        backgroundRiskAreaXY.push(xCoord(i));
        backgroundRiskAreaXY.push(yInfCoord(inf));
      }
    });
    backgroundRiskAreaXY.push(xCoord(101));
    backgroundRiskAreaXY.push(backgroundRiskY2);

    // maxProd solutions
    const maxProdOccs = occs.filter((occ, i) => prods[i + 1] > prods[i]);

    const maxProdIndex = prods.indexOf(Math.max(...prods));

    const maxProdOcc = occs[maxProdIndex];
    const maxProdProd = prods[maxProdOcc];
    const maxProdInf = infs[maxProdOcc];
    const maxProdX = xCoord(maxProdOcc);
    const maxProdY1 = yProdCoord(maxProdProd);
    const maxProdY2 = yInfCoord(maxProdInf);

    const lprod = occs
      .map((occ, i) => [xCoord(occ), yProdCoord(prods[i])])
      //.filter(([x, y]) => y < margins.t + hChart)
      .reduce((flat, val) => flat.concat(val), []);

    const linf = occs
      .map((occ, i) => [xCoord(occ), yInfCoord(infs[i])])
      .reduce((flat, val) => flat.concat(val), []);

    return {
      prods,
      infs,
      occs,
      backgroundRisk: {
        x: backgroundRiskX,
        yprod: backgroundRiskY1,
        yinf: backgroundRiskY2,
        occ: backgroundRiskOcc,
        prod: round(backgroundRiskProd),
        inf: backgroundRisk,
      },
      maxprod: {
        x: maxProdX,
        yprod: maxProdY1,
        yinf: maxProdY2,
        occ: maxProdOcc,
        prod: round(maxProdProd),
        inf: maxProdInf,
      },
      anrisk: backgroundRiskAreaXY,
      lprod: lprod,
      linf: linf,
    };
  }, [
    parNoE,
    parVacc,
    parHW,
    parTest,
    parInc,
    parContactRate,
    parMutation,
    betau,
    betav,
    wChart,
    hChart,
    backgroundRisk,
    maxInf,
  ]);

  const mouseStrategy = useMemo(() => {
    if (mouseOcc !== false) {
      const mouseProd = strategy.prods[mouseOcc];
      const mouseInf = strategy.infs[mouseOcc];
      const mouseX = xCoord(mouseOcc);
      const mouseY1 = yProdCoord(mouseProd);
      const mouseY2 = yInfCoord(mouseInf);

      return {
        x: mouseX,
        yprod: mouseY1,
        yinf: mouseY2,
        occ: mouseOcc,
        prod: round(mouseProd),
        inf: mouseInf,
      };
    } else {
      return {
        x: -1,
        yprod: -1,
        yinf: -1,
        occ: 0,
        prod: 0,
        inf: 0,
      };
    }
  }, [strategy, mouseOcc, customStrategyActive]);

  //console.log(results);

  useEffect(() => {
    const handleResize = () => {
      if (wrapperRef.current) {
        //console.log("setting width", wrapperRef.current.offsetWidth);
        setWPage(wrapperRef.current.offsetWidth);
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
  }, [wrapperRef.current]);

  const handleClickIncidenceRegion = (regionId: any) => {
    const newRegionId =
      regionId && thisWeekIncidence
        ? regionId !== parIncMapRegionSelected && regionId in thisWeekIncidence
          ? regionId
          : "14000"
        : "";

    if (newRegionId && thisWeekIncidence) {
      const incidence = thisWeekIncidence[newRegionId];
      setParInc(Math.round(incidence));
    }
    setParIncMapRegionSelected(newRegionId);
  };

  const resetRegionSelected = () => {
    setParIncMapRegionSelected("");
  };

  const renderWarningModal = () => {
    return (
      <Modal
        heading={t("optimization", "warning-title-1", lang) as string}
        headerColor={COLOR_PRIMARY}
        buttons={[
          {
            handleClick: () => {
              setWarningModalOpen(false);
            },
            class: "is-primary",
            text: t("optimization", "warning-agree", lang) as string,
          },
        ]}
        renderBody={() => (
          <React.Fragment>
            <InstructionsSection>
              <InstructionsParagraph>
                {t("optimization", "warning-text-1", lang)}
              </InstructionsParagraph>
            </InstructionsSection>
            <InstructionsSection>
              <InstructionsTitle>
                <Title size="m">
                  {t("optimization", "warning-title-2", lang)}
                </Title>
              </InstructionsTitle>
              <InstructionsParagraph>
                <ul>
                  {(t("optimization", "warning-text-2", lang) as string)
                    .split("•")
                    .filter((l, li) => li != 0)
                    .map((l, li) => {
                      return <li key={li}>{l}</li>;
                    })}
                </ul>
              </InstructionsParagraph>
            </InstructionsSection>
          </React.Fragment>
        )}
      />
    );
  };

  const renderInputModal = () => {
    return (
      <Modal
        heading={t("optimization", "input-section-title", lang)}
        handleExit={() => setInputModalOpen(false)}
        headerColor={COLOR_SECONDARY1}
        buttons={[]}
        renderBody={() => (
          <React.Fragment>
            <ModalSectionInput>
              <InputParameterDiv
                ParameterIcon={FaUsers}
                value={round(parNoE)}
                setValue={(v: any) => {
                  setParNoE(validate(v, 20, 990));
                }}
                title={t("optimization", "par-employees-title", lang) as string}
                description={
                  t("optimization", "par-employees-description", lang) as string
                }
                valueMin={100}
                valueStep={10}
                valueMax={990}
              />

              {/* vaccinated */}
              <InputParameterDiv
                ParameterIcon={FaSyringe}
                value={parVacc}
                setValue={(v: any) => {
                  setParVacc(validate(v, 5, 100));
                }}
                title={
                  t("optimization", "par-vaccinated-title", lang) as string
                }
                description={
                  t(
                    "optimization",
                    "par-vaccinated-description",
                    lang
                  ) as string
                }
                valueMin={5}
                valueStep={5}
                valueMax={100}
              />

              {/* Region characteristics */}

              {/* incidence */}
              <InputParameterDiv
                ParameterIcon={MdPlace}
                value={parInc}
                setValue={(v: any) => {
                  setParInc(validate(v, 50, 3000));
                }}
                title={t("optimization", "par-incidence-title", lang) as string}
                description={
                  t("optimization", "par-incidence-description", lang) as string
                }
                valueMin={50}
                valueStep={50}
                valueMax={3000}
              />
            </ModalSectionInput>

            <ModalSectionMap>
              <Title size="m" iconBefore={<FaMap />}>
                {t("riskcalculator", "input-section-map-title", lang)}
              </Title>
              <Title size="sm" sub>
                {t("riskcalculator", "input-section-map-subtitle", lang)}
              </Title>

              {
                errorCases ? (
                  <article className="message is-danger">
                    <div className="message-header">
                      <p>{t("general", "dataproblem-title", lang)}</p>
                    </div>
                    <div className="message-body">
                      {t("general", "dataproblem-text", lang)}
                    </div>
                  </article>
                ) : !isFetchingCases && (
                  <div>

                    <div className="region-text">
                      {t("riskcalculator", "input-section-map-regionselected", lang)}
                    </div>
                    <div className="select">
                      <select
                        value={parIncMapRegionSelected}
                        onChange={(e) => {
                          handleClickIncidenceRegion(e.target.value);
                        }}
                      >
                        <option value={""} key="">
                          {t("riskcalculator", "input-section-map-noregion", lang)}
                        </option>
                        <option value={"14000"} key="14000">
                          {t("riskcalculator", "input-section-map-wholesaxony", lang)}
                        </option>
                        {saxonyDict.map((region: any) => {
                          return (
                            <option key={region.id} value={region.id}>
                              {region.fullname}
                            </option>
                          );
                        })}
                      </select>
                    </div>

                    <MapContainer
                      center={[50.95, 13.5]}
                      zoom={8}
                      maxZoom={10}
                      minZoom={6}
                      scrollWheelZoom={true}
                      style={{ width: "100%", height: "400px" }}
                      // whenCreated={(map) => {
                      //   setIncidenceMap(map);
                      // }}
                      whenReady={() => {
                        console.log("ready");
                      }}
                    >
                      <Pane
                        name="regions"
                        style={{ zIndex: 200, mixBlendMode: "multiply" }}
                      >
                        {dataGeo.features.map((feature: any) => {
                          const region = saxonyDict.find(
                            (d: any) => d.id == feature.properties.region
                          );

                          const mode =
                            region.id === parIncMapRegionSelected
                              ? "selected"
                              : "default";

                          const incidence = thisWeekIncidence
                            ? thisWeekIncidence[region.id]
                            : 0;

                          const interval = INTERVALS_CASES.find(
                            (i: any) => i.from <= incidence && i.to >= incidence
                          );
                          return (
                            <Polygon
                              eventHandlers={{
                                click: (e) => {
                                  handleClickIncidenceRegion(region.id);
                                },
                              }}
                              key={feature.properties.region}
                              positions={feature.geometry.coordinates}
                              pathOptions={{
                                color: mode === "selected" ? COLOR_PRIMARY : "black",
                                weight: mode === "selected" ? 5 : 2,
                                fillColor:
                                  mode === "selected"
                                    ? interval?.color || "grey"
                                    : interval?.color || "grey",
                                fillOpacity: 1,
                                opacity: mode === "selected" ? 1 : 0.5,
                              }}
                            >
                              <LeafletTooltip sticky pane="tooltipPane">
                                {region.fullname}
                              </LeafletTooltip>
                            </Polygon>
                          );
                        })}
                      </Pane>
                      <Pane
                        name="boundaries"
                        style={{ zIndex: 200, mixBlendMode: "multiply" }}
                      >
                        {geoBoundaries && geoBoundaries.geometry && (
                          <Polygon
                            positions={geoBoundaries?.geometry.coordinates}
                            pathOptions={{
                              color:
                                parIncMapRegionSelected === "14000"
                                  ? COLOR_PRIMARY
                                  : "black",
                              weight: parIncMapRegionSelected === "14000" ? 5 : 2,
                              fillColor: "none",
                              fillOpacity: 0,
                            }}
                          />
                        )}
                      </Pane>

                      <TileLayer
                        url="https://stamen-tiles-{s}.a.ssl.fastly.net/toner-lite/{z}/{x}/{y}{r}.png"
                        attribution='Map tiles by <a href="http://stamen.com">Stamen Design</a>, <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a> &mdash; Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        subdomains="abcd"
                        minZoom={0}
                        maxZoom={20}
                      />
                    </MapContainer>
                  </div>
                )
              }
            </ModalSectionMap>
          </React.Fragment>
        )}
      />
    );
  };

  const renderExampleModal = () => {
    return (
      <Modal
        buttons={[]}
        heading={t("optimization", "example-title", lang)}
        headerColor={COLOR_SECONDARY2}
        renderBody={() => t("optimization", "example-content", lang)}
        handleExit={() => {
          setExampleModalOpen(false);
        }}
      />
    );
  };

  const renderHelpModal = () => {
    return (
      <Modal
        buttons={[]}
        heading={t("optimization", "instructions-title", lang)}
        headerColor={COLOR_SECONDARY2}
        renderBody={() => t("optimization", "instructions-content", lang)}
        handleExit={() => {
          setHelpModalOpen(false);
        }}
      />
    );
  };

  return (
    <WrapperDiv ref={wrapperRef}>
      {helpModalOpen && renderHelpModal()}
      {exampleModalOpen && renderExampleModal()}
      {inputModalOpen && renderInputModal()}
      {warningModalOpen && renderWarningModal()}
      <HeaderSection>
        <HeaderSectionButtons className="field has-addons">
          <p className="control" style={{ marginBottom: "0.25rem" }}>
            <button
              className="button is-info information-button"
              onClick={() => setHelpModalOpen(true)}
            >
              <span className="icon">
                <FaInfo />
              </span>
              <span>{t("optimization", "instructions-button", lang)}</span>
            </button>
          </p>
          {/* 
          <p className="control">
            <button
              className="button is-primary example-button"
              onClick={() => setExampleModalOpen(true)}
            >
              <span className="icon">
                <FaBook />
              </span>
              <span>{t("optimization", "example-button", lang)}</span>
            </button>
          </p> */}
        </HeaderSectionButtons>
        <HeaderSectionTitle>
          <Title size="xl" marginBottom={0} noTopPadding>
            {t("optimization", "title", lang)}
          </Title>
        </HeaderSectionTitle>
      </HeaderSection>
      <Box>
        <FormDiv>
          <FormDivHeader>
            <Title size="m" marginBottom={1}>
              {t("optimization", "input-section-title", lang)}
            </Title>
            <button
              className="button is-link"
              onClick={() => {
                // setTimeout(() => {
                //   incidenceMap.invalidateSize();
                // }, 500);
                setInputModalOpen(true);
              }}
            >
              <span className="icon">
                <i className="fas fa-cog"></i>
              </span>
              <span>
                {t("optimization", "input-section-edit-button", lang)}
              </span>
            </button>
          </FormDivHeader>

          <InputParameterWrapperDiv>
            {[
              {
                icon: FaUsers,
                title: t("optimization", "par-employees-title", lang) as string,
                description: t(
                  "optimization",
                  "par-employees-description",
                  lang
                ) as string,
                value: parNoE,
                valueMin: 100,
                valueMax: 999,
              },
              {
                icon: FaSyringe,
                title: t(
                  "optimization",
                  "par-vaccinated-title",
                  lang
                ) as string,
                description: t(
                  "optimization",
                  "par-vaccinated-description",
                  lang
                ) as string,
                value: parVacc,
                valueMin: 1,
                valueMax: 100,
              },
              {
                icon: MdPlace,
                title: t("optimization", "par-incidence-title", lang) as string,
                description: t(
                  "optimization",
                  "par-incidence-description",
                  lang
                ) as string,
                value: parInc,
                valueMin: 50,
                valueMax: 3000,
              },
            ].map((inputParameter: any) => {
              return (
                <ParameterView
                  key={inputParameter.title}
                  Icon={inputParameter.icon}
                  value={inputParameter.value}
                  title={inputParameter.title}
                  description={inputParameter.description}
                  valueMin={inputParameter.valueMin}
                  valueMax={inputParameter.valueMax}
                />
              );
            })}
          </InputParameterWrapperDiv>
          {/* mutation
              <InputParameterDiv
                ParameterIcon={FaViruses}
                value={parMutation}
                setValue={(v: any) => {
                  setParMutation(validate(v, 0, 100));
                }}
                title={
                  translate("optimization", "par-mutation-title") as string
                }
                description={
                  translate(
                    "optimization",
                    "par-mutation-description"
                  ) as string
                }
                additional={
                  <div>
                    <div>
                      {`${parMutation}% ${
                        translate(
                          "optimization",
                          "par-mutation-extended1"
                        ) as string
                      }
                      `}
                    </div>
                    <div>
                      {`${100 - parMutation}% ${
                        translate(
                          "optimization",
                          "par-mutation-extended2"
                        ) as string
                      }.`}
                    </div>
                  </div>
                }
                valueMin={0}
                valueStep={5}
                valueMax={100}
              /> */}

          {/* employees */}
        </FormDiv>

        {/* results panel */}
        <ResultDiv>
          <Title size="m" marginBottom={0.5}>
            {t("optimization", "results-title", lang)}
          </Title>
          {/* <p>{t("optimization", "results-text", lang)}</p> */}

          {/* homework */}

          <ContactRateSection>
            <ContactRateTitleWrapper
              className="has-tooltip-arrow text-center has-tooltip-multiline"
              data-tooltip={t("optimization", "par-homework-description", lang)}
            >
              <Title
                size="sm"
                marginBottom={0.5}
                iconBefore={<FaUserCog size={20} />}
                color={COLOR_SECONDARY1}
              >
                {t("optimization", "par-homework-title", lang)}
              </Title>
            </ContactRateTitleWrapper>
            <ParameterInputs className="field has-addons">
              <input
                className="slider is-large"
                step={5}
                min={5}
                max={95}
                value={round(parHW * 100)}
                onChange={(e) => {
                  setParHW(validate(e.target.value, 0, 100) / 100);
                }}
                type="range"
              />
              <input
                className="short input"
                type="number"
                min={5}
                max={85}
                step={5}
                value={round(parHW * 100)}
                onChange={(e) => {
                  setParHW(validate(e.target.value, 0, 100) / 100);
                }}
              />
            </ParameterInputs>
          </ContactRateSection>

          <ContactRateSection>
            <ContactRateTitleWrapper
              className="has-tooltip-arrow text-center has-tooltip-multiline"
              data-tooltip={t("optimization", "par-testing-description", lang)}
            >
              <Title
                size="sm"
                marginBottom={0.5}
                iconBefore={<BiTestTube size={20} />}
                color={COLOR_SECONDARY1}
              >
                {t("optimization", "par-testing-title", lang)}
              </Title>
            </ContactRateTitleWrapper>
            <ParameterInputs className="field has-addons">
              <input
                className="slider is-large"
                step={1}
                min={1}
                max={14}
                value={parTest}
                onChange={(e) => {
                  setParTest(validate(e.target.value, 1, 14));
                }}
                type="range"
              />
              <input
                className="short input"
                type="number"
                min={1}
                max={14}
                step={1}
                value={parTest}
                onChange={(e) => {
                  setParTest(validate(e.target.value, 1, 14));
                }}
              />
            </ParameterInputs>
          </ContactRateSection>

          <ContactRateSection>
            <ContactRateTitleWrapper
              className="has-tooltip-arrow text-center has-tooltip-multiline"
              data-tooltip={t(
                "optimization",
                "results-contacts-description",
                lang
              )}
            >
              <Title
                size="sm"
                marginBottom={0.5}
                iconBefore={<BiNetworkChart size={20} />}
                color={COLOR_SECONDARY1}
              >
                {t("optimization", "results-contacts-title", lang)}
              </Title>
            </ContactRateTitleWrapper>
            <ContactRateSelect className="field has-addons">
              {contactRates.map((contactRateCategory) => {
                return (
                  <p className="control" key={contactRateCategory.id}>
                    <button
                      className={`button is-small ${contactRateCategory.id === parContactRate
                        ? "is-link"
                        : ""
                        }`}
                      value={contactRateCategory.id}
                      onClick={(e) => {
                        setParContactRate(contactRateCategory.id);
                      }}
                    >
                      {t(
                        "optimization",
                        `results-contactrate-name-${contactRateCategory.label}`,
                        lang
                      )}
                    </button>
                  </p>
                );
              })}
            </ContactRateSelect>
            <ContactRateDescription>
              {t(
                "optimization",
                `results-contactrate-description-${parContactRate}`,
                lang
              )}
            </ContactRateDescription>
          </ContactRateSection>

          <ChartSummaryDiv>
            <ChartDiv width={wChartWrapper}>
              <Stage
                height={hChartWrapper}
                width={wChartWrapper}
              // onMouseMove={(e: KonvaEventObject<MouseEvent>) => {
              //   //handleChartMove(e);
              // }}
              // onMouseLeave={() => {
              //   //handleChartOut();
              // }}
              >
                <Layer key="axis-and-lines">
                  {/* axis-x */}
                  <Group key="value-ticks">
                    <Line
                      points={[
                        margins.l - 5,
                        strategy.backgroundRisk.yinf,
                        margins.l + 5,
                        strategy.backgroundRisk.yinf,
                      ]}
                      stroke={COLOR_PRIMARY}
                      strokeWidth={2}
                      shadowBlur={2}
                      shadowEnabled={true}
                      shadowColor={"white"}
                    />
                    {customStrategyActive && (
                      <Line
                        points={[
                          margins.l - 5,
                          mouseStrategy.yinf,
                          margins.l + 5,
                          mouseStrategy.yinf,
                        ]}
                        stroke={COLOR_SECONDARY2}
                        strokeWidth={2}
                      />
                    )}
                    <Line
                      points={[
                        margins.l - 5,
                        strategy.maxprod.yprod,
                        margins.l + 5,
                        strategy.maxprod.yprod,
                      ]}
                      stroke={COLOR_SECONDARY1}
                      strokeWidth={2}
                    />
                    <Line
                      points={[
                        margins.l - 5,
                        strategy.backgroundRisk.yprod,
                        margins.l + 5,
                        strategy.backgroundRisk.yprod,
                      ]}
                      stroke={COLOR_PRIMARY}
                      strokeWidth={2}
                    />
                    {customStrategyActive && (
                      <Line
                        points={[
                          margins.l - 5,
                          mouseStrategy.yprod,
                          margins.l + 5,
                          mouseStrategy.yprod,
                        ]}
                        stroke={COLOR_SECONDARY2}
                        strokeWidth={2}
                      />
                    )}
                    <Line
                      points={[
                        margins.l - 5,
                        strategy.maxprod.yinf,
                        margins.l + 5,
                        strategy.maxprod.yinf,
                      ]}
                      stroke={COLOR_SECONDARY1}
                      strokeWidth={2}
                    />
                  </Group>
                  <Group key="axis">
                    {/* top chart infection */}
                    <Line
                      points={[
                        margins.l,
                        margins.t + hChart,
                        wChartWrapper - margins.r,
                        margins.t + hChart,
                      ]}
                      stroke="black"
                      fill="black"
                      strokeWidth={1}
                    />
                    <Line
                      points={[
                        margins.l,
                        margins.t + hChart,
                        margins.l,
                        margins.t,
                      ]}
                      stroke="black"
                      fill="black"
                      strokeWidth={1}
                    />

                    {/* bottom chart productivity */}
                    <Line
                      points={[
                        margins.l,
                        margins.t + margins.s + 2 * hChart,
                        wChartWrapper - margins.r,
                        margins.t + margins.s + 2 * hChart,
                      ]}
                      stroke="black"
                      fill="black"
                    />
                    <Line
                      points={[
                        margins.l,
                        margins.t + hChart + margins.s,
                        margins.l,
                        margins.t + hChart + margins.s + hChart,
                      ]}
                      stroke="black"
                      fill="black"
                      strokeWidth={1}
                    />
                  </Group>
                  <Group key="productivity-line">
                    <Line
                      //shadowEnabled={true}
                      // shadowColor={"black"}
                      // shadowBlur={3}
                      //shadowOffset={{ x: 2, y: 2 }}
                      //shadowOpacity={0.5}
                      //globalCompositeOperation="multiply"
                      stroke={"black"}
                      strokeWidth={3}
                      points={strategy ? strategy.lprod : []}
                    />
                  </Group>

                  <Group key="infection-line">
                    <Line
                      key={maxInf}
                      //shadowEnabled={true}
                      // shadowColor={"black"}
                      // shadowBlur={3}
                      //shadowOffset={{ x: 2, y: 2 }}
                      //shadowOpacity={0.5}
                      //globalCompositeOperation="multiply"
                      stroke={"black"}
                      strokeWidth={3}
                      points={strategy ? strategy.linf : []}
                    />
                  </Group>
                  {/* {strategy.backgroundRisk.occ > 0 &&
                    strategy.backgroundRisk.occ < 101 && (
                      <Group key="backgroundRisk-area">
                        <Line
                          closed
                          points={strategy.anrisk}
                          fill={COLOR_PRIMARY}
                          width={0}
                          opacity={0.1}
                        />
                      </Group>
                    )} */}

                  <Group key="axes-labels">
                    {/* origin of the axes */}
                    <Text
                      fontFamily="Ubuntu"
                      text={"0"}
                      x={margins.l - 15}
                      y={margins.t + hChart}
                      fontSize={tickFontSizeSmall}
                    />
                    <Text
                      fontFamily="Ubuntu"
                      text={"0"}
                      x={margins.l - 15}
                      y={margins.t + hChart + margins.s + hChart}
                      fontSize={tickFontSizeSmall}
                    />

                    {/* end of the X axes */}
                    <Text
                      fontFamily="Ubuntu"
                      text={"100"}
                      x={margins.l + wChart}
                      y={margins.t + hChart - 15}
                      fontSize={tickFontSizeSmall}
                    />
                    <Text
                      fontFamily="Ubuntu"
                      text={"100"}
                      x={margins.l + wChart}
                      y={margins.t + hChart + margins.s + hChart - 15}
                      fontSize={tickFontSizeSmall}
                    />

                    {/* end of the Y axes */}
                    <Text
                      fontFamily="Ubuntu"
                      text={maxInf.toPrecision(3)}
                      width={margins.l - 5}
                      align="right"
                      x={0}
                      y={margins.t - 15}
                      fontSize={tickFontSizeSmall}
                    />
                    <Text
                      fontFamily="Ubuntu"
                      text={"100"}
                      width={margins.l - 5}
                      align="right"
                      x={0}
                      y={margins.t + hChart + margins.s - 15}
                      fontSize={tickFontSizeSmall}
                    />
                  </Group>
                </Layer>
                {strategy.backgroundRisk.occ > 0 &&
                  strategy.backgroundRisk.occ < 101 && (
                    <Layer key="chart-infection">
                      {/* background risk line */}

                      <DataLine
                        points={[
                          strategy.backgroundRisk.x,
                          strategy.backgroundRisk.yinf,
                          strategy.backgroundRisk.x,
                          hChartWrapper - margins.b,
                        ]}
                        stroke={COLOR_PRIMARY}
                      />
                      <ChartCircle
                        y={strategy.backgroundRisk.yprod}
                        x={strategy.backgroundRisk.x}
                        fill={COLOR_PRIMARY}
                      />
                      <ChartCircle
                        y={strategy.backgroundRisk.yinf}
                        x={strategy.backgroundRisk.x}
                        fill={COLOR_PRIMARY}
                      />

                      <HaloText
                        text={"" + strategy.backgroundRisk.prod}
                        x={margins.l - 25}
                        y={strategy.backgroundRisk.yprod}
                        fill={COLOR_PRIMARY}
                      />

                      <HaloText
                        text={"" + strategy.backgroundRisk.occ}
                        x={strategy.backgroundRisk.x}
                        y={hChartWrapper - margins.b + 5}
                        fill={COLOR_PRIMARY}
                      />
                    </Layer>
                  )}

                {/* productivity */}
                <Layer key="chart-productivity">
                  <DataLine
                    points={[
                      strategy.maxprod.x,
                      strategy.maxprod.yinf,
                      strategy.maxprod.x,
                      hChartWrapper - margins.b,
                    ]}
                    stroke={COLOR_SECONDARY1}
                  />

                  <ChartCircle
                    y={strategy.maxprod.yprod}
                    x={strategy.maxprod.x}
                    fill={COLOR_SECONDARY1}
                  />
                  <ChartCircle
                    y={strategy.maxprod.yinf}
                    x={strategy.maxprod.x}
                    fill={COLOR_SECONDARY1}
                  />

                  <HaloText
                    text={"" + strategy.maxprod.inf.toPrecision(3)}
                    x={margins.l - 35}
                    y={strategy.maxprod.yinf}
                    fill={COLOR_SECONDARY1}
                  />

                  <HaloText
                    text={"" + strategy.maxprod.occ}
                    x={strategy.maxprod.x}
                    y={hChartWrapper - margins.b + 5}
                    fill={COLOR_SECONDARY1}
                  />
                </Layer>

                {customStrategyActive && (
                  <Layer key="mouse-guides">
                    <DataLine
                      points={[
                        mouseStrategy.x,
                        mouseStrategy.yinf,
                        mouseStrategy.x,
                        hChartWrapper,
                      ]}
                      stroke={COLOR_SECONDARY2}
                    />

                    <Line
                      points={[
                        margins.l,
                        mouseStrategy.yinf,
                        wChartWrapper - margins.r,
                        mouseStrategy.yinf,
                      ]}
                      stroke={COLOR_SECONDARY2}
                      strokeWidth={1.5}
                      dash={[1, 2]}
                    />

                    <Line
                      points={[
                        margins.l,
                        mouseStrategy.yprod,
                        wChartWrapper - margins.r,
                        mouseStrategy.yprod,
                      ]}
                      stroke={COLOR_SECONDARY2}
                      strokeWidth={1.5}
                      dash={[1, 2]}
                    />

                    <ChartCircle
                      y={mouseStrategy.yprod}
                      x={mouseStrategy.x}
                      fill={COLOR_SECONDARY2}
                    />
                    <ChartCircle
                      y={mouseStrategy.yinf}
                      x={mouseStrategy.x}
                      fill={COLOR_SECONDARY2}
                    />
                    <HaloText
                      text={"" + mouseStrategy.occ}
                      x={mouseStrategy.x + 5}
                      y={hChartWrapper - 15}
                      fill={COLOR_SECONDARY2}
                    />
                    <HaloText
                      text={"" + mouseStrategy.prod}
                      x={margins.l + 10}
                      y={mouseStrategy.yprod - 5}
                      fill={COLOR_SECONDARY2}
                    />
                    <HaloText
                      text={"" + mouseStrategy.inf.toPrecision(3)}
                      x={margins.l + 10}
                      y={mouseStrategy.yinf - 5}
                      fill={COLOR_SECONDARY2}
                    />
                  </Layer>
                )}

                <Layer key="treshold-lines">
                  {/* backroundrisk line */}
                  <Line
                    points={[
                      margins.l,
                      strategy.backgroundRisk.yinf,
                      wChartWrapper - margins.r,
                      strategy.backgroundRisk.yinf,
                    ]}
                    stroke={COLOR_PRIMARY}
                    strokeWidth={1.5}
                    dash={[1, 2]}
                  />

                  <Text
                    fontSize={tickFontSize}
                    text={
                      (strategy.backgroundRisk.inf.toPrecision(3) +
                        " - " +
                        t(
                          "optimization",
                          "line-backgroundrisk",
                          lang
                        )) as string
                    }
                    x={margins.l + 40}
                    y={strategy.backgroundRisk.yinf - 20}
                    fill={COLOR_PRIMARY}
                    fontFamily="Ubuntu"
                    strokeWidth={3}
                    fillAfterStrokeEnabled
                    stroke="white"
                  />
                  {/* maxprod line */}
                  <Text
                    fontSize={tickFontSize}
                    text={
                      (strategy.maxprod.prod +
                        " - " +
                        t(
                          "optimization",
                          "line-maximalproductivity",
                          lang
                        )) as string
                    }
                    x={margins.l + 40}
                    y={strategy.maxprod.yprod - 15}
                    fill={COLOR_SECONDARY1}
                    fontFamily="Ubuntu"
                    strokeWidth={3}
                    fillAfterStrokeEnabled
                    stroke="white"
                  />
                  <Line
                    points={[
                      margins.l,
                      strategy.maxprod.yprod,
                      wChartWrapper - margins.r,
                      strategy.maxprod.yprod,
                    ]}
                    stroke={COLOR_SECONDARY1}
                    strokeWidth={1.5}
                    dash={[1, 2]}
                  />

                  {/* other two lines */}
                  <Line
                    points={[
                      margins.l,
                      strategy.backgroundRisk.yprod,
                      wChartWrapper - margins.r,
                      strategy.backgroundRisk.yprod,
                    ]}
                    stroke={COLOR_PRIMARY}
                    strokeWidth={1.5}
                    dash={[1, 2]}
                  />
                  <Line
                    points={[
                      margins.l,
                      strategy.maxprod.yinf,
                      wChartWrapper - margins.r,
                      strategy.maxprod.yinf,
                    ]}
                    stroke={COLOR_SECONDARY1}
                    strokeWidth={1.5}
                    dash={[1, 2]}
                  />
                </Layer>

                {/* legend presence*/}
                <Layer key="labels">
                  <Text
                    fontFamily="Ubuntu"
                    text={
                      t("optimization", "chart-legend-presence", lang) as string
                    }
                    x={margins.l}
                    y={hChartWrapper - 35}
                    fontStyle="bold"
                    align="center"
                    width={wChart}
                    fontSize={15}
                    rotation={0}
                    strokeWidth={3}
                    fillAfterStrokeEnabled
                    stroke="white"
                  />

                  {/* legend risk*/}

                  <Text
                    fontFamily="Ubuntu"
                    text={
                      t("optimization", "chart-legend-risk", lang) as string
                    }
                    x={40}
                    y={margins.t - 30}
                    fontSize={15}
                    fontStyle="bold"
                    rotation={0}
                    strokeWidth={3}
                    fillAfterStrokeEnabled
                    stroke="white"
                  />

                  {/* legend productivity*/}
                  <Text
                    fontFamily="Ubuntu"
                    text={
                      t(
                        "optimization",
                        "chart-legend-productivity",
                        lang
                      ) as string
                    }
                    x={40}
                    y={margins.t + hChart + margins.s - 30}
                    fontStyle="bold"
                    fontSize={15}
                    strokeWidth={3}
                    fillAfterStrokeEnabled
                    stroke="white"
                  />
                </Layer>
              </Stage>
              <ResultSlider width={wChart}>
                <input
                  className="slider is-medium is-info"
                  min={0}
                  max={100}
                  disabled={!customStrategyActive}
                  color={COLOR_SECONDARY2}
                  value={mouseStrategy.occ}
                  type="range"
                  onChange={(e) => {
                    if (e.target) {
                      setMouseOcc(parseInt(e.target.value));
                    }
                  }}
                />
                <ResultSliderText>
                  <label className="checkbox">
                    <input
                      type="checkbox"
                      checked={customStrategyActive}
                      onChange={(e) => {
                        setcustomStrategyActive(!customStrategyActive);
                      }}
                    />
                    {t("optimization", "chart-customstrategy", lang)}
                  </label>
                </ResultSliderText>
              </ResultSlider>
            </ChartDiv>
            <ResultSummary>
              {/* block 1 summary */}
              <ResultSummaryBlock height={hChart + margins.t}>
                <ResultSummaryBlockTitle>
                  <ResultSummaryBlockPre>
                    {t("optimization", "summary-block1-title-pre", lang)}
                  </ResultSummaryBlockPre>
                  <ResultSummaryBlockAttr
                    className="has-tooltip-arrow has-tooltip-multiline"
                    data-tooltip={t(
                      "optimization",
                      "tooltip-backgroundrisk",
                      lang
                    )}
                    style={{ color: COLOR_PRIMARY }}
                  >
                    {t("optimization", "summary-block1-title-attr", lang)}
                    <FaViruses size={20} />
                  </ResultSummaryBlockAttr>
                  <ResultSummaryBlockValue>
                    {strategy.backgroundRisk.inf.toFixed(2)}
                  </ResultSummaryBlockValue>
                  %
                  {` (=${Math.round(
                    (strategy.backgroundRisk.inf * parNoE) / 100
                  )} ${t(
                    "optimization",
                    "summary-no-infected-employees",
                    lang
                  )})`}
                  <ResultSummaryBlockPost>
                    {t("optimization", "summary-block1-title-post", lang)}:
                  </ResultSummaryBlockPost>
                </ResultSummaryBlockTitle>

                <ResultSummaryBlockValueRow>
                  <ResultSummaryBlockValueRowLegend>
                    <LegendLine
                      colorFill={COLOR_PRIMARY}
                      colorLine={COLOR_PRIMARY}
                    />
                  </ResultSummaryBlockValueRowLegend>
                  <ResultSummaryBlockValueRowValue color={COLOR_PRIMARY}>
                    {strategy.backgroundRisk.occ.toFixed(0)}
                  </ResultSummaryBlockValueRowValue>
                  <ResultSummaryBlockValueRowAttribute
                    className="has-tooltip-arrow has-tooltip-multiline"
                    data-tooltip={t(
                      "optimization",
                      "tooltip-officepresence",
                      lang
                    )}
                  >
                    {t("optimization", "summary-block1-attr-office", lang)}
                    <FaBriefcase size={18} />
                  </ResultSummaryBlockValueRowAttribute>
                </ResultSummaryBlockValueRow>

                <ResultSummaryBlockValueRow>
                  <ResultSummaryBlockValueRowLegend>
                    <LegendLine
                      colorFill={COLOR_PRIMARY}
                      colorLine={COLOR_PRIMARY}
                    />
                  </ResultSummaryBlockValueRowLegend>
                  <ResultSummaryBlockValueRowValue color={COLOR_PRIMARY}>
                    {strategy.backgroundRisk.prod.toFixed(0)}
                  </ResultSummaryBlockValueRowValue>
                  <ResultSummaryBlockValueRowAttribute
                    className="has-tooltip-arrow has-tooltip-multiline"
                    data-tooltip={t(
                      "optimization",
                      "tooltip-productivity",
                      lang
                    )}
                  >
                    {t(
                      "optimization",
                      "summary-block1-attr-productivity",
                      lang
                    )}
                    <FaUsersCog size={20} />
                  </ResultSummaryBlockValueRowAttribute>
                </ResultSummaryBlockValueRow>

                {/* contact rate */}
                <ResultSummaryBlockValueRow>
                  <BiNetworkChart size={20} />
                  {`${getKappaInterval(
                    contactRateCategory,
                    parNoE,
                    strategy.backgroundRisk.occ,
                    2
                  )} ${t("optimization", "summary-contactrate", lang)}  (${t(
                    "optimization",
                    `results-contactrate-name-${contactRateCategory.id}`,
                    lang
                  )})`}
                </ResultSummaryBlockValueRow>
              </ResultSummaryBlock>

              {/* block 2 summary */}
              <ResultSummaryBlock height={hChart + margins.b / 2}>
                <ResultSummaryBlockTitle>
                  <ResultSummaryBlockPre>
                    {t("optimization", "summary-block2-title-pre", lang)}
                  </ResultSummaryBlockPre>
                  <ResultSummaryBlockAttr
                    className="has-tooltip-arrow has-tooltip-multiline"
                    data-tooltip={t(
                      "optimization",
                      "tooltip-maximalproductivity",
                      lang
                    )}
                    style={{ color: COLOR_SECONDARY1 }}
                  >
                    {t("optimization", "summary-block2-title-attr", lang)}
                    <FaUsersCog size={20} />
                  </ResultSummaryBlockAttr>
                  (
                  <ResultSummaryBlockValue>
                    {strategy.maxprod.prod.toFixed(0)}
                  </ResultSummaryBlockValue>
                  %)
                  <ResultSummaryBlockPost>
                    {t("optimization", "summary-block2-title-post", lang)}:
                  </ResultSummaryBlockPost>
                </ResultSummaryBlockTitle>

                <ResultSummaryBlockValueRow>
                  <ResultSummaryBlockValueRowLegend>
                    <LegendLine
                      colorFill={COLOR_SECONDARY1}
                      colorLine={COLOR_SECONDARY1}
                    />
                  </ResultSummaryBlockValueRowLegend>
                  <ResultSummaryBlockValueRowValue color={COLOR_SECONDARY1}>
                    {strategy.maxprod.occ.toFixed(0)}
                  </ResultSummaryBlockValueRowValue>
                  <ResultSummaryBlockValueRowAttribute
                    className="has-tooltip-arrow has-tooltip-multiline"
                    data-tooltip={t(
                      "optimization",
                      "tooltip-officepresence",
                      lang
                    )}
                  >
                    {t("optimization", "summary-block2-attr-office", lang)}
                    <FaBriefcase size={18} />
                  </ResultSummaryBlockValueRowAttribute>
                </ResultSummaryBlockValueRow>
                <ResultSummaryBlockValueRow>
                  <ResultSummaryBlockValueRowLegend>
                    <LegendLine
                      colorFill={COLOR_SECONDARY1}
                      colorLine={COLOR_SECONDARY1}
                    />
                  </ResultSummaryBlockValueRowLegend>
                  <ResultSummaryBlockValueRowValue color={COLOR_SECONDARY1}>
                    {strategy.maxprod.inf.toFixed(2)}
                  </ResultSummaryBlockValueRowValue>

                  <ResultSummaryBlockValueRowAttribute
                    className="has-tooltip-arrow has-tooltip-multiline"
                    data-tooltip={t(
                      "optimization",
                      "tooltip-infectionrisk",
                      lang
                    )}
                  >
                    {t("optimization", "summary-block2-attr-infection", lang)}
                    <FaViruses size={20} />
                    {` (=${Math.round(
                      (strategy.maxprod.inf * parNoE) / 100
                    )} ${t(
                      "optimization",
                      "summary-no-infected-employees",
                      lang
                    )})`}
                  </ResultSummaryBlockValueRowAttribute>
                  {strategy.maxprod.inf > backgroundRisk && (
                    <ResultSummaryWarning>
                      {` (${t(
                        "optimization",
                        "summary-over-backgroundrisk",
                        lang
                      )})`}
                    </ResultSummaryWarning>
                  )}
                </ResultSummaryBlockValueRow>

                {/* contact rate */}
                <ResultSummaryBlockValueRow>
                  <BiNetworkChart size={20} />
                  {`${getKappaInterval(
                    contactRateCategory,
                    parNoE,
                    strategy.maxprod.occ,
                    2
                  )} ${t("optimization", "summary-contactrate", lang)}  (${t(
                    "optimization",
                    `results-contactrate-name-${contactRateCategory.id}`,
                    lang
                  )})`}
                </ResultSummaryBlockValueRow>
              </ResultSummaryBlock>

              {/* block 3 summary */}
              <ResultSummaryBlock height={hChart}>
                {customStrategyActive && (
                  <>
                    <ResultSummaryBlockTitle>
                      <ResultSummaryBlockPre>
                        {t("optimization", "summary-block3-title-pre", lang)}
                      </ResultSummaryBlockPre>
                      <ResultSummaryBlockValue>
                        {mouseStrategy.occ.toFixed(0)}
                      </ResultSummaryBlockValue>
                      %
                      <ResultSummaryBlockAttr
                        style={{ marginLeft: "0.2em", color: COLOR_SECONDARY2 }}
                        className="has-tooltip-arrow has-tooltip-multiline"
                        data-tooltip={t(
                          "optimization",
                          "tooltip-officepresence",
                          lang
                        )}
                      >
                        {t("optimization", "summary-block3-title-attr", lang)}
                        <FaBriefcase size={18} />
                      </ResultSummaryBlockAttr>
                      <ResultSummaryBlockPost>
                        {t("optimization", "summary-block3-title-post", lang)}:
                      </ResultSummaryBlockPost>
                    </ResultSummaryBlockTitle>

                    <ResultSummaryBlockValueRow>
                      <ResultSummaryBlockValueRowLegend>
                        <LegendLine
                          colorFill={COLOR_SECONDARY2}
                          colorLine={COLOR_SECONDARY2}
                        />
                      </ResultSummaryBlockValueRowLegend>
                      <ResultSummaryBlockValueRowValue color={COLOR_SECONDARY2}>
                        {mouseStrategy.prod.toFixed(0)}
                      </ResultSummaryBlockValueRowValue>
                      <ResultSummaryBlockValueRowAttribute
                        className="has-tooltip-arrow has-tooltip-multiline"
                        data-tooltip={t(
                          "optimization",
                          "tooltip-productivity",
                          lang
                        )}
                      >
                        {t(
                          "optimization",
                          "summary-block3-attr-productivity",
                          lang
                        )}
                        <FaBriefcase size={18} />
                      </ResultSummaryBlockValueRowAttribute>
                    </ResultSummaryBlockValueRow>

                    <ResultSummaryBlockValueRow>
                      <ResultSummaryBlockValueRowLegend>
                        <LegendLine
                          colorFill={COLOR_SECONDARY2}
                          colorLine={COLOR_SECONDARY2}
                        />
                      </ResultSummaryBlockValueRowLegend>
                      <ResultSummaryBlockValueRowValue color={COLOR_SECONDARY2}>
                        {mouseStrategy.inf.toFixed(2)}
                      </ResultSummaryBlockValueRowValue>

                      <ResultSummaryBlockValueRowAttribute
                        className="has-tooltip-arrow has-tooltip-multiline"
                        data-tooltip={t(
                          "optimization",
                          "tooltip-infectionrisk",
                          lang
                        )}
                      >
                        {t(
                          "optimization",
                          "summary-block3-attr-infection",
                          lang
                        )}
                        <FaViruses size={20} />
                        {` (=${Math.round(
                          (mouseStrategy.inf * parNoE) / 100
                        )} ${t(
                          "optimization",
                          "summary-no-infected-employees",
                          lang
                        )})`}
                      </ResultSummaryBlockValueRowAttribute>
                      {mouseStrategy.inf > backgroundRisk && (
                        <ResultSummaryWarning>
                          {` (${t(
                            "optimization",
                            "summary-over-backgroundrisk",
                            lang
                          )})`}
                        </ResultSummaryWarning>
                      )}
                    </ResultSummaryBlockValueRow>

                    {/* contact rate */}
                    <ResultSummaryBlockValueRow>
                      <BiNetworkChart size={20} />
                      {`${getKappaInterval(
                        contactRateCategory,
                        parNoE,
                        mouseStrategy.occ,
                        2
                      )} ${t(
                        "optimization",
                        "summary-contactrate",
                        lang
                      )}  (${t(
                        "optimization",
                        `results-contactrate-name-${contactRateCategory.id}`,
                        lang
                      )})`}
                    </ResultSummaryBlockValueRow>
                  </>
                )}
              </ResultSummaryBlock>
            </ResultSummary>
          </ChartSummaryDiv>
        </ResultDiv>
      </Box>
    </WrapperDiv>
  );
};

const ChartSummaryDiv = styled.div`
  display: flex;
  line-height: 1em;
`;

interface ResultSliderDivProps {
  width: number;
}

const ResultSlider = styled.div.attrs((props: ResultSliderDivProps) => ({}))`
  margin-left: ${(props) => margins.l - 7 + "px"};

  input.slider {
    margin: 0 !important;
    width: ${(props: ResultSliderDivProps) => props.width + 13 + "px"};
  }
`;

const ResultSliderText = styled.div`
  margin-left: 2px;
  input[type="checkbox"] {
    margin-right: 3px;
  }
`;

interface ChartDivProps {
  width: number;
}
const ChartDiv = styled.div.attrs((props) => ({}))`
  display: block;
  width: ${(props: ChartDivProps) => props.width + "px"};
`;

interface ResultSummaryBlockProps {
  height: number;
}
const ResultSummaryBlock = styled.div.attrs((props) => ({}))`
  padding-bottom: 1em;
  height: ${(props: ResultSummaryBlockProps) => props.height + "px"};
  line-height: 1em;
  svg {
    vertical-align: middle;
    margin: 0 0.2em 0px 0.4em;
  }
`;

const ResultSummaryWarning = styled.div`
  font-style: italic;
  color: red;
`;

const ResultSummaryBlockTitle = styled.div`
  div {
    display: inline;
  }
  margin-bottom: 0.5em;
`;
const ResultSummaryBlockPre = styled.div`
  margin-right: 0.2em;
`;
const ResultSummaryBlockAttr = styled.div`
  margin-right: 0.2em;
`;
const ResultSummaryBlockValue = styled.div`
  font-weight: bold;
`;
const ResultSummaryBlockPost = styled.div`
  margin-left: 0.2em;
  * {
    display: inline;
  }
`;

const ResultSummaryBlockValueRow = styled.div`
  margin-left: 0.2em;
  div {
    display: inline;
  }
`;

const ResultSummaryBlockValueRowLegend = styled.div`
  margin-right: 0.2em;
  vertical-align: middle;
`;

interface ResultSummaryBlockValueRowValueProps {
  color: string;
}
const ResultSummaryBlockValueRowValue = styled.div.attrs(
  (props: ResultSummaryBlockValueRowValueProps) => ({})
)`
  color: ${(props: ResultSummaryBlockValueRowValueProps) => props.color};
  font-weight: bold;
  margin-left: 3px;
`;

const ResultSummaryBlockValueRowAttribute = styled.div``;

interface ILegendLineProps {
  colorLine: string;
  colorFill: string;
}

const LegendLine = ({ colorLine, colorFill }: ILegendLineProps) => {
  const lw = 40;
  const lh = 16;

  return (
    <Stage
      height={lh}
      width={lw}
      style={{
        marginBottom: "0px",
        marginRight: "0em",
        display: "inline-flex",
        verticalAlign: "middle",
      }}
    >
      <Layer>
        <DataLine points={[0, lh / 2, lw, lh / 2]} stroke={colorLine} />
        <ChartCircle y={lh / 2} x={lw / 2} fill={colorFill} />
      </Layer>
    </Stage>
  );
};

interface InputParameterProps {
  ParameterIcon: any;
  value: number;
  setValue: Function;
  title: string;
  description: string;
  valueMin: number;
  valueMax: number;
  valueStep: number;
  additional?: any;
  warningText?: string;
  displayWarning?: boolean;
  extendedText?: string | false;
  disabled?: boolean;
}

const InputParameterDiv = ({
  ParameterIcon,
  value,
  setValue,
  title,
  description,
  valueMin,
  valueMax,
  valueStep,
  additional,
  warningText,
  displayWarning = false,
  extendedText = false,
  disabled = false,
}: InputParameterProps) => {
  const [openExtended, setOpenExtended] = useState<boolean>(false);

  const [warningTextDisplay, setWarningTextDisplay] = useState<boolean>(false);
  return (
    <ParameterDiv>
      <InputParameterDivMedia>
        <InputParameterDivMediaLeft>
          {/* @ts-ignore */}
          <ParameterIcon size={35} color={COLOR_SECONDARY1} />
        </InputParameterDivMediaLeft>
        <InputParameterDivMediaRight className="media-content">
          <Title color={COLOR_SECONDARY1} size="sm" marginBottom={0}>
            {title}
          </Title>
          <p>{description}</p>
        </InputParameterDivMediaRight>
      </InputParameterDivMedia>
      <ParameterInputs>
        <input
          disabled={disabled}
          className="slider is-large"
          step={valueStep}
          min={valueMin}
          max={valueMax}
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
          }}
          type="range"
        />
        <input
          disabled={disabled}
          className="short input"
          type="number"
          min={valueMin}
          max={valueMax}
          step={valueStep}
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
          }}
        />
        {warningText && (
          <WarningTextWrapper
            style={{ display: displayWarning ? "block" : "none" }}
          >
            <WarningTextIcon
              onMouseEnter={() => {
                setWarningTextDisplay(true);
              }}
              onMouseLeave={() => {
                setWarningTextDisplay(false);
              }}
            >
              <MdWarning color={COLOR_PRIMARY} size={28} />
            </WarningTextIcon>

            <WarningTextTooltip
              style={{ display: warningTextDisplay ? "block" : "none" }}
            >
              {warningText}
            </WarningTextTooltip>
          </WarningTextWrapper>
        )}

        {extendedText && (
          <ExtendedIcon
            onClick={() => {
              setOpenExtended(!openExtended);
            }}
          >
            {openExtended ? <BsInfoCircle /> : <BsInfoCircleFill />}
          </ExtendedIcon>
        )}
      </ParameterInputs>
      <ParameterAdditional>{additional && additional}</ParameterAdditional>
      {openExtended && (
        <ParameterInfoExtendedText>
          {/* <BsInfoCircleFill /> */}
          {extendedText}
        </ParameterInfoExtendedText>
      )}
    </ParameterDiv>
  );
};

const InputParameterDivMedia = styled.div.attrs(() => ({
  className: "media",
}))`
  margin-bottom: 0.5rem;
`;

const InputParameterDivMediaRight = styled.div``;
const InputParameterDivMediaLeft = styled.div.attrs(() => ({
  className: "media-left",
}))`
  display: flex;

  input {
    margin-right: 0.5em;
  }

  input.slider {
    width: 10em;
  }
`;

/*
INSTRUCTIONS
*/
const InstructionsSection = styled.div.attrs(() => ({}))`
  padding-bottom: 2em;
`;
const InstructionsTitle = styled.div.attrs(() => ({}))``;
const InstructionsParagraph = styled.div.attrs(() => ({}))``;

const InstructionsImagesList = styled.div`
  display: block;
`;
const InstructionsImageDiv = styled.div`
  padding: 1em;
`;
const InstructionsImageImage = styled.div``;
const InstructionsImageCaption = styled.div`
  font-weight: bold;
  text-align: center;
`;

/**
 * INPUT MODAL
 */

const ModalSectionInput = styled.div.attrs(({ }) => ({
  className: "",
}))`
  .parameter-div {
    padding-left: 1em;
  }
`;

const ModalSectionMap = styled.div.attrs(({ }) => ({
  className: "",
}))``;

const WarningTextWrapper = styled.div``;
const WarningTextIcon = styled.div`
  margin-left: 0.5em;
`;
const WarningTextTooltip = styled.div`
  position: absolute;
  background: ${COLOR_PRIMARY};
  z-index: 200;
  margin-top: 1em;
  margin-left: -20em;
  padding: 0.5rem;
  color: white;
  border-radius: 0.5rem;
  box-shadow: 0.5rem 0.5rem rgb(0 0 0 / 50%);
`;

const ParameterAdditional = styled.div.attrs(() => ({}))`
  padding: 1em;
  margin-left: 2em;

  input {
    margin-right: 5px;
  }
`;

const ExtendedIcon = styled.div.attrs(() => ({
  className: "",
}))`
  padding: 0em 0.7em;
  font-size: 1.2em;
  color: ${COLOR_SECONDARY1};
`;

const ResultSummary = styled.div.attrs(() => ({}))`
  font-size: 0.9em;
`;

const ResultDiv = styled.section.attrs(() => ({
  className: "section column",
}))`
  padding: 2rem 2.5rem;
`;

const ContactRateSection = styled.div`
  margin-bottom: 1.5em;
  margin-left: 1.5em;
`;
const ContactRateTitleWrapper = styled.div`
  display: inline-block;
`;
const ContactRateSelect = styled.div`
  margin-bottom: 0;

  p.control {
    margin-bottom: 0;
  }
`;

const ContactRateDescription = styled.div`
  font-weight: 300;
  font-size: 0.85em;
  font-size-adjust: 0.5;
  margin-left: 0.5em;
`;

const FormDiv = styled.section.attrs(() => ({
  className: "section",
}))`
  padding: 2rem 2.5rem;
  display: block;
  // border-bottom: 2px solid ${COLOR_SECONDARY1};

  button {
    margin-top: -1em;
  }
`;

const FormDivHeader = styled.div`
  display: flex;
  align-items: center;
  .button {
    margin-left: 1em;
  }
`;

const ParameterDiv = styled.div.attrs(() => ({
  className: "parameter-div",
}))`
  display: block;
  margin-bottom: 0.5em;
  margin-left: 1.5em;
`;

const ParameterInfoExtendedText = styled.div`
  font-style: italic;
  margin-left: 3.5em;
  font-size: 0.8em;
`;

interface IParameterProps {
  disabled?: boolean;
}
const ParameterInputs = styled.div`
  display: flex;
  align-items: center;
  margin-left: 1em;
  margin-right: 1em;
  .slider {
    width: 20em;
    margin: 0em !important;
  }
  input {
    margin-left: 0.5em;
    width: 5em;
  }
  .input-value {
    margin-left: 0.3em;
    margin-right: 0.3em;
    font-weight: bold;
    font-size: 1.2em;
    width: 2em;
  }
`;

const ParameterExtended = styled.div.attrs(() => ({
  className: "",
}))`
  font-weight: 300;
  font-size: 0.85em;
  font-size-adjust: 0.5;
`;

const InputParameterWrapperDiv = styled.div`
  margin-top: 0.5em;
`;

const HeaderSection = styled.div`
  margin: 10px;
  padding-top: 20px;
  padding-bottom: 10px;
`;

const HeaderSectionTitle = styled.div`
  padding-top: 0;
`;
const HeaderSectionButtons = styled.div``;

const WrapperDiv = styled.div.attrs(() => ({
  className: "content",
}))``;

interface IBoxContentProps {
  entered?: boolean;
}

const BoxContent = styled.div.attrs((props: IBoxContentProps) => ({
  className: "is-desktop",
}))`
  padding: 0.5em;
  min-height: 12em;
  display: ${(props: any) => {
    return props.entered ? "block" : "hidden";
  }};
`;

/**
 * Instructions button
 */
const InstructionsButton = styled.button.attrs(({ }: {}) => ({
  className: `button`,
}))`
  margin-bottom: -2rem;
  display: block;
  font-size: 13px;
  background: ${COLOR_SECONDARY2};
  color: white;
  font-weight: bold;
  border: none;
`;

const InstructionsButtonIcon = styled.span.attrs(({ }: {}) => ({}))`
  display: inline;
  margin-right: 5px;
  font-size: 13px;
  vertical-align: middle;
`;

const SelectOnMapButton = styled.button.attrs(({ }: {}) => ({
  className: `button`,
}))`
  margin: 0.25rem;
  display: block;
  font-size: 13px;
  background: ${COLOR_SECONDARY1};
  color: white;
  font-weight: bold;
  border: none;
`;

const AdvancedOptions = styled.div``;
const AdvancedOptionsLine = styled.div`
  display: inline-flex;
  cursor: pointer;
  padding-bottom: 1.5em;
  svg {
    vertical-align: middle;
  }
`;

const MapModalRow = styled.div.attrs(({ }) => ({
  className: "",
}))`
  margin-top: -0.5rem;
  margin-bottom: 1rem;
  display: inline-flex;
`;
const MapModalRegionText = styled.div.attrs(({ }) => ({
  className: "",
}))`
  margin-right: 0.5rem;
`;
const MapModalRegionName = styled.div.attrs(({ }) => ({
  className: "",
}))`
  font-weight: 800;
`;

//export default withRouter(OptimizationPage);
export default OptimizationPage;

const getKappa = (
  contact_rate: number,
  employees_all: number,
  occup: number,
  scale: number
) => {
  let kappa = 2 + contact_rate * 0.3 * (occup / 100) * employees_all;

  // 200 * 0.15 = 30
  // < 30 => kappa , > 30 => 30
  //kappa = kappa < contact_rate * 200 ? kappa : contact_rate * 200;
  kappa = kappa / scale;

  return kappa;
};
// X = precence rate
// Y = productivity
// Z = infection

function calculateStrategyOutcomes(
  employees_all: number,
  nv: number,
  betau: number,
  betav: number,
  contact_rate: number,
  home_efficiency_rate: number,
  infection_probability: number,
  test_interval: number
) {
  let current_prod = home_efficiency_rate * employees_all;
  current_prod = 100 * (current_prod / employees_all);

  const X = [];
  const Y = [];
  const Z = [];

  let pr = 0;

  X.push(pr);
  Y.push(current_prod);
  Z.push(0);

  const scale = 2;
  const workingdays = 5 / 7;
  const tau_bar = Math.round(scale * workingdays * (test_interval / 2));

  infection_probability = infection_probability / scale;

  while (pr < 100) {
    pr = pr + 1;

    const kappa = getKappa(contact_rate, employees_all, pr, scale);
    let occup = pr / 100;

    let pu = 0;
    let pv = 0;
    let n_infect = 0;

    for (var d = 1, _pj_a = tau_bar + 1; d < _pj_a; d += 1) {
      let A = Math.pow(
        1 - betau,
        occup * occup * kappa * (1 / (employees_all - 1))
      );

      let B = Math.pow(
        1 - pu * betau,
        kappa *
        (1 - 1 / (employees_all - 1)) *
        occup *
        ((employees_all - nv - 1) / (employees_all - 1))
      );

      let C = Math.pow(
        1 - pv * betau,
        kappa *
        (1 - 1 / (employees_all - 1)) *
        occup *
        (nv / (employees_all - 1))
      );

      let pr_healty = A * B * C * (1 - pu);
      pu = 1 - pr_healty;

      A = Math.pow(
        1 - betav,
        occup * occup * kappa * (1 / (employees_all - 1))
      );

      B = Math.pow(
        1 - pu * betav,
        kappa *
        (1 - 1 / (employees_all - 1)) *
        occup *
        ((employees_all - nv - 1) / (employees_all - 1))
      );

      C = Math.pow(
        1 - pv * betav,
        kappa *
        (1 - 1 / (employees_all - 1)) *
        occup *
        (nv / (employees_all - 1))
      );

      pr_healty = A * B * C * (1 - pv);
      pv = 1 - pr_healty;

      let m = 1 + (employees_all - nv - 1) * pu + nv * pv;

      let x =
        m * (1 - Math.pow(1 - infection_probability, occup * employees_all));

      n_infect = n_infect + x - (n_infect * x) / employees_all;
    }

    n_infect = n_infect * (7 / (tau_bar / scale));

    if (n_infect > employees_all) {
      n_infect = employees_all;
    }

    //console.log(n_infect);

    current_prod =
      occup * (employees_all - n_infect) +
      home_efficiency_rate * (1 - occup) * (employees_all - n_infect);

    current_prod = 100 * (current_prod / employees_all);
    X.push(pr);
    Y.push(current_prod);
    Z.push(100 * (n_infect / employees_all));
  }
  return [X, Y, Z];
}
