import React from "react";

import styled from "styled-components";

import { COLOR_SECONDARY1 } from "variables";

const sizes: {
  [id: string]: {
    fontSize: number;
    fontSubSize: number;
    lineHeight: number;
    marginTop: number;
    paddingTop: number;
    marginBottom: number;
    marginIcon: number;
  };
} = {
  xs: {
    fontSize: 1,
    fontSubSize: 1,
    lineHeight: 1,
    marginTop: 1,
    paddingTop: 0,
    marginBottom: 1,
    marginIcon: 1.2,
  },
  sm: {
    fontSize: 1.2,
    fontSubSize: 1,
    lineHeight: 1.2,
    marginTop: 1.2,
    paddingTop: 0.25,
    marginBottom: 1.2,
    marginIcon: 1.5,
  },
  m: {
    fontSize: 1.5,
    fontSubSize: 1.2,
    lineHeight: 1.3,
    marginTop: 2,
    paddingTop: 0.5,
    marginBottom: 1.5,
    marginIcon: 2,
  },
  lg: {
    fontSize: 1.8,
    fontSubSize: 1.4,
    lineHeight: 1.4,
    marginTop: 2.75,
    paddingTop: 1,
    marginBottom: 1.8,
    marginIcon: 4,
  },
  xl: {
    fontSize: 2.5,
    fontSubSize: 2,
    lineHeight: 1.5,
    marginTop: 4,
    paddingTop: 2,
    marginBottom: 2.5,
    marginIcon: 8,
  },
};

export const Title = ({
  children,
  iconBefore,
  iconAfter,
  ...divProps
}: {
  children: React.ReactNode;
  iconBefore?: React.ReactElement;
  iconAfter?: React.ReactElement;
  color?: string;
  size?: "xs" | "sm" | "m" | "lg" | "xl";
  sub?: boolean;
  marginBottom?: number;
  noTopPadding?: boolean;
}) => {
  return (
    <TitleDiv {...divProps}>
      {iconBefore && <IconBefore>{iconBefore}</IconBefore>}
      <TitleDivText>{children}</TitleDivText>

      {iconAfter && <IconAfter>{iconAfter}</IconAfter>}
    </TitleDiv>
  );
};

const Icon = styled.div``;

const IconBefore = styled(Icon)`
  margin-right: ${(props: ITitleDiv) =>
    sizes[props.size || "xl"].marginIcon as number}px;
`;
const IconAfter = styled(Icon)`
  margin-left: ${(props: ITitleDiv) =>
    sizes[props.size || "xl"].marginIcon as number}px;
`;

const TitleDivText = styled.div``;

interface ITitleDiv {
  color?: string;
  size?: "xs" | "sm" | "m" | "lg" | "xl";
  sub?: boolean;
  marginBottom?: number;
  noTopPadding?: boolean;
}

const TitleDiv = styled.div.attrs((props: ITitleDiv) => ({
  className: "",
}))`
  margin-bottom: ${(props: ITitleDiv) =>
    "marginBottom" in props
      ? props.marginBottom
      : (sizes[props.size || "xl"].marginBottom as number)}rem;
  margin-top: -${(props: ITitleDiv) => (props.sub ? (sizes[props.size || "xl"].marginTop as number) : 0)}rem;
  padding-top: ${(props: ITitleDiv) =>
    !props.sub && !props.noTopPadding
      ? (sizes[props.size || "xl"].paddingTop as number)
      : 0}rem;
  align-items: baseline;
  display: flex;
  font-size: ${(props: ITitleDiv) =>
    props.sub
      ? (sizes[props.size || "xl"].fontSubSize as number)
      : (sizes[props.size || "xl"].fontSize as number)}em;
  line-height: ${(props: ITitleDiv) =>
    sizes[props.size || "xl"].lineHeight as number};
  color: ${(props: ITitleDiv) => props.color || ""};
  font-weight: ${(props: ITitleDiv) => (props.sub ? "400" : "bold")};
  word-break: break-word;
`;
