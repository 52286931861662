import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestHeaders,
  AxiosResponse,
} from "axios";
import { ICorrelationParams } from "pages/correlation";
import React from "react";

let developmentMode: boolean = false;

const baseUrl = developmentMode
  ? "http://127.0.0.1:5000" + "/rest"
  : process.env.API_URL + "/rest";

// "no-cors" in deploy
// "cors" in development
const corsMode = developmentMode ? "cors" : "no-cors";

class Api {
  private baseUrl: string;
  private headers: any;

  constructor() {
    console.log("new", process.env.API_URL);
    if (!process.env.API_URL) {
      throw new Error("APIURL is not set");
    }
    this.baseUrl = baseUrl;

    this.headers = {
      "Content-Type": "application/text",
    };
  }

  async _fetch(endpoint: string) {
    const response = await fetch(`${this.baseUrl}/${endpoint}`, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: corsMode, // no-cors, *cors, same-origin
      //cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: this.headers,
      //  redirect: "follow", // manual, *follow, error
      //referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      //body: JSON.stringify(data), // body data type must match "Content-Type" header
    });
    if (!response.ok) {
      const message = `An error has occured calling ${endpoint}: ${response.status}`;
      //throw new Error(message);
    }

    const resText = await response.text();
    try {
      const resObj = await JSON.parse(resText);
      const resObj2 = await JSON.parse(resObj);
      return resObj2;
    } catch {
      console.log("problem parsing data", endpoint);
      console.log(resText);
      return false;
    }
  }

  async retirement(params: any) {
    return this._fetch(
      `retirement?m=${params.no_residents}&n=${params.no_employees}&kappa=${params.no_contacts}&max_g=${params.max_group_size}&max_tau=${params.min_testing_interval}&p_time=${params.time_group}&t_time=${params.time_resident}&maximum_workload=${params.max_workload}`
    );
  }

  async correlationRegion(params: ICorrelationParams) {
    return this._fetch(
      `correlation_region?regid=${params.regId}&time_from=${params.timeFrom}&time_to=${params.timeTo}&time_diff=1`
    );
  }

  async correlationMap(params: ICorrelationParams) {
    return this._fetch(
      `correlation_map?mode=${params.mapMode}&time_from=${params.timeFrom}&time_to=${params.timeTo}`
    );
  }

  async correlationTimeline() {
    return this._fetch(`correlation_timeline`);
  }

  async correlationRegionList() {
    return this._fetch(`correlation_regionlist`);
  }

  async saxonyCasesForecast() {
    return this._fetch(`saxony_cases`);
  }
  async saxonyVaccines() {
    return this._fetch(`saxony_vaccines`);
  }
  async dolnoslaskiCasesForecast() {
    return this._fetch(`dolnoslaski_cases`);
  }
  async czechiaCasesForecast() {
    return this._fetch(`czechia_cases`);
  }
  async czechiaTestsForecast() {
    return this._fetch(`czechia_tests`);
  }

  async wastewaterTimeline(selection: any) {
    return {};
  }

  //http://127.0.0.1:5000/rest/wastewater_heatmap?radius=kreis&metric=inc
  async wastewaterHeatmap(radius: string, metric: string) {
    return this._fetch(`wastewater_heatmap?radius=${radius}&metric=${metric}`);
  }

  // http://127.0.0.1:5000/rest/wastewater_map?radius=kreis&metric=inc&date=2022-03-06
  async wastewaterMap(radius: string, metric: string, date: string) {
    return this._fetch(
      `wastewater_map?radius=${radius}&metric=${metric}&date=${date}`
    );
  }
}

export default new Api();
