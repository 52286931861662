import React, { useGlobal } from "reactn";
import dictionary from "./dictionary.json";

import { ILanguage } from "models";

import { DateTimeFormatOptions } from "intl";

import parse from "html-react-parser";

type paramType = { [id: string]: string };

const assignParameters = (term: string, params: paramType) => {
  Object.keys(params).forEach((paramKey: any) => {
    const paramReplace = params[paramKey];
    term = term.replace(
      new RegExp("{{(?:\\s+)?(" + paramKey + ")(?:\\s+)?}}"),
      paramReplace
    );
  });
  return term;
};

export const translateDate = (inDate: Date) => {
  // check lang format

  const [lang, setLang] = useGlobal("lang");
  const activeLanguage = languages.find((l) => l.id === lang);
  let timeFormat = "de-DE";
  if (activeLanguage) {
    timeFormat = activeLanguage.timeFormat;
  }

  const options: DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };

  return inDate.toLocaleString(timeFormat, options);
};

type IdictionaryItem = {
  section: string;
  term: string;
  id?: string;
  de?: string;
  pl?: string;
  en: string;
  cs?: string;
};

const getItemFromDictionary = (sectionKey: string, termKey: string) => {
  const item: IdictionaryItem | undefined = (
    dictionary as IdictionaryItem[]
  ).find(
    (dictionaryItem: IdictionaryItem) =>
      dictionaryItem.section === sectionKey && dictionaryItem.term === termKey
  );
  return item;
};

export const translate = (
  sectionKey: string,
  termKey: string,
  params: paramType = {}
) => {
  const [lang, setLang] = useGlobal("lang");
  const item = getItemFromDictionary(sectionKey, termKey);

  if (item) {
    const translation = item[lang as languageId] || item["en"];
    const withParameters = assignParameters(translation, params);
    if (withParameters) {
      return parse(withParameters) || "";
    } else {
      return "";
    }
  }
  return `--missing translation (${sectionKey}, ${termKey})--`;
};

export const t = (
  sectionKey: string,
  termKey: string,
  lang: string = "de",
  params: paramType = {}
) => {
  const item = getItemFromDictionary(sectionKey, termKey);

  if (item) {
    const translation = item[lang as languageId] || item["en"];
    const withParameters = assignParameters(translation, params);
    if (withParameters) {
      return parse(withParameters) || "";
    } else {
      return "";
    }
  }
  return `--missing translation (${sectionKey}, ${termKey})--`;
};

export const translateText = (
  sectionKey: string,
  termKey: string,
  params: paramType = {}
): string => {
  const [lang, setLang] = useGlobal("lang");
  const item = getItemFromDictionary(sectionKey, termKey);

  if (item) {
    const translation = item[lang as languageId] || item["en"];
    const withParameters = assignParameters(translation, params);
    return withParameters;
  }

  return `--missing translation (${sectionKey}, ${termKey})--`;
};

export const getLanguage = (): ILanguage => {
  const [lang, setLang] = useGlobal("lang");
  const languageObj = getLanguageById(lang);
  return languageObj;
};

export const formatDate = (
  date: string,
  options: {
    delta?: { days?: number; months?: number; years?: number };
    format?: DateTimeFormatOptions;
  } = {
    delta: { days: 0, months: 0, years: 0 },
  }
): string => {
  const defaultFormat: DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const validatedDate = date
    .replace("-20", " 2020")
    .replace("-21", " 2021")
    .replace("-22", " 2022")
    .replace("-23", " 2023");
  let dateObj = new Date(Date.parse(validatedDate));

  if (options?.delta?.days) {
    dateObj.setDate(dateObj.getDate() + options.delta.days);
  }
  if (options?.delta?.months) {
    dateObj.setDate(dateObj.getDate() + options.delta.months);
  }
  if (options?.delta?.years) {
    dateObj.setDate(dateObj.getDate() + options.delta.years);
  }

  const formatOptions: DateTimeFormatOptions = {
    ...defaultFormat,
    ...options.format,
  };

  let timeFormat = getLanguage().timeFormat;

  return dateObj.toLocaleString(timeFormat, formatOptions);
};

export const tDay = (date: Date, lang: string = "de") => {
  const languageItem = getLanguageById(lang);
  return date.toLocaleString(languageItem.timeFormat, {
    weekday: "short",
  });
};

export const tDate = (
  dateIn: string | Date,
  lang: string = "de",
  options: {
    delta?: { days?: number; months?: number; years?: number };
    format?: DateTimeFormatOptions;
  } = {
    delta: { days: 0, months: 0, years: 0 },
  }
): string => {
  const defaultFormat: DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const date = typeof dateIn === "string" ? dateIn : dateIn.toDateString();

  const validatedDate = date
    .replace("-20", " 2020")
    .replace("-21", " 2021")
    .replace("-22", " 2022")
    .replace("-23", " 2023");
  let dateObj = new Date(Date.parse(validatedDate));

  if (options?.delta?.days) {
    dateObj.setDate(dateObj.getDate() + options.delta.days);
  }
  if (options?.delta?.months) {
    dateObj.setDate(dateObj.getDate() + options.delta.months);
  }
  if (options?.delta?.years) {
    dateObj.setDate(dateObj.getDate() + options.delta.years);
  }

  const formatOptions: DateTimeFormatOptions = {
    ...defaultFormat,
    ...options.format,
  };

  const languageObj = getLanguageById(lang);
  let timeFormat = languageObj.timeFormat;

  return dateObj.toLocaleString(timeFormat, formatOptions);
};

export const languages: ILanguage[] = [
  {
    id: "de",
    label: "Deutsch",
    timeFormat: "de-DE",
    logo: require("./../assets/flags/de.svg"),
  },
  {
    id: "en",
    label: "English",
    timeFormat: "en-US",
    logo: require("./../assets/flags/en.svg"),
  },
  // {
  //   id: "pl",
  //   label: "Polski",
  //   timeFormat: "pl-PL",
  //   logo: require("./../assets/flags/pl.svg"),
  // },
  // {
  //   id: "cs",
  //   label: "Česky",
  //   timeFormat: "cs-CZ",
  //   logo: require("./../assets/flags/cz.svg"),
  // },
];

type languageId = ["de", "en", "pl", "cs"][number];

export const getLanguageById = (id: string) => {
  return languages.find((l) => l.id === id) || languages[0];
};
