import {
  MapContainer,
  TileLayer,
  Marker,
  Polygon,
  Pane,
  Tooltip,
} from "react-leaflet";

import { translateText } from "localization/translate";

import { latLng, latLngBounds, divIcon } from "leaflet";

import React, {
  setGlobal,
  useGlobal,
  useState,
  useLayoutEffect,
  useEffect,
  useMemo,
  useRef,
} from "reactn";

import { renderToString } from "react-dom/server";

import { FaArrowAltCircleDown, FaArrowAltCircleUp } from "react-icons/fa";
import { INTERVALS_CASES, COLOR_SECONDARY1, COLOR_PRIMARY } from "variables";

import styled from "styled-components";

import { bbox, feature, area, polygon } from "turf";
import polylabel from "polylabel";
import { IInterval } from "models";

interface IRegionMapProps {
  center: [number, number];
  width: string;
  height: string;
  regionsDict: { id: string; fullname: string }[];
  regionsGeo: {
    properties: { region: string };
    geometry: { coordinates: [][] };
  }[];
  boundaries: {
    properties: { region: string };
    geometry: { coordinates: [][] };
  };
  data: { [id: string]: { v: number; c: number } };
  intervals: IInterval[];
  selectedRegionId: string | false;
  selectedColor: string;
  selectRegion: (newRegionId: string) => void;
  legendTitle: string;
}

export const centroid = (feature: any) => {
  if (feature.geometry.type === "Point") {
    return feature.geometry.coordinates;
  }
  const featurePolygons =
    feature.geometry.coordinates[0].length !== 1
      ? [polygon(feature.geometry.coordinates)]
      : feature.geometry.coordinates.map((c: any) => polygon(c));

  const biggestPolygon = featurePolygons.reduce((max: any, pol: any) => {
    return max === false || area(pol) > area(max) ? pol : max;
  }, false);

  const regionCenter = polylabel(biggestPolygon.geometry.coordinates, 1.0);

  return latLng(regionCenter[0], regionCenter[1]);
};

const MapSymbol = styled.span.attrs<{ increase: boolean }>(({ increase }) => ({
  className: "content",
}))<{ increase: boolean }>`
  color: ${({ increase }) => (increase ? "red" : "green")};
  font-size: 15px;
  border-radius: 50%;
  border: 2px solid white;
  background-color: white;
  display: inline-block;
  vertical-align: middle;
`;

const decreaseSymbol = (
  <MapSymbol increase={false}>
    <FaArrowAltCircleDown />
  </MapSymbol>
);

const increaseSymbol = (
  <MapSymbol increase={true}>
    <FaArrowAltCircleUp />
  </MapSymbol>
);

const RegionMap = ({
  center,
  width,
  height,
  regionsDict,
  regionsGeo,
  boundaries,
  data,
  intervals,
  selectedRegionId,
  selectedColor,
  selectRegion,
  legendTitle,
}: IRegionMapProps) => {
  const valueToIntensity = (value: number) => {
    return intervals.find((i) => i.from <= value && i.to >= value);
  };

  const isRegionSelected = (id: string) => {
    return selectedRegionId == id;
  };

  const [map, setMap] = useState<false | any>(false);

  useEffect(() => {
    if (map) {
      //console.log("invalidating size");
      map.invalidateSize();

      try {
        const boundF = feature(boundaries.geometry as any);
        const boundBB = bbox(boundF);
        const boundLl = latLngBounds(
          latLng(boundBB[0], boundBB[1]),
          latLng(boundBB[2], boundBB[3])
        );
        map.fitBounds(boundLl);
      } catch (e) {
        console.log("problem fitting boundaries");
      }
    }
  }, [map]);

  const renderSymbol = (feature: any) => {
    if (data[feature.properties.region]) {
      const regionChange = data[feature.properties.region].c;
      const region = regionsDict.find(
        (d: any) => d.id == feature.properties.region
      );

      const regionCenter = centroid(feature);
      let symbol: false | React.ReactElement = false;

      if (regionChange > 0.1) {
        symbol = increaseSymbol;
      } else if (regionChange < -0.1) {
        symbol = decreaseSymbol;
      }
      if (region && symbol) {
        const size = [20, 20];
        return (
          <Marker
            key={feature.properties.region}
            position={regionCenter}
            icon={divIcon({
              html: renderToString(symbol),
              className: "map-sort-icon",
              iconSize: [size[0], size[1]],
              iconAnchor: [size[0] / 2, size[1] / 2],
            })}
            eventHandlers={{
              click: (e) => {
                selectRegion(feature.properties.region);
              },
            }}
          >
            <Tooltip sticky pane="tooltipPane">
              {region.fullname}
            </Tooltip>
          </Marker>
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  const renderRegion = (feature: any, mode: "selected" | "default") => {
    if (data[feature.properties.region]) {
      const regionCases = data[feature.properties.region].v;

      const region = regionsDict.find(
        (d: any) => d.id == feature.properties.region
      );

      const intensityInterval = valueToIntensity(regionCases || 0);

      if (region) {
        return (
          <Polygon
            eventHandlers={{
              click: (e) => {
                selectRegion(
                  mode === "selected" ? false : feature.properties.region
                );
              },
            }}
            key={feature.properties.region}
            positions={feature.geometry.coordinates}
            pathOptions={{
              color: mode === "selected" ? selectedColor : "white",
              weight: mode === "selected" ? 6 : 2,
              fillColor: intensityInterval?.color || "grey",
              fillOpacity: 1,
            }}
          >
            <Tooltip sticky pane="tooltipPane">
              {region.fullname}
            </Tooltip>
          </Polygon>
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  return (
    <>
      <MapContainer
        center={center}
        zoom={8}
        scrollWheelZoom={false}
        style={{ width: width, height: height }}
        // whenCreated={(map) => {
        //   setMap(map);
        // }}
      >
        <MapLegend>
          <MapLegendColumns>
            <MapLegendColumn>
              <MapLegendTitle>{legendTitle}</MapLegendTitle>
              <MapLegendContent>
                {intervals.map((interval: any) => {
                  return (
                    <MapLegendLine key={interval.intervalNo}>
                      <MapLegendLineText>{interval.label}</MapLegendLineText>
                      <MapLegendLineBox color={interval.color} />
                    </MapLegendLine>
                  );
                })}
              </MapLegendContent>
              <MapLegendTitle>
                {translateText("region-map", "legend-change")}
              </MapLegendTitle>
              <MapLegendContent>
                <MapLegendLine key="increase-symbol">
                  <MapLegendLineText>
                    {translateText("region-map", "legend-decrease")}
                  </MapLegendLineText>
                  <MapSymbol increase={true}>
                    <FaArrowAltCircleUp />
                  </MapSymbol>
                </MapLegendLine>
                <MapLegendLine key="decrease-symbol">
                  <MapLegendLineText>
                    {translateText("region-map", "legend-increase")}
                  </MapLegendLineText>
                  <MapSymbol increase={false}>
                    <FaArrowAltCircleDown />
                  </MapSymbol>
                </MapLegendLine>
              </MapLegendContent>
            </MapLegendColumn>
          </MapLegendColumns>
        </MapLegend>

        <Pane
          name="regions-selected"
          style={{ zIndex: 250, mixBlendMode: "multiply" }}
        >
          {regionsGeo
            .filter((f: any) => isRegionSelected(f.properties.region))
            .map((feature: any) => renderRegion(feature, "selected"))}
        </Pane>
        <Pane name="regions" style={{ zIndex: 200, mixBlendMode: "multiply" }}>
          {regionsGeo
            .filter((f: any) => !isRegionSelected(f.properties.region))
            .map((feature: any) => renderRegion(feature, "default"))}
        </Pane>
        <Pane name="symbols" style={{ zIndex: 500, mixBlendMode: "normal" }}>
          {regionsGeo.map((feature: any) => renderSymbol(feature))}
        </Pane>

        <Pane
          name="boundaries"
          style={{ zIndex: 200, mixBlendMode: "multiply" }}
        >
          {boundaries && boundaries.geometry && (
            <Polygon
              positions={boundaries?.geometry.coordinates}
              pathOptions={{
                color: "black",
                weight: 3,
                fillColor: "none",
                fillOpacity: 0,
              }}
            />
          )}
        </Pane>

        <TileLayer
          url="https://stamen-tiles-{s}.a.ssl.fastly.net/toner-lite/{z}/{x}/{y}{r}.png"
          attribution='Map tiles by <a href="http://stamen.com">Stamen Design</a>, <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a> &mdash; Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          subdomains="abcd"
          minZoom={0}
          maxZoom={20}
        />
      </MapContainer>
    </>
  );
};

/***
 * Styled components
 */

interface IMapLegend {
  width?: number;
}

export const MapLegend = styled.div.attrs<IMapLegend>(({ width }) => ({
  className: "leaflet-control leaflet-bar leaflet-bottom leaflet-right",
}))<IMapLegend>`
  max-width: ${(props) => props.width || 200};
  background: white;
  color: black;
  padding: 0.1em;
  margin: 1.5em;
  pointer-events: auto;
`;
export const MapLegendColumns = styled.div.attrs(() => ({
  className: "",
}))`
  display: inline-flex;
`;
export const MapLegendColumn = styled.div.attrs(() => ({
  className: "",
}))`
  padding: 0.5em;
  :not(:last-child) {
    padding-right: 1em;
  }
  :not(:first-child) {
    padding-left: 1em;
    border-left: 1px dashed black;
  }
`;
export const MapLegendLine = styled.div`
  text-align: right;
`;
export const MapLegendLineBox = styled.div.attrs(() => ({
  className: "",
}))`
  display: inline-block;
  width: 25px;
  height: 12px;
  margin-left: 5px;
  vertical-align: middle;
  background-color: ${({ color }) => color};
`;
export const MapLegendLineCircle = styled.div.attrs(() => ({
  className: "",
}))`
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-left: 5px;
  border-radius: 50%;
  vertical-align: middle;
  background-color: ${({ color }) => color};
`;
export const MapLegendLineText = styled.div`
  vertical-align: middle;
  margin-right: 5px;
  display: inline;
  font-size: 12px;
`;

export const MapLegendContent = styled.div`
  border-collapse: separate;
  border-spacing: 5px;
`;
export const MapLegendTitle = styled.div`
  font-weight: bold;
  text-align: right;
`;

export default RegionMap;
