import React from "reactn";
import styled from "styled-components";

interface ParameterViewProps {
  Icon: any;
  value: number;
  title: string;
  description: string;
  valueMin: number;
  valueMax: number;
}

export const ParameterView = ({
  Icon,
  value,
  title,
  description,
  valueMin,
  valueMax,
}: ParameterViewProps) => {
  return (
    <ParameterViewDivMedia key={title}>
      <ParameterViewDivMediaLeft>
        <ParameterInputsDiv>
          <input
            className="slider is-medium"
            min={valueMin}
            max={valueMax}
            value={value}
            disabled
            type="range"
          />
          <div className="input-value">{value}</div>
        </ParameterInputsDiv>
        <Icon size={25} />
      </ParameterViewDivMediaLeft>
      <ParameterViewDivMediaRight data-tooltip={description}>
        {title}
      </ParameterViewDivMediaRight>
    </ParameterViewDivMedia>
  );
};

const ParameterInputsDiv = styled.div`
  display: flex;
  align-items: center;
  margin-left: 1.5em;
  margin-right: 1em;
  .slider {
    width: 20em;
    margin: 0em !important;
  }
  input {
    margin-left: 0.5em;
    width: 5em;
  }
  .input-value {
    margin-left: 0.3em;
    margin-right: 0.3em;
    font-size: 1em;
    width: 2em;
  }
`;

const ParameterViewDivMedia = styled.div.attrs(() => ({
  className: "media",
}))`
  align-items: center;
  margin-bottom: 0.1rem;
  padding-top: 0.2rem !important;
`;

const ParameterViewDivMediaRight = styled.div``;
const ParameterViewDivMediaLeft = styled.div.attrs(() => ({
  className: "media-left",
}))`
  display: flex;
  input {
    margin-right: 0.5em;
  }

  input.slider {
    width: 10em;
  }
`;
