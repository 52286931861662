import React, {
  setGlobal,
  useGlobal,
  useState,
  useLayoutEffect,
  useEffect,
  useMemo,
  useRef,
} from "reactn";

import { Stage, Layer, Line, Text, Circle, Group, Rect } from "react-konva";

import Colors, {
  BOX_PADDING,
  COLOR_PRIMARY,
  COLOR_SECONDARY1,
  COLOR_SECONDARY2,
} from "variables";

import { formatDate, t, languages } from "localization/translate";

interface IDatasetLabel {
  y: number;
  text: string;
  color: string;
}

type ILine = {
  id: string;
  label: string;
  color: string;
  data: { [id: string]: { t: number; m: number; b: number } };
  polygonCoordinates?: number[][];
  lineCoordinates?: number[][];
  displayPolygon?: boolean;
};

type ITimelineProps = {
  width: number;
  height: number;
  lines: ILine[];
  timeSlices: string[];
  selectedSlice: string;
  todaySlice?: string;
  topValue: number;
  tickValues: number[];
  handleChangeSelected: Function;
};

const checkCollision = (
  newVal: number,
  vals: number[],
  difference: number
): number => {
  const inCollision = vals.find((val) => Math.abs(val - newVal) < difference);

  if (inCollision) {
    newVal = newVal > inCollision ? newVal + difference : newVal - difference;
    return checkCollision(newVal, vals, difference);
  } else {
    return newVal;
  }
};

const fontSize = 13;
const shadowColor = "darkgrey";

/**
 *
 * TODO: do not calculate anything when width = 0
 */

const Timeline = ({
  width,
  height,
  lines,
  timeSlices,
  selectedSlice,
  todaySlice,
  topValue,
  tickValues,
  handleChangeSelected,
}: ITimelineProps) => {
  const [lang, setLang] = useGlobal("lang");
  const [shadow, setShadow] = useState<string | false>(false);

  const margins = {
    t: 30,
    l: 40,
    r: 200,
    b: 90,
  };

  // set sizes when width and height changes
  const chartW = useMemo(() => {
    return width - margins.r - margins.l;
  }, [width]);
  const chartH = useMemo(() => {
    return height - margins.t - margins.b;
  }, [height]);

  const oneValueH: number = useMemo(() => {
    return chartH / topValue;
  }, [height, topValue]);

  const oneSliceW: number = useMemo(() => {
    return chartW / (timeSlices.length - 1);
  }, [width, timeSlices.length]);

  const xCoord = (val: number) => margins.l + oneSliceW * val;
  const yCoord = (val: number) =>
    width === 0 ? 0 : margins.t + (topValue - val) * oneValueH;

  // set line coordinates everytime width and height changes

  const datasets = useMemo(() => {
    return lines.map((line) => {
      line.lineCoordinates = timeSlices
        .filter((timeSlice) => timeSlice in line.data)
        .map((timeSlice, ti) => {
          const val = line.data[timeSlice]?.m > 0 ? line.data[timeSlice]?.m : 0;
          return [xCoord(ti), yCoord(val)];
        });

      const bottomPoints = timeSlices.map((timeSlice, ti) => {
        return [xCoord(ti), yCoord(line.data[timeSlice]?.b || 0)];
      });
      const topPoints = timeSlices.map((timeSlice, ti) => {
        return [xCoord(ti), yCoord(line.data[timeSlice]?.t || 0)];
      });

      line.polygonCoordinates =
        width === 0 ? [] : [...bottomPoints, ...topPoints.reverse()];
      return line;
    });
  }, [width, height, lines]);

  const textToday: string = useMemo(() => {
    return t("timeline", "today", lang) as string;
  }, [lang]);
  const textPrediction: string = useMemo(() => {
    return t("timeline", "prediction", lang) as string;
  }, [lang]);

  const datasetsLabels = useMemo(() => {
    const newDatasetLabels: IDatasetLabel[] = [];

    lines.forEach((line) => {
      const y =
        line.lineCoordinates && line.lineCoordinates.length
          ? line.lineCoordinates[line.lineCoordinates.length - 1][1]
          : -100;

      const previousYs = newDatasetLabels.map((l) => l.y);

      const yWithoutCollision = checkCollision(y, previousYs, 10);
      newDatasetLabels.push({
        text: line.label,
        y: yWithoutCollision,
        color: line.color,
      });
    });
    return newDatasetLabels;
  }, [lines, width, height]);

  const sliceByX = (x: number) => {
    const slice = Math.round(
      ((x - margins.l) / chartW) * (timeSlices.length - 1)
    );
    if (slice > -1 && slice < timeSlices.length) {
      return slice;
    } else {
      return false;
    }
  };

  const setNewSelected = (newSelectedX: number) => {
    const newSliceNo = sliceByX(newSelectedX);
    if (newSliceNo !== false) {
      handleChangeSelected(timeSlices[newSliceNo]);
    }
  };

  const setNewShadow = (newShadowX: number | false) => {
    if (newShadowX === false) {
      setShadow(false);
    } else {
      const newSliceNo = sliceByX(newShadowX);
      setShadow(newSliceNo === false ? false : timeSlices[newSliceNo]);
    }
  };

  return (
    <Stage
      height={height}
      width={width}
      onClick={(e: any) => {
        setNewSelected(e.evt.layerX);
      }}
      onMouseMove={(e: any) => {
        setNewShadow(e.evt.layerX);
      }}
      onMouseOut={(e: any) => {
        setNewShadow(false);
      }}
      // onContentTouchStart={(e: any) => {
      //     setNewSelected(e.evt.layerX);
      //     setNewShadow(false);
      // }}
      // onContentTouchEnd={(e: any) => {
      //     setNewSelected(e.evt.layerX);
      //     setNewShadow(false);
      // }}
      // onCountentTouchMove={(e: any) => {
      //     setNewShadow(e.evt.layerX);
      // }}
    >
      <Layer key="axis">
        <Line
          points={[
            margins.l,
            height - margins.b,
            width - margins.r,
            height - margins.b,
          ]}
          stroke="black"
          fill="black"
        />
        {timeSlices.map((timeSlice, ti) => {
          return !(ti % 2) ? (
            <Text
              key={ti}
              text={formatDate(timeSlice, {
                format: { month: "short", year: "2-digit" },
              })}
              fill={timeSlice === shadow ? "grey" : "black"}
              rotation={90}
              x={xCoord(ti) + 5}
              y={
                todaySlice && ti > timeSlices.indexOf(todaySlice)
                  ? height - margins.b + 25
                  : height - margins.b + 5
              }
              fontSize={fontSize - 2}
              fontStyle={timeSlice === selectedSlice ? "bold" : "normal"}
            />
          ) : (
            false
          );
        })}
        {tickValues.map((tick, ti) => {
          const y = yCoord(tick);
          return (
            <Group key={ti}>
              <Text
                fontSize={fontSize}
                text={tick.toString()}
                align="right"
                width={35}
                x={margins.l - 45}
                y={y - fontSize / 2}
              />
              <Line
                points={[margins.l - 5, y, width - margins.r, y]}
                stroke="black"
                fill="black"
                strokeWidth={0.5}
              />
            </Group>
          );
        })}
      </Layer>
      <Layer key="today">
        {todaySlice ? (
          <Group>
            <Text
              fontSize={fontSize}
              text={(t("timeline", "today", lang) as string) || ""}
              x={xCoord(timeSlices.indexOf(todaySlice)) - 15}
              y={margins.t + 100}
              width={90}
              align={"right"}
              rotation={-90}
              fill="grey"
            />
            <Line
              points={[
                xCoord(timeSlices.indexOf(todaySlice)),
                height - margins.b,
                xCoord(timeSlices.indexOf(todaySlice)),
                margins.t,
              ]}
              dash={[5, 5]}
              stroke="grey"
              fill="grey"
            />
          </Group>
        ) : null}
        {todaySlice ? (
          <Group key="prediction">
            <Rect
              x={xCoord(timeSlices.indexOf(todaySlice))}
              y={margins.t}
              width={
                chartW - xCoord(timeSlices.indexOf(todaySlice)) + margins.l
              }
              height={chartH}
              fill="grey"
              opacity={0.2}
            />
            <Text
              fontSize={fontSize}
              fontStyle="italic"
              fill="grey"
              text={(t("timeline", "prediction", lang, {}) as string) || ""}
              x={xCoord(timeSlices.indexOf(todaySlice) + 1)}
              y={margins.t + chartH + 5}
            />
          </Group>
        ) : null}
      </Layer>
      <Layer key="labels">
        <Group key="dataset-labels">
          {datasetsLabels.map((datasetLabel, li) => {
            return (
              <Text
                fontSize={fontSize}
                key={li}
                text={datasetLabel.text}
                align="left"
                width={margins.r}
                x={width - margins.r + 15}
                y={datasetLabel.y}
                fill={datasetLabel.color}
                fontStyle="bold"
              />
            );
          })}
        </Group>
        {/* <Group key="dataset-selected-slice-value">
          {selectedSlice &&
            datasets.map((line, li) => {
              const sliceIndex = timeSlices.indexOf(selectedSlice);
              console.log(sliceIndex);
              return (
                <Text
                  fontSize={fontSize}
                  key={li}
                  text={`${Math.round(line.data[selectedSlice].m)}` || ""}
                  x={xCoord(sliceIndex) + 5}
                  y={
                    line.lineCoordinates
                      ? line.lineCoordinates[sliceIndex][1] - 15
                      : -100
                  }
                  fill={line.color}
                />
              );
            })}
        </Group> */}
      </Layer>
      <Layer key="lines">
        {datasets
          .filter((l) => l && l.lineCoordinates)
          .map((line) => {
            return (
              <Group key={line.id}>
                {line &&
                  line.lineCoordinates &&
                  line.lineCoordinates.map((coordinate, ci) => {
                    return (
                      <Circle
                        key={ci}
                        radius={
                          timeSlices.indexOf(selectedSlice) === ci ? 3 : 2
                        }
                        x={coordinate[0]}
                        y={coordinate[1]}
                        stroke={
                          shadow && timeSlices.indexOf(shadow) === ci
                            ? shadowColor
                            : line.color
                        }
                        fill={
                          shadow && timeSlices.indexOf(shadow) === ci
                            ? shadowColor
                            : line.color
                        }
                      />
                    );
                  })}
                <Line
                  key={line.id}
                  stroke={line.color}
                  globalCompositeOperation="multiply"
                  points={
                    line.lineCoordinates
                      ? line.lineCoordinates.reduce(
                          (flat, val) => flat.concat(val),
                          []
                        )
                      : []
                  }
                />
              </Group>
            );
          })}
        {datasets
          .filter((d) => d.displayPolygon)
          .filter((d) => d.polygonCoordinates && d.polygonCoordinates.length)
          .map((dataset) => {
            const coordinates = dataset.polygonCoordinates
              ? dataset.polygonCoordinates.reduce(
                  (flat, val) => flat.concat(val),
                  []
                )
              : [];

            return (
              <Group key={dataset.id}>
                <Line
                  closed
                  opacity={0.3}
                  fill={dataset.color}
                  key={dataset.id}
                  stroke={dataset.color}
                  points={coordinates}
                  globalCompositeOperation="multiply"
                />
              </Group>
            );
          })}
      </Layer>
      <Layer key="selected">
        <Group key="selected-line">
          <Line
            points={[
              xCoord(timeSlices.indexOf(selectedSlice)),
              0,
              xCoord(timeSlices.indexOf(selectedSlice)),
              height - margins.b,
            ]}
            strokeWidth={3}
            stroke="black"
            fill="black"
          />
          <Circle
            radius={5}
            x={xCoord(timeSlices.indexOf(selectedSlice))}
            y={5}
            strokeWidth={0}
            stroke="black"
            fill="black"
          />
          <Text
            fontSize={fontSize}
            text={selectedSlice}
            align="center"
            x={xCoord(timeSlices.indexOf(selectedSlice)) + 10}
            y={0}
            fontStyle="bold"
          />
          {!shadow ? (
            <Group key="tooltip">
              {datasets.map((dataset, di) => {
                const value = dataset.data[selectedSlice]?.m;
                if (value) {
                  const activeLanguage = languages.find((l) => l.id === lang);
                  let langFormat = "de-DE";
                  if (activeLanguage) {
                    langFormat = activeLanguage.timeFormat;
                  }
                  const localValue = parseFloat(
                    value.toFixed(2)
                  ).toLocaleString(langFormat);

                  return (
                    <Group key={di}>
                      <Text
                        text={dataset.label}
                        key="dataset-name"
                        lineJoin="round"
                        fontSize={fontSize}
                        strokeWidth={5}
                        stroke="white"
                        fillAfterStrokeEnabled={true}
                        align="left"
                        x={xCoord(timeSlices.indexOf(selectedSlice)) + 5}
                        y={35 + di * 40}
                        fill={dataset.color}
                      />
                      <Text
                        key="value"
                        lineJoin="round"
                        text={localValue}
                        fontSize={fontSize}
                        strokeWidth={5}
                        stroke="white"
                        fillAfterStrokeEnabled={true}
                        x={xCoord(timeSlices.indexOf(selectedSlice)) + 5}
                        y={50 + di * 40}
                        fill={dataset.color}
                      />
                    </Group>
                  );
                }
              })}
            </Group>
          ) : null}
        </Group>
        {shadow ? (
          <Group key="shadow">
            <Line
              points={[
                xCoord(timeSlices.indexOf(shadow)),
                20,
                xCoord(timeSlices.indexOf(shadow)),
                height - margins.b,
              ]}
              strokeWidth={2}
              stroke={shadowColor}
            />
            <Circle
              radius={5}
              x={xCoord(timeSlices.indexOf(shadow))}
              y={20}
              strokeWidth={0}
              stroke={shadowColor}
              fill={shadowColor}
            />
            <Text
              fontSize={fontSize}
              text={shadow}
              strokeWidth={5}
              stroke="white"
              fillAfterStrokeEnabled={true}
              x={xCoord(timeSlices.indexOf(shadow)) + 10}
              y={15}
              fontStyle="bold"
              fill={shadowColor}
            />
          </Group>
        ) : null}
        {shadow ? (
          <Group key="tooltip">
            {datasets
              .filter((dataset) => dataset.data[shadow])
              .map((dataset, di) => {
                const value = dataset.data[shadow].m;
                const activeLanguage = languages.find((l) => l.id === lang);
                let langFormat = "de-DE";
                if (activeLanguage) {
                  langFormat = activeLanguage.timeFormat;
                }
                const localValue = parseFloat(value.toFixed(2)).toLocaleString(
                  langFormat
                );
                return (
                  <Group key={di}>
                    <Text
                      key="dataset-name"
                      lineJoin="round"
                      fontSize={fontSize}
                      text={dataset.label}
                      strokeWidth={5}
                      stroke="white"
                      fillAfterStrokeEnabled={true}
                      align="left"
                      x={xCoord(timeSlices.indexOf(shadow)) + 5}
                      y={35 + di * 40}
                      fill={dataset.color}
                    />
                    <Text
                      key="value"
                      lineJoin="round"
                      text={localValue}
                      fontSize={fontSize}
                      strokeWidth={5}
                      stroke="white"
                      fillAfterStrokeEnabled={true}
                      x={xCoord(timeSlices.indexOf(shadow)) + 5}
                      y={50 + di * 40}
                      fill={dataset.color}
                    />
                  </Group>
                );
              })}
          </Group>
        ) : null}
      </Layer>
    </Stage>
  );
};

export default Timeline;
