import React, {
  setGlobal,
  useGlobal,
  useState,
  useLayoutEffect,
  useEffect,
  useRef,
  useMemo,
} from "reactn";

import { Box } from "components/box";
import { Title1 } from "components/title1";
import { Title2 } from "components/title2";
import { Title3 } from "components/title3";

import { translate, translateText } from "localization/translate";

//import { withRouter, RouteComponentProps } from "react-router-dom";

import {
  INTERVALS_CASES,
  INTERVALS_TESTS,
  COLOR_SECONDARY1,
  COLOR_PRIMARY,
} from "variables";
import styled from "styled-components";

const { default: logoCasus } = require("assets/logo-casus.png");

//interface ContactPageProps extends RouteComponentProps {}
interface ContactPageProps {}

const ContactPage = ({}: ContactPageProps) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);
  const [wrapperWidth, setWrapperWidth] = useState(0);
  const [headerHeight, setHeaderHeight] = useState(0);

  const iframeRef = useRef(null);

  const marginH = useMemo(() => {
    return headerHeight + 320;
  }, [headerHeight]);

  useEffect(() => {
    const handleResize = () => {
      if (wrapperRef.current) {
        //console.log("setting width", wrapperRef.current.offsetWidth);
        setWrapperWidth(wrapperRef.current.offsetWidth);
      }
      if (headerRef.current) {
        setHeaderHeight(headerRef.current.offsetHeight);
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
  }, [wrapperRef.current]);

  return (
    <WrapperDiv ref={wrapperRef}>
      <HeaderSection ref={headerRef}>
        <Title1>{translate("covidsim", "title")}</Title1>
      </HeaderSection>
      <Box>
        <BoxContent>
          <iframe
            style={{
              height: `calc(100% - ${marginH}px)`,
            }}
            ref={iframeRef}
            width="100%"
            src="/covid-sim"
          />
        </BoxContent>
      </Box>
    </WrapperDiv>
  );
};

const HeaderSection = styled.div`
  margin: 10px;
  padding-top: 20px;
  padding-bottom: 10px;
`;

const WrapperDiv = styled.div.attrs(() => ({
  className: "content",
}))``;
const BoxContent = styled.div`
  padding: 0.5em;
`;

//export default withRouter(ContactPage);
export default ContactPage;
