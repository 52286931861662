import api from "api";
import chroma from "chroma-js";
import { Box } from "components/box";
import { Modal } from "components/modal";
import {
  centroid,
  MapLegend,
  MapLegendColumn,
  MapLegendColumns,
  MapLegendContent,
  MapLegendLine,
  MapLegendLineBox,
  MapLegendLineCircle,
  MapLegendLineText,
  MapLegendTitle,
} from "components/region-map";
import { Title } from "components/title";
import { divIcon, latLng } from "leaflet";
import { t } from "localization/translate";
import { renderToString } from "react-dom/server";
import {
  FaArrowCircleDown,
  FaArrowCircleUp,
  FaCircle,
  FaDotCircle,
  FaInfo,
  FaQuestionCircle,
} from "react-icons/fa";
import {
  Circle,
  Group,
  Layer,
  Line,
  Rect,
  RegularPolygon,
  Stage,
  Text,
} from "react-konva";
import {
  CircleMarker,
  LayerGroup,
  MapContainer,
  Marker,
  Pane,
  Polygon,
  TileLayer,
  Tooltip,
} from "react-leaflet";
import { ThreeDots } from "react-loading-icons";
import { useQuery } from "react-query";
//import { withRouter, RouteComponentProps } from "react-router-dom";
import React, { useEffect, useGlobal, useMemo, useRef, useState } from "reactn";
import styled from "styled-components";
import { COLOR_PRIMARY, COLOR_SECONDARY2 } from "variables";

const { default: flip } = require("@turf/flip");

const regToMunDict = {
  "14511": "145110000000",
  "14521": "145210020020",
  "14522": "145220180180",
  "14523": "145230320320",
  "14612": "146120000000",
  "14713": "147130000000",
};

const regionToMunId = (regId: string) => {
  if (Object.keys(regToMunDict).includes(regId)) {
    //@ts-ignore
    return regToMunDict[regId];
  } else {
    return regId;
  }
};

interface IRegionData {
  [key: string]: { geometry: any; properties: any };
}
const regionMunicipalities: IRegionData = {};
const regionKreis: IRegionData = {};

flip(
  require("./../../data/spatial/geo_saxony_gemeinde_points.json")
).features.forEach((feat: any) => {
  regionMunicipalities[feat.properties.region] = {
    geometry: feat.geometry,
    properties: feat.properties,
  };
});

flip(
  require("./../../data/spatial/geo_saxony_county_simplified.json")
).features.forEach((feat: any) => {
  regionKreis[feat.properties.region] = {
    geometry: feat.geometry,
    properties: feat.properties,
  };
});

const regionAdmin = require("./../../data/spatial/geo_saxony_gemeinde_admin.json");

export enum IWWMethod {
  "Q" = "quantiles",
  "MM" = "minmax",
  "SD" = "standard-deviation",
}

export interface IWastewaterMapR {
  region: { [key: string]: number };
  ww: { [key: string]: number };
}

export interface IWastewaterHeatmapR {
  [key: string]: {
    ww_vals: { [key: string]: number };
    r_vals: { [key: string]: number };
  };
}

enum IMetric {
  INC = "inc",
  CH = "change",
  PLUS = "increase",
}
enum IRadius {
  M30 = "30",
  M60 = "60",
  KREIS = "kreis",
}

interface WastewaterPageProps { }
const WastewaterPage = ({ }: WastewaterPageProps) => {
  const [lang, setLang] = useGlobal("lang");
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [wPage, setWPage] = useState<number>(0);

  const [date, setDate] = useState<string>("2021-10-24");
  const [radius, setRadius] = useState<IRadius>(IRadius.KREIS);
  const [metric, setMetric] = useState<IMetric>(IMetric.INC);
  const [wwMethod, setWwMethod] = useState<IWWMethod>(IWWMethod.Q);

  const [openInfo, setOpenInfo] = useState<boolean>(false);

  useEffect(() => {
    const handleResize = () => {
      if (wrapperRef.current) {
        //console.log("setting width", wrapperRef.current.offsetWidth);
        setWPage(wrapperRef.current.offsetWidth);
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
  }, [wrapperRef.current]);

  const {
    status: statusHeatmap,
    data: dataHeatmap,
    error: errorHeatmap,
    isFetching: isFetchingHeatmap,
  } = useQuery(
    ["heatmap", metric, radius],
    async (): Promise<IWastewaterHeatmapR> => {
      const res = await api.wastewaterHeatmap(radius, metric);
      return res;
    },
    { enabled: true, initialData: {} }
  );

  const {
    status: statusMap,
    data: dataMap,
    error: errorMap,
    isFetching: isFetchingMap,
  } = useQuery(
    ["map", date, , metric, radius],
    async (): Promise<IWastewaterMapR> => {
      const res = await api.wastewaterMap(radius, metric, date);
      return res;
    },
    { enabled: true, initialData: { region: {}, ww: {} } }
  );

  const dates = useMemo(() => {
    if (dataHeatmap) {
      const firstPlantKey = Object.keys(dataHeatmap)[0];
      return firstPlantKey
        ? Object.keys(dataHeatmap[firstPlantKey].r_vals)
        : [];
    } else {
      return [];
    }
  }, [dataHeatmap]);

  const renderInformationModal = () => {
    return (
      <Modal
        buttons={[]}
        heading={t("wastewater", "title", lang)}
        headerColor={COLOR_SECONDARY2}
        renderBody={() => {
          return (
            <div>
              <Title size="m">{t("wastewater", "about-title1", lang)}</Title>
              <div style={{ marginBottom: "1em" }}>
                {t("wastewater", "about-text1", lang)}
              </div>
              <Title size="m">{t("wastewater", "about-title2", lang)}</Title>
              <div style={{ marginBottom: "1em" }}>
                {t("wastewater", "about-text2", lang)}
              </div>
              <Title size="m">{t("wastewater", "about-title3", lang)}</Title>
              <div style={{ marginBottom: "1em" }}>
                {t("wastewater", "about-text3", lang)}
              </div>
            </div>
          );
        }}
        handleExit={() => {
          setOpenInfo(false);
        }}
      />
    );
  };

  const renderDataProblem = () => {
    return (errorMap || errorHeatmap ?
      (
        <article className="message is-danger">
          <div className="message-header">
            <p>{t("general", "dataproblem-title", lang)}</p>
          </div>
          <div className="message-body">
            {t("general", "dataproblem-text", lang)}
          </div>
        </article>
      )
      : (<div />))
  };


  return (
    <WrapperDiv ref={wrapperRef} id="printable-content">
      {openInfo && renderInformationModal()}
      <HeaderSection>
        <HeaderSectionTitle>
          <Title size="xl" marginBottom={0} noTopPadding>
            {t("wastewater", "title", lang)}

            <button
              className="button is-info information-button"
              onClick={() => setOpenInfo(true)}
              style={{ marginBottom: "9px", height: "2em", padding: "0.5em" }}
            >
              <span className="icon">
                <FaInfo />
              </span>
              <span>{t("wastewater", "info-button", lang)}</span>
            </button>
            {(isFetchingHeatmap || isFetchingMap) && (
              <React.Fragment>
                <ThreeDots
                  fill={COLOR_PRIMARY}
                  style={{
                    height: "0.5em",
                    verticalAlign: "middle",
                    marginLeft: "0.25em",
                  }}
                  stroke={COLOR_PRIMARY}
                />
              </React.Fragment>
            )}
          </Title>
        </HeaderSectionTitle>
      </HeaderSection>
      {renderDataProblem()}
      {
        !errorMap && !errorHeatmap && (

          <React.Fragment>
            <HeaderMenu>
              <HeaderMenuItem>
                <HeaderMenuItemLabel>
                  {t("wastewater", "selection-week", lang)}
                </HeaderMenuItemLabel>
                <HeaderMenuItemValue>
                  <div className="select is-small">
                    <select
                      onChange={(e) => {
                        const newDate = e.target.value;
                        setDate(newDate);
                      }}
                      value={date}
                    >
                      {dates.map((date) => {
                        return (
                          <option value={date} key={date}>
                            {date}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </HeaderMenuItemValue>
              </HeaderMenuItem>
              <HeaderMenuItem>
                <HeaderMenuItemLabel>
                  {t("wastewater", "selection-neighborhood", lang)}
                </HeaderMenuItemLabel>
                <HeaderMenuItemValue>
                  <ButtonGroup>
                    <Button
                      selected={radius === IRadius.KREIS}
                      key={IRadius.KREIS}
                      onClick={() => setRadius(IRadius.KREIS)}
                    >
                      {t("wastewater", "selection-neighborhood-region", lang)}
                    </Button>
                    <Button
                      selected={radius === IRadius.M30}
                      key={IRadius.M30}
                      onClick={() => setRadius(IRadius.M30)}
                    >
                      {t(
                        "wastewater",
                        "selection-neighborhood-municipalities30",
                        lang
                      )}
                    </Button>
                    <Button
                      selected={radius === IRadius.M60}
                      key={IRadius.M60}
                      onClick={() => setRadius(IRadius.M60)}
                    >
                      {t(
                        "wastewater",
                        "selection-neighborhood-municipalities60",
                        lang
                      )}
                    </Button>
                  </ButtonGroup>
                </HeaderMenuItemValue>
              </HeaderMenuItem>
              <HeaderMenuItem>
                <HeaderMenuItemLabel>
                  {t("wastewater", "selection-indicator", lang)}
                </HeaderMenuItemLabel>
                <HeaderMenuItemValue>
                  <ButtonGroup>
                    <Button
                      selected={wwMethod === IWWMethod.Q}
                      key={IWWMethod.Q}
                      onClick={() => setWwMethod(IWWMethod.Q)}
                    >
                      {t("wastewater", "selection-indicator-quantiles", lang)}
                    </Button>
                    <Button
                      selected={wwMethod === IWWMethod.MM}
                      key={IWWMethod.MM}
                      onClick={() => setWwMethod(IWWMethod.MM)}
                    >
                      {t("wastewater", "selection-indicator-minmax", lang)}
                    </Button>
                    <Button
                      selected={wwMethod === IWWMethod.SD}
                      key={IWWMethod.SD}
                      onClick={() => setWwMethod(IWWMethod.SD)}
                    >
                      {t("wastewater", "selection-indicator-sd", lang)}
                    </Button>
                  </ButtonGroup>
                </HeaderMenuItemValue>
              </HeaderMenuItem>
            </HeaderMenu>
            <Box>
              {dataHeatmap && wPage > 100 && (
                <WastewaterHeatmap
                  wPage={wPage}
                  data={dataHeatmap}
                  handleDateChange={(newDate: string) => {
                    setDate(newDate);
                  }}
                  dates={dates}
                  date={date}
                  radius={radius}
                  metric={metric}
                  wwMethod={wwMethod}
                />
              )}
              {dataMap && dataHeatmap && (
                <WastewaterMap
                  wPage={wPage}
                  data={dataMap}
                  dataHeatmap={dataHeatmap}
                  radius={radius}
                  metric={metric}
                  dates={dates}
                  date={date}
                  wwMethod={wwMethod}
                />
              )}
            </Box>
          </React.Fragment>
        )
      }
    </WrapperDiv >

  );
};

interface IWastewaterHeatmap {
  wPage: number;
  data: IWastewaterHeatmapR;
  handleDateChange: Function;
  dates: string[];
  date: string;
  radius: IRadius;
  metric: IMetric;
  wwMethod: IWWMethod;
}

const WastewaterHeatmap = ({
  wPage,
  data,
  handleDateChange,
  dates,
  date,
  radius,
  metric,
  wwMethod,
}: IWastewaterHeatmap) => {
  const moveTimeLine = (xPosition: number) => {
    const limits = [
      dateToX(0) - sizes.datesliceW / 2,
      dateToX(dates.length - 1) - sizes.datesliceW / 2,
    ];

    if (xPosition < limits[0]) {
      xPosition = limits[0];
    } else if (xPosition > limits[1]) {
      xPosition = limits[1];
    }

    const newDatePos = xToDate(xPosition);

    // console.log(newX, newDatePos);
    // e.target.position({
    //   x: activeDateX - sizes.timeCircleR / 2,
    //   y: sizes.timeLineY,
    // });

    if (newDatePos !== datePos) {
      handleDateChange(dates[newDatePos]);
    } else {
      return false;
    }
  };

  const sizes = useMemo(() => {
    const marginL = 30;
    const marginR = 30;

    const labelsW = 100;
    const timeCircleR = 8;

    const w = wPage - marginL - marginR;
    const timeMR = 30;
    const timeML = 30;
    const timeMT = 30;
    const timeMB = 20;

    const timeW = w - labelsW - timeMR - timeML;

    const plantH = 30;
    const timeH = 20;

    const datesL = dates.length;
    const datesliceW = timeW / (datesL - 1);

    const plantsL = Object.keys(data).length;

    const h = timeMT + timeH + plantH * plantsL + timeMB;

    const timeLineY = timeMT + timeH / 2;

    const timeLabelW = 100;

    const heatM = 0.1; // margin for heat radius
    const heatMaxR = Math.min(...[plantH, datesliceW]) - heatM;

    return {
      w,
      h,

      labelsW,
      timeW,

      timeH,
      plantH,

      datesliceW,
      timeMR,
      timeML,
      timeMT,

      timeLabelW,

      timeLineY,
      timeCircleR,

      heatM,
      heatMaxR,
    };
  }, [wPage, dates, data]);

  const plantY = (no: number) => sizes.timeMT + sizes.timeH + no * sizes.plantH;
  const dateToX = (date: number) =>
    sizes.labelsW + sizes.timeML + date * sizes.datesliceW;
  const xToDate = (x: number) =>
    Math.round((x - sizes.labelsW - sizes.timeML) / sizes.datesliceW);

  const datePos: number = useMemo(() => {
    return dates.indexOf(date);
  }, [date, dates]);

  const activeDateX = useMemo(() => {
    return dateToX(datePos);
  }, [date, sizes, dates]);

  return (
    <div key={JSON.stringify(data)}>
      <Stage
        width={sizes.w}
        height={sizes.h}
        onTap={(e: any) => {
          const target = e.target.getClientRect();
          let newX = target.x + target.width / 2;
          moveTimeLine(newX);
        }}
        onClick={(e: any) => {
          let newX = e.evt.offsetX;
          moveTimeLine(newX);
        }}
      >
        <Layer key="region-rect">
          {Object.keys(data).map((pKey: string, pNo: number) => {
            const plantData = data[pKey];

            const region =
              radius === IRadius.KREIS
                ? regionKreis[pKey]
                : regionMunicipalities[pKey];

            if (region) {
              const y = plantY(pNo);
              return (
                <Group key={pKey}>
                  <Text
                    y={y}
                    x={10}
                    height={sizes.plantH}
                    width={sizes.labelsW}
                    text={region.properties.name}
                    align="right"
                    verticalAlign="middle"
                    fontStyle="bold"
                  />

                  {Object.keys(plantData.r_vals).map((d: string) => {
                    const di = dates.indexOf(d);
                    const rVal = plantData.r_vals[d];
                    const active = date === d;
                    const rectX =
                      sizes.labelsW +
                      sizes.timeML +
                      sizes.datesliceW * (di - 0.75);

                    return (
                      <Group key={`inf${d}`}>
                        <Rect
                          y={y}
                          x={rectX}
                          fill={rColor(rVal, metric)}
                          opacity={0.8}
                          width={sizes.datesliceW + 0.5}
                          height={sizes.plantH}
                          strokeEnabled={false}
                        />
                        <Rect
                          y={y + sizes.plantH - 2}
                          x={rectX}
                          fill="white"
                          opacity={0.8}
                          width={sizes.datesliceW + 0.5}
                          height={2}
                          strokeEnabled={false}
                        />
                      </Group>
                    );
                  })}
                </Group>
              );
            } else {
              return false;
            }
          })}
        </Layer>
        <Layer key="timeslider">
          <Line
            points={[
              dateToX(0) - sizes.timeCircleR / 2,
              sizes.timeLineY,
              dateToX(dates.length - 1) - sizes.timeCircleR / 2,
              sizes.timeLineY,
            ]}
            key="horizontal-line"
            stroke="black"
          />

          <Text
            x={dateToX(0) - sizes.timeLabelW / 2 - sizes.timeCircleR / 2}
            y={sizes.timeMT - 10}
            width={sizes.timeLabelW}
            align="center"
            text={dates[0]}
            key="start-text"
          />
          <Line
            key="start-line"
            points={[
              dateToX(0) - sizes.timeCircleR / 2,
              sizes.timeLineY + sizes.timeCircleR,
              dateToX(0) - sizes.timeCircleR / 2,
              sizes.timeLineY - sizes.timeCircleR,
            ]}
            stroke="black"
          />

          <Text
            x={
              dateToX(dates.length - 1) -
              sizes.timeLabelW / 2 -
              sizes.timeCircleR / 2
            }
            y={sizes.timeMT - 10}
            width={sizes.timeLabelW}
            align="center"
            text={dates[dates.length - 1]}
            key="end-text"
          />

          <Line
            key="end-line"
            points={[
              dateToX(dates.length - 1) - sizes.timeCircleR / 2,
              sizes.timeLineY + sizes.timeCircleR,
              dateToX(dates.length - 1) - sizes.timeCircleR / 2,
              sizes.timeLineY - sizes.timeCircleR,
            ]}
            stroke="black"
          />

          <Text
            key="date-selected-text"
            x={activeDateX - sizes.timeLabelW / 2 - sizes.timeCircleR / 2}
            y={sizes.timeMT - 30}
            width={sizes.timeLabelW}
            fontVariant="bold"
            fill={COLOR_PRIMARY}
            align="center"
            textDecoration="underline"
            text={date}
          />
          <Line
            key="vertical-line"
            points={[
              activeDateX - sizes.timeCircleR / 2,
              15,
              activeDateX - sizes.timeCircleR / 2,
              //sizes.timeMT + sizes.timeH, //+
              sizes.timeMT + sizes.plantH * (Object.keys(data).length + 1),
            ]}
            //dash={[2, 4]}
            lineJoin="round"
            stroke={COLOR_PRIMARY}
          />
          <RegularPolygon
            key="active-triangle-top"
            x={activeDateX - sizes.timeCircleR / 2}
            y={sizes.timeLineY}
            radius={sizes.timeCircleR}
            sides={3}
            fill={COLOR_PRIMARY}
            stroke="white"
            strokeWidth={1.5}
            rotation={180}
            cursor="pointer"
            onMouseEnter={(e: any) => {
              const container = e.target.getStage().container();
              container.style.cursor = "grab";
            }}
            onMouseLeave={(e: any) => {
              const container = e.target.getStage().container();
              container.style.cursor = "default";
            }}
            onDragMove={(e: any) => {
              const container = e.target.getStage().container();
              container.style.cursor = "grabbing";

              let newX = e.target.position().x;

              const limits = [
                dateToX(0) - sizes.timeCircleR / 2,
                dateToX(dates.length - 1) - sizes.timeCircleR / 2,
              ];

              if (newX < limits[0]) {
                newX = limits[0];
              } else if (newX > limits[1]) {
                newX = limits[1];
              }

              const newDatePos = xToDate(newX);
              e.target.position({
                x: activeDateX - sizes.timeCircleR / 2,
                y: sizes.timeLineY,
              });

              if (newDatePos !== datePos) {
                handleDateChange(dates[newDatePos]);
              } else {
                return false;
              }
            }}
            draggable
          />
          <RegularPolygon
            key="active-triangle-bottom"
            x={activeDateX - sizes.timeCircleR / 2}
            y={sizes.timeMT + sizes.plantH * (Object.keys(data).length + 1)}
            radius={sizes.timeCircleR}
            sides={3}
            fill={COLOR_PRIMARY}
            stroke="white"
            strokeWidth={1.5}
            cursor="pointer"
            onMouseEnter={(e: any) => {
              const container = e.target.getStage().container();
              container.style.cursor = "grab";
            }}
            onMouseLeave={(e: any) => {
              const container = e.target.getStage().container();
              container.style.cursor = "default";
            }}
            onDragMove={(e: any) => {
              const container = e.target.getStage().container();
              container.style.cursor = "grabbing";

              let newX = e.target.position().x;

              const limits = [
                dateToX(0) - sizes.timeCircleR / 2,
                dateToX(dates.length - 1) - sizes.timeCircleR / 2,
              ];

              if (newX < limits[0]) {
                newX = limits[0];
              } else if (newX > limits[1]) {
                newX = limits[1];
              }

              const newDatePos = xToDate(newX);
              e.target.position({
                x: activeDateX - sizes.timeCircleR / 2,
                y: sizes.timeMT + sizes.plantH * (Object.keys(data).length + 1),
              });

              if (newDatePos !== datePos) {
                handleDateChange(dates[newDatePos]);
              } else {
                return false;
              }
            }}
            draggable
          />
        </Layer>

        <Layer key="ww-circles">
          {Object.keys(data).map((pKey: string, pNo: number) => {
            const plantData = data[pKey];

            const region =
              radius === IRadius.KREIS
                ? regionKreis[pKey]
                : regionMunicipalities[pKey];

            if (region) {
              const y = plantY(pNo);
              return (
                <Group key={pKey}>
                  {dates.map((d: string) => {
                    const di = dates.indexOf(d);
                    const ct = plantData.ww_vals[d];
                    const x =
                      sizes.labelsW +
                      sizes.timeML +
                      sizes.datesliceW * (di - 0.75);

                    const active = date === d;
                    if (ct) {
                      const color = wwColor(
                        ct,
                        Object.values(plantData.ww_vals),
                        wwMethod
                      );
                      return (
                        <Circle
                          opacity={active ? 1 : 1}
                          key={`inf${di}`}
                          stroke={"black"}
                          strokeWidth={1}
                          y={y + sizes.plantH / 2}
                          x={
                            sizes.labelsW +
                            sizes.timeML +
                            sizes.datesliceW * (di - 0.25)
                          }
                          fill={color}
                          radius={sizes.datesliceW / 2 - 2}
                        // radius={wwRadius(
                        //   d,
                        //   plantData.ww_vals,
                        //   sizes.heatMaxR
                        // )}
                        />
                      );
                    } else {
                      return null;
                    }
                  })}
                </Group>
              );
            } else {
              return false;
            }
          })}
        </Layer>
      </Stage>
    </div>
  );
};

interface IWastewaterMap {
  wPage: number;
  data: IWastewaterMapR;
  dataHeatmap: IWastewaterHeatmapR;
  dates: string[];
  date: string;
  radius: IRadius;
  metric: IMetric;
  wwMethod: IWWMethod;
}

const WastewaterMap = ({
  wPage,
  data,
  dataHeatmap,
  dates,
  date,
  radius,
  metric,
  wwMethod,
}: IWastewaterMap) => {
  const [lang, setLang] = useGlobal("lang");

  const center = latLng(51, 13.5);
  const height = 800;

  const gemeindeMarkerR = 4;

  return (
    <StyledMapWrapper key={`map-${wPage}`}>
      <MapContainer
        center={center}
        zoom={9}
        scrollWheelZoom={false}
        style={{ width: wPage - 50, height: height }}
      >
        <Pane name="gemeinde" style={{ zIndex: 500, mixBlendMode: "multiply" }}>
          {radius !== "kreis" &&
            Object.keys(data.region).map((regionId: string) => {
              const gemeinde = regionMunicipalities[regionId];
              return (
                <LayerGroup key={regionId + gemeinde.properties.name}>
                  <CircleMarker
                    center={gemeinde.geometry.coordinates}
                    radius={gPopRadius(gemeinde.properties.population)}
                    stroke={true}
                    color={"white"}
                    weight={1.5}
                    fillOpacity={0.6}
                    fillColor={rColor(data.region[regionId], metric)}
                  />
                  <CircleMarker
                    center={gemeinde.geometry.coordinates}
                    radius={gemeindeMarkerR}
                    stroke={false}
                    fillOpacity={1}
                    fillColor="black"
                  />
                </LayerGroup>
              );
            })}
        </Pane>

        <Pane name="kreis" style={{ zIndex: 500, mixBlendMode: "multiply" }}>
          {radius === "kreis" &&
            Object.keys(data.region).map((regionId: any) => {
              const kreisGeo = regionKreis[regionId];
              if (kreisGeo) {
                return (
                  <LayerGroup key={regionId}>
                    <Polygon
                      positions={kreisGeo.geometry.coordinates}
                      pathOptions={{
                        color: "black",
                        weight: 1.5,
                        dashArray: "2 4",
                        fillColor: rColor(data.region[regionId], metric),
                        fillOpacity: 0.8,
                      }}
                    />
                  </LayerGroup>
                );
              }
            })}
        </Pane>

        <Pane name="plants" style={{ zIndex: 500, mixBlendMode: "normal" }}>
          {Object.keys(dataHeatmap).map((plantIdRaw: string) => {
            const plantId = regionToMunId("" + plantIdRaw);

            // transform kreis to municipality
            // const region =
            //   radius === IRadius.KREIS
            //     ? regionKreis[plantId]
            //     : regionMunicipalities[plantId];
            const region = regionMunicipalities[plantId];

            const regionCentroid = centroid(region);

            const rid = region.properties.region;

            if (Object.keys(dataHeatmap).includes(plantIdRaw)) {
              const thisDateIndex = dates.indexOf(date);
              const prevDateIndex = thisDateIndex - 1;
              const prevDate = dates[prevDateIndex];

              const thisDateWWValue = dataHeatmap[plantIdRaw]["ww_vals"][date];
              const prevDateWWValue =
                dataHeatmap[plantIdRaw]["ww_vals"][prevDate];
              const allWWValues = Object.values(
                dataHeatmap[plantIdRaw]["ww_vals"]
              );

              return (
                <LayerGroup key={plantIdRaw}>
                  {renderPlant(
                    plantId,
                    regionCentroid,
                    thisDateWWValue,
                    prevDateWWValue,
                    allWWValues,
                    region.properties.name,
                    wwMethod
                  )}
                </LayerGroup>
              );
            } else {
              return null;
            }
          })}
        </Pane>
        <Pane
          name="boundaries"
          style={{ zIndex: 200, mixBlendMode: "multiply" }}
        ></Pane>
        <TileLayer
          url="https://stamen-tiles-{s}.a.ssl.fastly.net/toner-lite/{z}/{x}/{y}{r}.png"
          attribution='www.coronavirus.sachsen.de/. Map tiles by <a href="http://stamen.com">Stamen Design</a>, <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a> &mdash; Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          subdomains="abcd"
          minZoom={0}
          maxZoom={20}
        />
        <MapLegend width={600}>
          <MapLegendColumns>
            <MapLegendColumn>
              <MapLegendTitle>
                {radius === IRadius.KREIS
                  ? t("wastewater", "legend-incidence-region", lang)
                  : t("wastewater", "legend-incidence-municipalities", lang)}
              </MapLegendTitle>
              <MapLegendContent>
                {incIntervals.map((intVal: number, ci: number) => {
                  const color = rColor(intVal, metric);
                  return (
                    <MapLegendLine key={intVal}>
                      <MapLegendLineText>{intVal}</MapLegendLineText>
                      <MapLegendLineBox color={color} />
                    </MapLegendLine>
                  );
                })}
              </MapLegendContent>
            </MapLegendColumn>
            <MapLegendColumn>
              <MapLegendTitle>
                {t("wastewater", "legend-indicator", lang)}
              </MapLegendTitle>
              <MapLegendContent>
                {[
                  [1, t("wastewater", "legend-indicator-val-1", lang)],
                  [2, t("wastewater", "legend-indicator-val-2", lang)],
                  [3, t("wastewater", "legend-indicator-val-3", lang)],
                  [4, t("wastewater", "legend-indicator-val-4", lang)],
                  [5, t("wastewater", "legend-indicator-val-5", lang)],
                  [0, t("wastewater", "legend-indicator-val-no", lang)],
                ].map((item: any) => {
                  const color = wwColor(item[0], [1, 2, 3, 4, 5], wwMethod);
                  return (
                    <MapLegendLine key={item[0]}>
                      <MapLegendLineText>{item[1]}</MapLegendLineText>
                      <MapLegendLineCircle color={color} />
                    </MapLegendLine>
                  );
                })}
              </MapLegendContent>
            </MapLegendColumn>
            <MapLegendColumn>
              <MapLegendTitle>
                {t("wastewater", "legend-change", lang)}
              </MapLegendTitle>
              <MapLegendContent>
                {[
                  [
                    false,
                    false,
                    [],
                    t("wastewater", "legend-change-invalid", lang),
                  ],
                  [
                    1,
                    false,
                    [],
                    t("wastewater", "legend-change-previousinvalid", lang),
                  ],
                  [
                    1,
                    1,
                    [1, 2, 3, 4],
                    t("wastewater", "legend-change-no", lang),
                  ],
                  [
                    1,
                    5,
                    [1, 2, 3, 4],
                    t("wastewater", "legend-change-decrease", lang),
                  ],
                  [
                    5,
                    1,
                    [1, 2, 3, 4],
                    t("wastewater", "legend-change-increase", lang),
                  ],
                ].map((item: any, ii) => {
                  return (
                    <MapLegendLine key={ii}>
                      <MapLegendLineText>{item[3]}</MapLegendLineText>
                      <MapLegendLineCircle>
                        {wwChangeSymbol(item[0], item[1], item[2], 14)}
                      </MapLegendLineCircle>
                    </MapLegendLine>
                  );
                })}
              </MapLegendContent>
            </MapLegendColumn>
          </MapLegendColumns>
        </MapLegend>
      </MapContainer>
    </StyledMapWrapper>
  );
};

const renderPlant = (
  key: string,
  xy: L.LatLngExpression,
  value: undefined | number,
  prevValue: undefined | number,
  vals: (number | false)[],
  munName: string,
  wwMethod: IWWMethod
) => {
  const size = [32, 32];

  return (
    <Marker
      key={key}
      position={xy}
      icon={divIcon({
        html: renderToString(
          <MapSymbol color={wwColor(value, vals, wwMethod)}>
            {wwChangeSymbol(value, prevValue, vals, 25)}
          </MapSymbol>
        ),
        className: "map-sort-icon",
        iconSize: [size[0], size[1]],
        iconAnchor: [size[0] / 2, size[1] / 2],
      })}
      eventHandlers={
        {
          // click: (e) => {
          //   selectRegion(feature.properties.region);
          // },
        }
      }
    >
      <Tooltip sticky pane="tooltipPane">
        {munName}
      </Tooltip>
    </Marker>
  );
};

const incIntervals: number[] = [10, 50, 250, 1000, 2500];

const wwRadius = (
  date: string,
  vals: { [key: string]: number },
  heatMaxR: number
) => {
  const thisVal = vals[date];
  if (thisVal) {
    const valsList = Object.values(vals);
    const max = Math.max(...valsList);
    const min = Math.min(...valsList);
    return Math.sqrt(((thisVal - min) / (max - min)) * heatMaxR) / 2 + 0.5;
  } else {
    return 0;
  }
};

const wwColor = (
  val: number | undefined,
  allVals: (number | false)[],
  method: IWWMethod
): string => {
  if (val) {
    const colorScale = chroma.scale("RdYlGn").padding([0.1, 0.1]);
    const allValsClean = allVals.filter((v) => v !== false) as number[];

    const max: number = Math.max(...(allValsClean as number[]));
    const min: number = Math.min(...(allValsClean as number[]));
    const sum: number = allValsClean.reduce((a, b) => a + b, 0);
    const mean = sum / allValsClean.length;

    if (method === IWWMethod.Q) {
      const wwColors = colorScale.domain([0, 1]).classes(6);
      const allValsSorted = [...allValsClean].sort((a, b) =>
        a === b ? 0 : a < b ? 1 : -1
      );

      const allValsNo = allValsSorted.length;
      const valIndex = allValsSorted.indexOf(val);
      return wwColors(valIndex / allValsNo).hex();
    }
    if (method === IWWMethod.MM) {
      //const wwIntervals: number[] = [mean + sd / 2, mean - sd / 2];
      const wwColors = colorScale.domain([0, 1]).classes(5);
      return wwColors(1 - (val - min) / max).hex();
    }
    if (method === IWWMethod.SD) {
      const sd = Math.sqrt(
        (allValsClean as number[])
          .map((x: number) => Math.pow(x - mean, 2))
          .reduce((a, b) => a + b) / allValsClean.length
      );
      const wwColors = colorScale.domain([5, 1]);
      if (val < mean - sd) {
        return wwColors(1).hex();
      } else if (val < mean - sd / 2) {
        return wwColors(2).hex();
      } else if (val < mean + sd / 2) {
        return wwColors(3).hex();
      } else if (val < mean + sd) {
        return wwColors(4).hex();
      } else if (val < mean + 1.5 * sd) {
        return wwColors(5).hex();
      } else {
        return wwColors(6).hex();
      }
    }
  }
  return chroma("lightgrey").hex();
};

const wwChangeSymbol = (
  val: number | false | undefined,
  prevVal: number | false | undefined,
  allVals: (number | false)[],
  size: number
) => {
  if (val === false || val === undefined) {
    // this value is unknown

    return <FaCircle size={size} />;
  } else if (prevVal === false || prevVal === undefined) {
    // the previous value is unknown
    return <FaQuestionCircle size={size} />;
  } else {
    // get average change
    const changes: number[] = [];
    allVals.forEach((valAllVals, vi) => {
      const prevValAllVals = allVals[vi - 1];

      if (
        valAllVals !== false &&
        valAllVals !== undefined &&
        prevValAllVals !== false &&
        prevValAllVals !== undefined
      ) {
        changes.push(Math.abs(valAllVals - prevValAllVals));
      }
    });

    const sum: number = changes.reduce((a, b) => a + b, 0);

    const mean = sum / changes.length;

    const sd = Math.sqrt(
      changes
        .map((x: number) => Math.pow(x - mean, 2))
        .reduce((a, b) => a + b) / changes.length
    );

    const thisChange = val - prevVal;

    if (Math.abs(thisChange) > mean + sd / 4) {
      if (thisChange > 0) {
        return <FaArrowCircleUp size={size} />;
      } else {
        return <FaArrowCircleDown size={size} />;
      }
    }
    return <FaDotCircle size={size} />;
  }
};

const rColor = (val: number, metric: IMetric) => {
  // incidence

  if (metric === IMetric.INC) {
    const incidenceColors = chroma
      .scale("PuBu")
      .padding([0.1, 0])
      .domain(incIntervals);
    return incidenceColors(val).hex();
  }
};

const gPopRadius = (val: number) => {
  return Math.sqrt(Math.sqrt(val)) * 2;
};

const MapSymbol = styled.span.attrs<{ increase: boolean }>(({ increase }) => ({
  className: "content",
})) <{ color: string }>`
  svg {
    border: 2px solid black;
    border-radius: 50%;
    background-color: black;
    color: ${({ color }) => color};
  }
`;

const StyledMapWrapper = styled.div`
  margin-top: 1em;
`;

const WrapperDiv = styled.div.attrs(() => ({
  className: "content",
}))``;

const HeaderSection = styled.div`
  margin: 10px;
  padding-top: 20px;
  padding-bottom: 10px;
`;

const HeaderSectionTitle = styled.div`
  padding-top: 0;

  button {
    margin-left: 0.5em;
    vertical-align: middle;
  }
`;

const Button = styled.button.attrs<{ selected: boolean }>(({ selected }) => ({
  className: "button is-small",
})) <{ selected: boolean }>`
  background-color: ${({ selected }) => (selected ? COLOR_PRIMARY : "white")};
  color: ${({ selected }) => (selected ? "white" : COLOR_PRIMARY)};
  font-weight: ${({ selected }) => (selected ? "bold" : "normal")};
  text-decoration: ${({ selected }) => (selected ? "underline" : "none")};
`;

const ButtonGroup = styled.div.attrs(({ }: {}) => ({
  className: "buttons has-addons",
}))`
  display: inline-flex;
`;

const HeaderMenu = styled.div.attrs(({ }: {}) => ({
  className: "",
}))``;
const HeaderMenuItem = styled.div.attrs(({ }: {}) => ({
  className: "",
}))`
  display: grid;
  grid-template-columns: 15em auto;
  padding-bottom: 0.5em;
  align-items: start;
`;
const HeaderMenuItemLabel = styled.div.attrs(({ }: {}) => ({
  className: "",
}))`
  font-weight: bold;
  text-align: right;
  margin-right: 1em;
`;
const HeaderMenuItemValue = styled.div.attrs(({ }: {}) => ({
  className: "",
}))``;

export default WastewaterPage;
