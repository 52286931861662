import React from "react";

import styled from "styled-components";

import { COLOR_SECONDARY1 } from "variables";

export const Subtitle = ({ children }: { children: React.ReactNode }) => {
    return <SubTitleDiv>{children}</SubTitleDiv>;
};

const SubTitleDiv = styled.div.attrs(() => ({
    className: "subtitle is-4",
}))`
    font-size: 1.5em;
    font-weight: bold;
    color: ${COLOR_SECONDARY1};
    //text-transform: capitalize;
`;
