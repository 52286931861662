import React, {
  setGlobal,
  useGlobal,
  useState,
  useLayoutEffect,
  useEffect,
  useMemo,
  useRef,
} from "reactn";

import { FaInfo, FaCalendarAlt } from "react-icons/fa";

import { Box } from "components/box";
import { Title1 } from "components/title1";
import { Title2 } from "components/title2";
import { Subtitle } from "components/subtitle";

import { Stage, Layer, Line } from "react-konva";

//import { withRouter, RouteComponentProps } from "react-router-dom";

import styled from "styled-components";
import {
  INTERVALS_CASES,
  COLOR_SECONDARY1,
  COLOR_SECONDARY2,
  COLOR_PRIMARY,
} from "variables";

import Timeline from "components/timeline";
import RegionMap from "components/region-map";

import {
  translate,
  languages,
  formatDate,
  t,
  tDate,
} from "localization/translate";

const { default: flip } = require("@turf/flip");

const geoBoundaries = flip(require("data/spatial/geo_dolnoslaski_bounds.json"))
  .features[0];
const dolnoslaskiDict = require("data/dictionaries/dolnoslaski_powiat.json");
const dataGeo = flip(
  require("data/spatial/geo_dolnoslaski_powiat_simplified.json")
);

import { useMutation, useQuery, useQueryClient } from "react-query";
import api from "api";
import { IDataRegion } from "models";
import { ThreeDots } from "react-loading-icons";

interface IDolnoslaskiPageProps { }

const sizes = {
  hTimelineLine: 300,
  hMapTableLine: 700,
  wTableColumn: 400,
  hTableTimeline: 35,
  wCoat: 30,
  wTableTimeline: 170,
};

// component
const DolnoslaskiPage = ({ }: IDolnoslaskiPageProps) => {
  const [lang, setLang] = useGlobal("lang");

  // load data
  const queryClient = useQueryClient();
  const {
    status: status,
    data: dataCases,
    error: error,
    isFetching: isFetching,
  } = useQuery(
    [],
    async (): Promise<IDataRegion> => {
      const res = await api.dolnoslaskiCasesForecast();
      return res;
    },
    { enabled: true, initialData: {} as IDataRegion }
  );

  const isDataLoaded: boolean = useMemo(() => {
    return !!dataCases && "02" in dataCases;
  }, [dataCases]);

  const allDates = useMemo(() => {
    return isDataLoaded && dataCases ? Object.keys(dataCases["02"]) : [];
  }, [dataCases]);

  const nowDate = useMemo(
    () => (allDates.length > 5 ? allDates[allDates.length - 4] : ""),
    [allDates]
  );
  useEffect(() => {
    if (allDates.length > 5 && selectedDate === "")
      setSelectedDate(allDates[allDates.length - 3]);
  }, [allDates]);

  const wrapperRef = useRef<HTMLDivElement>(null);
  const [wrapperWidth, setWrapperWidth] = useState(0);
  const [selectedRegion, setSelectedRegion] = useState<
    { id: string; fullname: string; coat: string } | false
  >(false);
  const [selectedDate, setSelectedDate] = useState<string>("");

  const [helpModalOpen, setHelpModalOpen] = useState<boolean>(false);

  const selectRegion = (newId: string | false) => {
    setSelectedRegion(
      dolnoslaskiDict.find(
        (r: { id: string; fullname: string; id_ruian: string }) => r.id == newId
      ) || false
    );
  };

  // resizing
  useEffect(() => {
    //console.log("handle resize");
    const handleResize = () => {
      if (wrapperRef.current) {
        //console.log("setting width", wrapperRef.current.offsetWidth);
        setWrapperWidth(wrapperRef.current.offsetWidth);
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
  }, [wrapperRef.current]);

  const allRegionsTranslation = t(
    "dolnoslaskie",
    "allregions",
    lang,
    {}
  ) as string;
  const timelineData = useMemo(() => {
    if (isDataLoaded && dataCases) {
      const datasets = [
        {
          id: "1",
          label: allRegionsTranslation,
          color: COLOR_SECONDARY1,
          data: dataCases["02"],
          displayPolygon: true,
        },
      ];
      if (selectedRegion) {
        datasets.push({
          id: "2",
          label: selectedRegion.fullname,
          color: COLOR_PRIMARY,
          data: dataCases[selectedRegion.id],
          displayPolygon: true,
        });
      }
      return datasets;
    } else {
      return [];
    }
  }, [dataCases, selectedRegion, lang]);

  const generalTableTitle = t("dolnoslaskie", "table-title", lang);

  const mapData = useMemo(() => {
    const regionsData: { [id: string]: { v: number; c: number } } = {};

    if (isDataLoaded && dataCases) {
      Object.keys(dataCases).forEach((regionId: string) => {
        const regionData = dataCases[regionId][selectedDate];
        if (regionData) {
          regionsData[regionId] = { v: regionData.m, c: regionData.ch };
        }
      });
    }

    return regionsData;
  }, [dataCases, selectedDate]);

  const countiesSorted = dolnoslaskiDict.sort((a: any, b: any) => {
    return a.fullname > b.fullname ? 1 : -1;
  });

  const renderHelpModal = () => {
    return (
      <ModalDiv className="modal is-active">
        <ModalBackgroundDiv
          onClick={() => {
            setHelpModalOpen(false);
          }}
        />
        <ModalCardDiv>
          <ModalCardHeader background={COLOR_SECONDARY1}>
            <div className="modal-card-title">
              <Title2>{t("dolnoslaskie", "instructions-title", lang)}</Title2>
            </div>
            <button
              className="delete"
              aria-label="close"
              onClick={() => {
                setHelpModalOpen(false);
              }}
            ></button>
          </ModalCardHeader>
          <ModalCardBody>
            {t("dolnoslaskie", "instructions-content", lang)}
          </ModalCardBody>
        </ModalCardDiv>
      </ModalDiv>
    );
  };

  const renderDataLoading = () => {
    return (isFetching ? (
      <ThreeDots fill={COLOR_PRIMARY}
        style={{
          height: "2em",
          verticalAlign: "middle",
          margin: "0 auto",
        }}
        stroke={COLOR_PRIMARY} />

    ) : (<div />))
  };


  const renderDataProblem = () => {
    return (error ?
      (
        <article className="message is-danger">
          <div className="message-header">
            <p>{t("general", "dataproblem-title", lang)}</p>
          </div>
          <div className="message-body">
            {t("general", "dataproblem-text", lang)}
          </div>
        </article>
      )
      : (<div />))
  };

  return (
    <WrapperDiv ref={wrapperRef}>
      {helpModalOpen && renderHelpModal()}
      <HeaderSection>
        <InstructionsButton onClick={() => setHelpModalOpen(true)}>
          <InstructionsButtonIcon>
            <FaInfo />
          </InstructionsButtonIcon>
          {t("dolnoslaskie", "instructions-title", lang)}
        </InstructionsButton>
        {isDataLoaded && (
          <DateSelect>
            <div className="control has-icons-left">
              <div className="select">
                <select
                  value={selectedDate}
                  onChange={(e: React.FormEvent<HTMLSelectElement>) => {
                    const newValue = e.currentTarget.value;
                    setSelectedDate(newValue);
                  }}
                >
                  {allDates.map((date, di) => {
                    const toDate = tDate(date, lang);
                    const fromDate = tDate(date, lang, {
                      delta: { days: -7 },
                    });

                    return (
                      <option
                        value={date}
                        key={di}
                      >{`${fromDate} - ${toDate}`}</option>
                    );
                  })}
                </select>
              </div>
              <div className="icon is-small is-left">
                <FaCalendarAlt />
              </div>
            </div>
          </DateSelect>
        )}
        <Title1>{t("dolnoslaskie", "title", lang)}</Title1>
        <Subtitle>{t("dolnoslaskie", "subtitle", lang)}</Subtitle>
      </HeaderSection>

      {renderDataProblem()}
      {renderDataLoading()}

      {isDataLoaded && (
        <TimelineDivLine>
          <TimelineDiv>
            <Box>
              <Timeline
                width={wrapperWidth}
                height={300}
                lines={timelineData}
                timeSlices={allDates}
                todaySlice={nowDate}
                selectedSlice={selectedDate}
                topValue={2500}
                tickValues={[500, 1000, 1500, 2000, 2500]}
                handleChangeSelected={(newDate: string) => {
                  setSelectedDate(newDate);
                }}
              />
            </Box>
          </TimelineDiv>
        </TimelineDivLine>
      )}

      {isDataLoaded && (
        <MapDiv>
          <Box>
            <RegionMap
              center={[51, 16.5]}
              width="100%"
              height={`${sizes.hMapTableLine}px`}
              regionsDict={dolnoslaskiDict}
              regionsGeo={
                dataGeo.features as {
                  properties: { region: string };
                  geometry: { coordinates: [][] };
                }[]
              }
              boundaries={geoBoundaries}
              data={mapData}
              intervals={INTERVALS_CASES}
              legendTitle={
                t("dolnoslaskie", "cases-legend-title", lang) as string
              }
              selectedRegionId={selectedRegion ? selectedRegion.id : false}
              selectedColor={COLOR_PRIMARY}
              selectRegion={(newRegionId) => {
                selectRegion(newRegionId);
              }}
            />
          </Box>
        </MapDiv>
      )}
      {isDataLoaded && (
        <FooterDiv>
          <Box>
            <div>{t("dolnoslaskie", "footer", lang)}</div>
          </Box>
        </FooterDiv>
      )}
    </WrapperDiv>
  );
};

const HeaderSection = styled.div`
  margin: 10px;
  padding-top: 20px;
  padding-bottom: 10px;
`;

const DateSelect = styled.div.attrs(() => ({
  className: "",
}))`
  margin-top: 2.5em;
  margin-bottom: -2em;
  .control .icon {
    color: black;
  }
`;

const WrapperDiv = styled.div.attrs(() => ({
  className: "content",
}))``;
const TimelineDivLine = styled.div.attrs(() => ({
  className: "",
}))`
  height: ${sizes.hTimelineLine + 50}px;
  width: 100%;
`;

const FooterDiv = styled.div``;

const TimelineDiv = styled.div.attrs(() => ({
  className: "",
}))``;

const MapTableDivLine = styled.div.attrs(() => ({
  className: "",
}))`
  display: grid;
  grid-template-columns: ${sizes.wTableColumn}px auto;
  grid-gap: 0px;
`;

const MapDiv = styled.div.attrs(() => ({
  className: "",
}))`
   ;
`;
const TableDiv = styled.div.attrs(() => ({
  className: "",
}))``;

const TableDivTable = styled.div`
  width: 100%;
`;

const TableContentDiv = styled.div`
  display: table;
  border-collapse: separate;
  height: ${sizes.hMapTableLine}px;
`;

interface ITableTr {
  selected: boolean;
}

const TableTr = styled.div.attrs(({ selected }: ITableTr) => ({}))`
  display: table-row;
  background-color: ${({ selected }: ITableTr) =>
    selected ? COLOR_PRIMARY : "white"};
  color: ${({ selected }: ITableTr) => (selected ? "white" : "black")};
`;

const TableTd = styled.div`
  display: table-cell;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 1rem;
  padding-right: 0rem;
  vertical-align: middle;
  text-align: left;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
  whitespace: nowrap;
  overflow: hidden;
  textoverflow: ellipsis;
`;

const InstructionsButton = styled.button.attrs(({ }: {}) => ({
  className: `button`,
}))`
  margin-bottom: -2rem;
  display: block;
  font-size: 13px;
  background: ${COLOR_SECONDARY2};
  color: white;
  font-weight: bold;
  border: none;
`;

const InstructionsButtonIcon = styled.span.attrs(({ }: {}) => ({}))`
  display: inline;
  margin-right: 5px;
  font-size: 13px;
  vertical-align: middle;
`;

/**
 * Modal
 */
const ModalDiv = styled.div.attrs(({ active }: { active: boolean }) => ({
  className: `modal ${active && "is-active"}`,
}))``;

const ModalBackgroundDiv = styled.div.attrs(({ }) => ({
  className: "modal-background",
}))`
  z-index: 30;
`;

const ModalCardDiv = styled.div.attrs(({ }) => ({
  className: "modal-card",
}))`
  z-index: 40;
`;

interface IModalCardHeader {
  background: string;
}
const ModalCardHeader = styled.div.attrs(
  ({ background }: IModalCardHeader) => ({
    className: "modal-card-head",
  })
)`
  z-index: 40;
  background-color: ${({ background }: IModalCardHeader) => background};
  .modal-card-title {
    .title {
      color: white;
    }
  }
`;
const ModalCardBody = styled.section.attrs(({ }) => ({
  className: "modal-card-body",
}))`
  z-index: 40;
`;

//export default withRouter(dolnoslaskiePage);
export default DolnoslaskiPage;
