import React from "react";

import styled from "styled-components";

import { COLOR_SECONDARY1 } from "variables";

export const Title3 = ({ children }: { children: React.ReactNode }) => {
    return <Title3Div>{children}</Title3Div>;
};

const Title3Div = styled.div.attrs(() => ({
    className: "",
}))`
    font-size: 1em;
    font-weight: bold;
    color: black;
    margin-bottom: 0.5em;
`;
