import React, {
  setGlobal,
  useGlobal,
  useState,
  useLayoutEffect,
  useEffect,
  useRef,
  useMemo,
} from "reactn";

import { Box } from "components/box";
import { Title1 } from "components/title1";
import { Title2 } from "components/title2";
import { Title3 } from "components/title3";

import { translate, translateText } from "localization/translate";

//import { withRouter, RouteComponentProps } from "react-router-dom";

import {
  INTERVALS_CASES,
  INTERVALS_TESTS,
  COLOR_SECONDARY1,
  COLOR_PRIMARY,
} from "variables";
import styled from "styled-components";

const { default: logoCasus } = require("assets/logo-casus.png");

//interface ContactPageProps extends RouteComponentProps {}
interface ContactPageProps {}

const ContactPage = ({}: ContactPageProps) => {
  const wrapperRef = useRef<any>(null);
  const [wrapperWidth, setWrapperWidth] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      if (wrapperRef.current) {
        //console.log("setting width", wrapperRef.current.offsetWidth);
        setWrapperWidth(wrapperRef.current.offsetWidth);
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
  }, [wrapperRef.current]);

  return (
    <WrapperDiv ref={wrapperRef}>
      <HeaderSection>
        <Title1>{translate("contact", "title")}</Title1>
      </HeaderSection>
      <Box>
        <BoxContent>
          <SectionDiv>
            <Title2>{translate("contact", "contact-person")}</Title2>
            <p>
              Dr. Weronika Schlechte-Wełnicz
              <br />
              <a href={`mailto: w.schlechte-welnicz@hzdr.de`}>
                w.schlechte-welnicz@hzdr.de
              </a>
              <br />
              +49 (0)3581 - 375 23 72
            </p>
          </SectionDiv>

          <SectionDiv>
            <Title2>{translate("contact", "contact-institution")}</Title2>
            <p>
              <a href={`mailto: contact@casus.science`}>
                contact@casus.science
              </a>
            </p>
            <p>
              CASUS – Center for Advanced Systems Understanding
              <br />
              Helmholtz-Zentrum Dresden-Rossendorf e.V. (HZDR)
              <br />
              Untermarkt 20 D-02826 Görlitz <br />
              <a href="https://www.casus.science">https://www.casus.science</a>
            </p>
            <p>
              <img src={String(logoCasus)} style={{ height: "80px" }} />
            </p>
          </SectionDiv>
          <SectionDiv>
            {/* <Title2>{translate("contact", "financing-title")}</Title2> */}
            <AboutFinances>
              <div>
                <img
                  src={require("assets/saxony-state.gif").default}
                  width="60px"
                />
              </div>
              <div>
                <p>{translate("contact", "financing-content")}</p>
              </div>
            </AboutFinances>
          </SectionDiv>
          <SectionDiv>
            <Title2>{translate("contact", "privacy-title")}</Title2>

            <Title3>{translate("contact", "privacy-intro-heading")}</Title3>
            <div className="block">
              {translate("contact", "privacy-intro-content")}
            </div>

            <Title3>{translate("contact", "privacy-protocols-heading")}</Title3>
            <div className="block">
              {translate("contact", "privacy-protocols-content")}
            </div>

            <Title3>{translate("contact", "privacy-contact-heading")}</Title3>
            <div className="block">
              {translate("contact", "privacy-contact-content")}
            </div>

            <Title3>{translate("contact", "privacy-cookies-heading")}</Title3>
            <div className="block">
              {translate("contact", "privacy-cookies-content")}
            </div>

            <Title3>{translate("contact", "privacy-links-heading")}</Title3>
            <div className="block">
              {translate("contact", "privacy-links-content")}
            </div>

            <Title3>{translate("contact", "privacy-rights-heading")}</Title3>
            <div className="block">
              {translate("contact", "privacy-rights-content")}
            </div>
          </SectionDiv>
        </BoxContent>
      </Box>
    </WrapperDiv>
  );
};

const HeaderSection = styled.div`
  margin: 10px;
  padding-top: 20px;
  padding-bottom: 10px;
`;
const AboutFinances = styled.div`
  display: flex;

  div {
    margin-left: 0.5em;
    display: table-cell;
    p {
      max-width: 400px;
      padding-bottom: 1em;
      font-size: 1em;
      font-style: italic;
      color: grey;
    }
  }
`;

const WrapperDiv = styled.div.attrs(() => ({
  className: "content",
}))``;
const BoxContent = styled.div`
  padding: 0.5em;
`;

const SectionDiv = styled.section.attrs(() => ({
  className: "section",
}))`
  padding: 0 0 2.5rem 0;
`;

//export default withRouter(ContactPage);
export default ContactPage;
