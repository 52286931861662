import { Box } from "components/box";
import { Modal } from "components/modal";
import { OptimizationLineChart } from "components/optimizationlinechart";
import { ParameterView } from "components/parameterview";
import { Title } from "components/title";
import { t } from "localization/translate";
import {
  BsFillPinMapFill,
  BsInfoCircle,
  BsInfoCircleFill,
} from "react-icons/bs";
import { FaInfo, FaMap, FaSyringe, FaUsers } from "react-icons/fa";
import { GiHealthNormal, GiOfficeChair } from "react-icons/gi";
import { ImEnter, ImOffice } from "react-icons/im";
import { MdWarning } from "react-icons/md";
import { RiTestTubeFill, RiVirusFill } from "react-icons/ri";
import { Circle, Layer, Line, Stage } from "react-konva";
import { ThreeDots } from "react-loading-icons";

//import { withRouter, RouteComponentProps } from "react-router-dom";
import {
  MapContainer,
  Pane,
  Polygon,
  TileLayer,
  Tooltip as LeafletTooltip,
} from "react-leaflet";
import React, { useEffect, useGlobal, useMemo, useRef, useState } from "reactn";
import { useQuery, useQueryClient } from "react-query";
import styled from "styled-components";
import {
  COLOR_PRIMARY,
  COLOR_SECONDARY1,
  COLOR_SECONDARY2,
  INTERVALS_CASES,
} from "variables";
import { IDataRegionOneTime } from "models";
import api from "api";

const { default: flip } = require("@turf/flip");

const saxonyDict = require("data/dictionaries/saxony_county.json");
const dataGeo = flip(require("data/spatial/geo_saxony_county_simplified.json"));
const geoBoundaries = flip(require("data/spatial/geo_saxony_bounds.json"))
  .features[0];

//interface OptimizationPageProps extends RouteComponentProps {}
interface OptimizationPageProps { }

const entryPassword = "n2PGztBp";

const contactRates = [
  { id: "high", value: 0.15, color: "#d7191c" },
  { id: "mid", value: 0.1, color: "#fdae61" },
  { id: "low", value: 0.05, color: "#a6d96a" },
];

const defaultRegion = "14000";
const presenceValues = [...Array(100).keys()];
const testValues = [...Array(15).keys()].filter((v) => v);
const testValuesMax = testValues[testValues.length - 1];

const round = (value: string | number) => {
  var number: number = +value;
  return Math.trunc(Math.round(number));
};

const validate = (
  value: number | string,
  minValue: number,
  maxValue: number
) => {
  let newValue = parseFloat(value as string);
  if (newValue > maxValue) {
    newValue = maxValue;
  }
  if (newValue < minValue) {
    newValue = minValue;
  }
  return newValue;
};

const RiskCalculatorPage = ({ }: OptimizationPageProps) => {
  const [lang, setLang] = useGlobal("lang");
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [wrapperWidth, setWrapperWidth] = useState(0);

  // password protection
  const [entered, setEntered] = useState<boolean>(true);
  const [displayErrorLogin, setDisplayErrorLogin] = useState<boolean>(false);
  const [enterPasswordTyped, setEnterPasswordTyped] = useState<string>("");

  const tryToEnter = () => {
    if (enterPasswordTyped === entryPassword) {
      setEntered(true);
      setWarningModalOpen(true);
    } else {
      setDisplayErrorLogin(true);
      setTimeout(() => {
        setDisplayErrorLogin(false);
      }, 2000);
    }
    setEnterPasswordTyped("");
  };

  useEffect(() => {
    const handleResize = () => {
      if (wrapperRef.current) {
        //console.log("setting width", wrapperRef.current.offsetWidth);
        setWrapperWidth(wrapperRef.current.offsetWidth);
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
  }, [wrapperRef.current, entered]);

  // parameters
  const [parNoE, setParNoE] = useState(150);
  const [parVacc, setParVacc] = useState(70);
  const [parInc, setParInc] = useState(100);
  const [parVaccLocal, setParVaccLocal] = useState(70);
  const [parTest, setParTest] = useState(7);
  const [parPresence, setParPresence] = useState(50);

  const [parMutation, setParMutation] = useState(0);
  const beta1 = useMemo(() => {
    return (0.04 * parMutation) / 100 + (1 - parMutation / 100) * 0.1;
  }, [parMutation]);

  const beta2 = useMemo(() => {
    return (1 - 0.7) * beta1;
  }, [beta1]);

  // modals
  const [warningModalOpen, setWarningModalOpen] = useState<boolean>(true);
  const [helpModalOpen, setHelpModalOpen] = useState<boolean>(false);
  const [inputModalOpen, setInputModalOpen] = useState<boolean>(false);
  const [parIncMapRegionSelected, setParIncMapRegionSelected] =
    useState<string>(defaultRegion);

  // fix parIncValue to parVaccLocal value
  const [parVaccFit, setParVaccFit] = useState<boolean>(true);

  useEffect(() => {
    if (parVaccFit) {
      setParVacc(parVaccLocal);
    }
  }, [parVaccFit, parVaccLocal]);

  // open disclaimer when the language is changed
  useEffect(() => {
    setWarningModalOpen(true);
  }, [lang]);
  // load data

  const {
    status: statusCases,
    data: thisWeekIncidence,
    error: errorCases,
    isFetching: isFetchingCases,
  } = useQuery(
    ['incidence'],
    async (): Promise<IDataRegionOneTime> => {
      console.log('going to ask for saxony incidence')
      const res = await api.saxonyCasesForecast();
      const regions = Object.keys(res);
      const dates = Object.keys(res[regions[0]]);
      const thisWeekDate = dates[dates.length - 5];

      const thisWeekIncidence: IDataRegionOneTime = {};
      regions.forEach(
        (region) => (thisWeekIncidence[region] = res[region][thisWeekDate].m)
      );
      return thisWeekIncidence;
      //return {} as IDataRegion;
    },
    { enabled: true, initialData: {} as IDataRegionOneTime }
  );

  const {
    status: statusVaccination,
    data: thisWeekVaccination,
    error: errorVaccination,
    isFetching: isFetchingVaccination,
  } = useQuery(
    ['vaccines'],
    async (): Promise<IDataRegionOneTime> => {
      const res = await api.saxonyVaccines();
      return res;
      //return {} as IDataRegion;
    },
    { enabled: true, initialData: {} as IDataRegionOneTime }
  );

  //const [incidenceMap, setIncidenceMap] = useState<false | any>(false);

  const strategies: {
    byTests: any;
    byPresence: any;
    riskThresholdsTest: any;
    riskThresholdsPresence: any;
    backgroundRisk: number;
  } = useMemo(() => {
    const vaccinated = round(parNoE * (parVacc / 100));
    const nonVaccinated = parNoE - vaccinated;

    let infectionProbabilityOutsideWeek = parInc / 100000;

    let backgroundRisk = infectionProbabilityOutsideWeek;
    let infectionProbability =
      (infectionProbabilityOutsideWeek / 7) * (parVaccLocal / parVacc);

    let byTests: any = {};
    testValues.forEach((test: number) => {
      byTests[test] = {};
      contactRates.forEach((contactRate) => {
        byTests[test][contactRate.id] = {};
      });
    });

    let byPresence: any = {};
    presenceValues.forEach((presence: number) => {
      byPresence[presence] = {};
      contactRates.forEach((contactRate) => {
        byPresence[presence][contactRate.id] = {};
      });
    });

    presenceValues.forEach((presence: number) => {
      testValues.forEach((test: number) => {
        contactRates.forEach((contactRate) => {
          const risk = calculateOptimalStrategy(
            parNoE,
            vaccinated,
            beta1,
            beta2,
            contactRate.value,
            infectionProbability,
            test,
            presence
          );

          byTests[test][contactRate.id][presence] = risk;
          byPresence[presence][contactRate.id][test] = risk;
        });
      });
    });

    // crossing natural risks
    const riskThresholdsPresence: any = {};
    contactRates.forEach((contactRate) => {
      riskThresholdsPresence[contactRate.id] = {};

      presenceValues.forEach((presence: number) => {
        Object.keys(byPresence[presence][contactRate.id]).forEach(
          (testValueKey: string, ti: number) => {
            //x2
            const testValue = parseFloat(testValueKey);
            // y2
            const val = byPresence[presence][contactRate.id][testValue];

            if (
              val > backgroundRisk &&
              !(presence in riskThresholdsPresence[contactRate.id])
            ) {
              //x1
              const previousTestValue = parseFloat(
                Object.keys(byPresence[presence][contactRate.id])[ti - 1]
              );

              //y1
              const previousValue =
                byPresence[presence][contactRate.id][previousTestValue];

              const ratioY =
                (val - backgroundRisk) / (backgroundRisk - previousValue);
              const thresholdCrossX =
                (testValue + ratioY * previousTestValue) / (ratioY + 1);

              riskThresholdsPresence[contactRate.id][presence] =
                thresholdCrossX;
            }
          }
        );
      });
    });

    const riskThresholdsTest: any = {};
    contactRates.forEach((contactRate) => {
      riskThresholdsTest[contactRate.id] = {};

      testValues.forEach((test: number) => {
        Object.keys(byTests[test][contactRate.id]).forEach(
          (presenceValueKey: string, ti: number) => {
            const presenceValue = parseFloat(presenceValueKey);
            const val = byTests[test][contactRate.id][presenceValue];
            if (
              val > backgroundRisk &&
              !(test in riskThresholdsTest[contactRate.id])
            ) {
              //x1
              const previousPresenceValue = parseFloat(
                Object.keys(byTests[test][contactRate.id])[ti - 1]
              );

              //y1
              const previousValue =
                byTests[test][contactRate.id][previousPresenceValue];

              const ratioY =
                (val - backgroundRisk) / (backgroundRisk - previousValue);
              const thresholdCrossX =
                (presenceValue + ratioY * previousPresenceValue) / (ratioY + 1);

              riskThresholdsTest[contactRate.id][test] = thresholdCrossX;
            }
          }
        );
      });
    });

    return {
      byTests,
      byPresence,
      backgroundRisk,
      riskThresholdsTest,
      riskThresholdsPresence,
    };
  }, [parNoE, parVacc, parInc, parPresence, parVaccLocal]);

  useEffect(() => {
    const handleResize = () => {
      if (wrapperRef.current) {
        //console.log("setting width", wrapperRef.current.offsetWidth);
        setWrapperWidth(wrapperRef.current.offsetWidth);
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
  }, [wrapperRef.current]);

  const handleClickIncidenceRegion = (regionId: any) => {
    const newRegionId =
      regionId && thisWeekIncidence
        ? regionId !== parIncMapRegionSelected && regionId in thisWeekIncidence
          ? regionId
          : "14000"
        : "";

    if (newRegionId) {
      const incidence = thisWeekIncidence ? thisWeekIncidence[newRegionId] : 0;
      const vaccines = thisWeekVaccination && thisWeekVaccination[newRegionId]
        ? thisWeekVaccination[newRegionId]
        : 0.6

      setParInc(Math.round(incidence));
      setParVaccLocal(Math.round(vaccines * 100));
    }
    setParIncMapRegionSelected(newRegionId);
  };

  const resetRegionSelected = () => {
    setParIncMapRegionSelected("");
  };

  const renderWarningModal = () => {
    return (
      <Modal
        buttons={[
          {
            handleClick: () => {
              setWarningModalOpen(false);
            },
            class: "is-primary",
            text: t("riskcalculator", "warning-agree", lang) as string,
          },
        ]}
        heading={t("riskcalculator", "warning-title-1", lang)}
        headerColor={COLOR_PRIMARY}
        renderBody={() => (
          <React.Fragment>
            <InstructionsSection>
              <InstructionsParagraph>
                {t("riskcalculator", "warning-text-1", lang)}
              </InstructionsParagraph>
            </InstructionsSection>
            <InstructionsSection>
              <InstructionsTitle>
                <Title size="m">
                  {t("riskcalculator", "warning-title-2", lang)}
                </Title>
              </InstructionsTitle>
              <InstructionsParagraph>
                <ul>
                  {(t("riskcalculator", "warning-text-2", lang) as string)
                    .split("•")
                    .filter((l, li) => li != 0)
                    .map((l, li) => {
                      return <li key={li}>{l}</li>;
                    })}
                </ul>
              </InstructionsParagraph>
            </InstructionsSection>
          </React.Fragment>
        )}
      />
    );
  };

  const renderHelpModal = () => {
    return (
      <Modal
        buttons={[]}
        heading={t("riskcalculator", "instructions-title", lang)}
        headerColor={COLOR_SECONDARY2}
        handleExit={() => {
          setHelpModalOpen(false);
        }}
        renderBody={() => (
          <React.Fragment>
            <InstructionsSection>
              <InstructionsTitle>
                <Title size="m">
                  {t("riskcalculator", "instructions-s1-title", lang)}
                </Title>
              </InstructionsTitle>
              <InstructionsParagraph>
                {t("riskcalculator", "instructions-s1-text", lang)}
              </InstructionsParagraph>
            </InstructionsSection>

            <InstructionsSection>
              <InstructionsTitle>
                <Title size="m">
                  {t("riskcalculator", "instructions-s2-title", lang)}
                </Title>
              </InstructionsTitle>
              <InstructionsParagraph>
                {t("riskcalculator", "instructions-s2-text", lang)}
              </InstructionsParagraph>
            </InstructionsSection>

            <InstructionsSection>
              <InstructionsTitle>
                <Title size="m">
                  {t("riskcalculator", "instructions-s3-title", lang)}
                </Title>
              </InstructionsTitle>
              <InstructionsParagraph>
                {t("riskcalculator", "instructions-s3-text", lang)}
              </InstructionsParagraph>
            </InstructionsSection>

            <InstructionsSection>
              <InstructionsTitle>
                <Title size="m">
                  {t("riskcalculator", "instructions-s4-title", lang)}
                </Title>
              </InstructionsTitle>
              <InstructionsParagraph>
                {t("riskcalculator", "instructions-s4-text", lang)}
              </InstructionsParagraph>
            </InstructionsSection>
            <InstructionsImagesList>
              {[1, 2, 3, 4, 5, 6, 7].map((imageI) => {
                const imagePre = lang === "de" ? "de" : "en";
                const imageCaptionText = lang === "de" ? "Abb. " : "Fig. ";

                const imgSource = require(`./../../assets/printscreens/${imagePre}-${imageI}.png`);

                return (
                  <InstructionsImageDiv key={imageI}>
                    <InstructionsImageCaption>{`${imageCaptionText}${imageI}`}</InstructionsImageCaption>
                    <InstructionsImageImage>
                      <img src={imgSource.default} height="300" width="auto" />
                    </InstructionsImageImage>
                  </InstructionsImageDiv>
                );
              })}
            </InstructionsImagesList>
          </React.Fragment>
        )}
      />
    );
  };

  const renderInputModal = () => {
    return (
      <Modal
        heading={t("riskcalculator", "input-section-title", lang)}
        handleExit={() => setInputModalOpen(false)}
        buttons={[]}
        renderBody={() => (
          <React.Fragment>
            <Title size="m">
              {t("riskcalculator", "input-section-subtitle", lang)}
            </Title>
            <ModalSectionInput>
              <Title size="m" iconBefore={<ImOffice />}>
                {t("riskcalculator", "input-section-organization-title", lang)}
              </Title>

              {/* employees */}
              <InputParameterDiv
                ParameterIcon={FaUsers}
                value={round(parNoE)}
                setValue={(v: any) => {
                  setParNoE(validate(v, 20, 999));
                }}
                title={
                  t("riskcalculator", "par-employees-title", lang) as string
                }
                description={
                  t(
                    "riskcalculator",
                    "par-employees-description",
                    lang
                  ) as string
                }
                extendedText={false}
                valueMin={20}
                valueStep={1}
                valueMax={999}
              />

              {/* vaccinated */}
              <InputParameterDiv
                ParameterIcon={FaSyringe}
                value={parVacc}
                disabled={parVaccFit}
                setValue={(v: any) => {
                  setParVacc(validate(v, 1, 100));
                }}
                title={
                  t("riskcalculator", "par-vaccinated-title", lang) as string
                }
                description={
                  t(
                    "riskcalculator",
                    "par-vaccinated-description",
                    lang
                  ) as string
                }
                extendedText={false}
                additional={
                  <div className="field">
                    <label className="checkbox">
                      <input
                        type="checkbox"
                        checked={parVaccFit}
                        onChange={() => {
                          setParVaccFit(!parVaccFit);
                        }}
                      />
                      {
                        t(
                          "riskcalculator",
                          "par-vaccinated-fix",
                          lang
                        ) as string
                      }
                    </label>
                  </div>
                }
                valueMin={1}
                valueStep={1}
                valueMax={100}
              />
              {/* Region characteristics */}
              <Title size="m" iconBefore={<BsFillPinMapFill />}>
                {t("riskcalculator", "input-section-region-title", lang)}
              </Title>

              {/* vaccinated rate in county */}
              <InputParameterDiv
                ParameterIcon={FaSyringe}
                value={parVaccLocal}
                setValue={(v: any) => {
                  setParVaccLocal(validate(v, 1, 100));
                  resetRegionSelected();
                }}
                title={
                  t(
                    "riskcalculator",
                    "par-vaccinatedlocal-title",
                    lang
                  ) as string
                }
                description={
                  t(
                    "riskcalculator",
                    "par-vaccinatedlocal-description",
                    lang
                  ) as string
                }
                extendedText={false}
                valueMin={1}
                valueStep={1}
                valueMax={100}
              />

              {/* incidence */}
              <InputParameterDiv
                ParameterIcon={GiHealthNormal}
                value={parInc}
                setValue={(v: any) => {
                  setParInc(validate(v, 1, 2000));
                  resetRegionSelected();
                }}
                title={
                  t("riskcalculator", "par-incidence-title", lang) as string
                }
                description={
                  t(
                    "riskcalculator",
                    "par-incidence-description",
                    lang
                  ) as string
                }
                extendedText={false}
                valueMin={1}
                valueStep={1}
                valueMax={2000}
              />
            </ModalSectionInput>

            {errorCases || errorVaccination ? (
              <article className="message is-danger">
                <div className="message-header">
                  <p>{t("general", "dataproblem-title", lang)}</p>
                </div>
                <div className="message-body">
                  {t("general", "dataproblem-text", lang)}
                </div>
              </article>
            ) : thisWeekVaccination && Object.keys(thisWeekVaccination).length && thisWeekIncidence && Object.keys(thisWeekIncidence).length ? (<ModalSectionMap>
              <Title size="m" iconBefore={<FaMap />}>
                {t("riskcalculator", "input-section-map-title", lang)}
              </Title>
              <Title size="sm" sub>
                {t("riskcalculator", "input-section-map-subtitle", lang)}
              </Title>
              <div className="region-text">
                {t("riskcalculator", "input-section-map-regionselected", lang)}
              </div>
              <div className="select">
                <select
                  value={parIncMapRegionSelected}
                  onChange={(e) => {
                    handleClickIncidenceRegion(e.target.value);
                  }}
                >
                  <option value={""} key="">
                    {t("riskcalculator", "input-section-map-noregion", lang)}
                  </option>
                  <option value={"14000"} key="14000">
                    {t("riskcalculator", "input-section-map-wholesaxony", lang)}
                  </option>
                  {saxonyDict.map((region: any) => {
                    return (
                      <option key={region.id} value={region.id}>
                        {region.fullname}
                      </option>
                    );
                  })}
                </select>
              </div>
              <MapContainer
                center={[50.95, 13.5]}
                zoom={8}
                maxZoom={10}
                minZoom={6}
                scrollWheelZoom={true}
                style={{ width: "100%", height: "400px" }}
                // whenCreated={(map) => {
                //   setIncidenceMap(map);
                // }}
                whenReady={() => {
                  console.log("ready");
                }}
              >
                <Pane
                  name="regions"
                  style={{ zIndex: 200, mixBlendMode: "multiply" }}
                >
                  {dataGeo.features.map((feature: any) => {
                    const region = saxonyDict.find(
                      (d: any) => d.id == feature.properties.region
                    );

                    const mode =
                      region.id === parIncMapRegionSelected
                        ? "selected"
                        : "default";

                    const incidence = thisWeekIncidence
                      ? thisWeekIncidence[region.id]
                      : 0;

                    const interval = INTERVALS_CASES.find(
                      (i: any) => i.from <= incidence && i.to >= incidence
                    );
                    return (
                      <Polygon
                        eventHandlers={{
                          click: (e) => {
                            handleClickIncidenceRegion(region.id);
                          },
                        }}
                        key={feature.properties.region}
                        positions={feature.geometry.coordinates}
                        pathOptions={{
                          color: mode === "selected" ? COLOR_PRIMARY : "black",
                          weight: mode === "selected" ? 5 : 2,
                          fillColor:
                            mode === "selected"
                              ? interval?.color || "grey"
                              : interval?.color || "grey",
                          fillOpacity: 1,
                          opacity: mode === "selected" ? 1 : 0.5,
                        }}
                      >
                        <LeafletTooltip sticky pane="tooltipPane">
                          {region.fullname}
                        </LeafletTooltip>
                      </Polygon>
                    );
                  })}
                </Pane>
                <Pane
                  name="boundaries"
                  style={{ zIndex: 200, mixBlendMode: "multiply" }}
                >
                  {geoBoundaries && geoBoundaries.geometry && (
                    <Polygon
                      positions={geoBoundaries?.geometry.coordinates}
                      pathOptions={{
                        color:
                          parIncMapRegionSelected === "14000"
                            ? COLOR_PRIMARY
                            : "black",
                        weight: parIncMapRegionSelected === "14000" ? 5 : 2,
                        fillColor: "none",
                        fillOpacity: 0,
                      }}
                    />
                  )}
                </Pane>

                <TileLayer
                  url="https://stamen-tiles-{s}.a.ssl.fastly.net/toner-lite/{z}/{x}/{y}{r}.png"
                  attribution='Map tiles by <a href="http://stamen.com">Stamen Design</a>, <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a> &mdash; Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  subdomains="abcd"
                  minZoom={0}
                  maxZoom={20}
                />
              </MapContainer>
            </ModalSectionMap>) : (<ThreeDots fill={COLOR_PRIMARY}
              style={{
                height: "2em",
                verticalAlign: "middle",
                margin: "0 auto",
              }}
              stroke={COLOR_PRIMARY} />)}

          </React.Fragment>
        )}
      />
    );
  };

  return (
    <WrapperDiv>
      {helpModalOpen && renderHelpModal()}
      {inputModalOpen && renderInputModal()}
      {warningModalOpen && renderWarningModal()}
      <HeaderSection>
        <button
          className="button is-info"
          style={{ display: entered ? "block" : "none" }}
          onClick={() => setHelpModalOpen(true)}
        >
          <span className="icon">
            <FaInfo />
          </span>
          <span>{t("riskcalculator", "instructions-button", lang)}</span>
        </button>

        <Title size="xl" iconBefore={<RiVirusFill />} marginBottom={1}>
          {t("riskcalculator", "title", lang)}
        </Title>
      </HeaderSection>
      <Box>
        <BoxContent
          ref={wrapperRef}
          style={{ display: !entered ? "block" : "none" }}
        >
          <Title size="sm">{t("riskcalculator", "login-text", lang)}</Title>

          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">
                {t("riskcalculator", "login-inputlabel", lang)}
              </label>
            </div>
            <div className="field-body">
              <div className="field">
                <p className="control">
                  <input
                    className="input"
                    type="text"
                    value={enterPasswordTyped}
                    onChange={(e) => {
                      setEnterPasswordTyped(e.target.value);
                    }}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        tryToEnter();
                      }
                    }}
                  />
                </p>
              </div>
            </div>
          </div>
          <button
            className="button is-link is-pulled-right"
            onClick={() => tryToEnter()}
          >
            <span className="icon">
              <ImEnter />
            </span>
            <span>{t("riskcalculator", "login-accept", lang)}</span>
          </button>
          <ErrorLoginMessage
            style={{ display: displayErrorLogin ? "block" : "none" }}
          >
            {t("riskcalculator", "login-error", lang)}
          </ErrorLoginMessage>
        </BoxContent>
        <BoxContent
          ref={wrapperRef}
          style={{ display: entered ? "block" : "none" }}
        >
          <FormDiv>
            <FormDivHeader>
              <Title size="m" marginBottom={1}>
                {t("riskcalculator", "input-section-title", lang)}
              </Title>

              <button
                className="button is-link"
                onClick={() => {
                  // setTimeout(() => {
                  //   incidenceMap.invalidateSize();
                  // }, 500);
                  setInputModalOpen(true);
                }}
              >
                <span className="icon">
                  <i className="fas fa-cog"></i>
                </span>
                <span>
                  {t("riskcalculator", "input-section-edit-button", lang)}
                </span>
              </button>
            </FormDivHeader>

            <InputParameterWrapperDiv>
              {[
                {
                  icon: FaUsers,
                  title: t(
                    "riskcalculator",
                    "par-employees-title",
                    lang
                  ) as string,
                  description: t(
                    "riskcalculator",
                    "par-employees-description",
                    lang
                  ) as string,
                  value: parNoE,
                  valueMin: 100,
                  valueMax: 999,
                },
                {
                  icon: FaSyringe,
                  title: t(
                    "riskcalculator",
                    "par-vaccinated-title",
                    lang
                  ) as string,
                  description: t(
                    "riskcalculator",
                    "par-vaccinated-description",
                    lang
                  ) as string,
                  value: parVacc,
                  valueMin: 1,
                  valueMax: 100,
                },
                {
                  icon: FaSyringe,
                  title: t(
                    "riskcalculator",
                    "par-vaccinatedlocal-title",
                    lang
                  ) as string,
                  description: t(
                    "riskcalculator",
                    "par-vaccinatedlocal-description",
                    lang
                  ) as string,
                  value: parVaccLocal,
                  valueMin: 1,
                  valueMax: 100,
                },
                {
                  icon: GiHealthNormal,
                  title: t(
                    "riskcalculator",
                    "par-incidence-title",
                    lang
                  ) as string,
                  description: t(
                    "riskcalculator",
                    "par-incidence-description",
                    lang
                  ) as string,
                  value: parInc,
                  valueMin: 1,
                  valueMax: 2000,
                },
              ].map((inputParameter: any) => {
                return (
                  <ParameterView
                    key={inputParameter.title}
                    Icon={inputParameter.icon}
                    value={inputParameter.value}
                    title={inputParameter.title}
                    description={inputParameter.description}
                    valueMin={inputParameter.valueMin}
                    valueMax={inputParameter.valueMax}
                  />
                );
              })}
            </InputParameterWrapperDiv>
          </FormDiv>

          {/* results panel */}
          <ResultDiv>
            <Title size="m" marginBottom={1.5}>
              {t("riskcalculator", "results-section-title", lang)}
            </Title>
            {/* <Title size="m" sub>
              {translateText("riskcalculator", "results-section-subtitle")}
            </Title> */}

            <ResultDivColumns>
              <ResultDivColumn>
                <ResultParameter>
                  <InputParameterDiv
                    ParameterIcon={GiOfficeChair}
                    value={parPresence}
                    setValue={(v: any) => {
                      setParPresence(validate(v, 0, 100));
                    }}
                    title={
                      t("riskcalculator", "par-presence-title", lang) as string
                    }
                    description={
                      t(
                        "riskcalculator",
                        "par-presence-description",
                        lang
                      ) as string
                    }
                    extendedText={false}
                    valueMin={5}
                    valueStep={5}
                    valueMax={95}
                  />
                </ResultParameter>
                <ResultChart>
                  <OptimizationLineChart
                    data={strategies.byPresence[parPresence]}
                    thresholds={{
                      high: strategies.riskThresholdsPresence["high"][
                        parPresence
                      ],
                      mid: strategies.riskThresholdsPresence["mid"][
                        parPresence
                      ],
                      low: strategies.riskThresholdsPresence["low"][
                        parPresence
                      ],
                    }}
                    categories={contactRates}
                    height={400}
                    width={wrapperWidth / 2 - 50}
                    backgroundRisk={strategies.backgroundRisk}
                    //skipXSlices={1}
                    xStart={1}
                    tickXValues={testValues.filter((tv, tvi) => tvi % 2)}
                    xAxisText={
                      t("riskcalculator", "par-testing-title", lang) as string
                    }
                  />
                </ResultChart>
                <ResultSummary>
                  <ResultSummaryTitle>
                    {t("riskcalculator", "summary-title-preposition", lang)}
                    <ResultSummaryTitleParX>
                      <span className="value">{parPresence}</span>

                      {t("riskcalculator", "par-presence-title", lang)}
                      <GiOfficeChair size={25} />
                    </ResultSummaryTitleParX>
                    {t("riskcalculator", "summary-title-left", lang)}
                    <ResultSummaryTitleParY>
                      {t("riskcalculator", "par-testing-title", lang)}
                      <RiTestTubeFill size={25} />
                    </ResultSummaryTitleParY>
                  </ResultSummaryTitle>
                  {contactRates.map((categoryRate: any) => {
                    const lw = 40;
                    const lh = 20;

                    const val =
                      strategies.riskThresholdsPresence[categoryRate.id][
                      parPresence
                      ];
                    return (
                      <ResultSummaryLegend key={categoryRate.id}>
                        <ResultSummaryLegendLabel>
                          <Stage height={lh} width={lw}>
                            <Layer>
                              <Line
                                points={[0, lh / 2, lw, lh / 2]}
                                stroke={categoryRate.color}
                                strokeWidth={2}
                                shadowEnabled={true}
                                shadowColor={"black"}
                                shadowBlur={3}
                                shadowOffset={{ x: 2, y: 2 }}
                                shadowOpacity={0.5}
                              />
                              <Circle
                                y={lh / 2}
                                x={lw / 2}
                                radius={5}
                                stroke={"black"}
                                fill={categoryRate.color}
                                strokeWidth={2}
                              />
                            </Layer>
                          </Stage>
                          {/* <BiNetworkChart size={25} /> */}
                          <span>
                            {t(
                              "riskcalculator",
                              "summary-contactrate-name-" + categoryRate.id,
                              lang
                            )}
                          </span>
                        </ResultSummaryLegendLabel>
                        <ResultSummaryLegendValue>
                          <span className="value">
                            {typeof val === "number"
                              ? Math.floor(val) || "<1"
                              : ">" + testValuesMax}
                          </span>
                          {t("riskcalculator", "par-testing-title", lang)}
                          {/* <RiTestTubeFill size={25} /> */}
                        </ResultSummaryLegendValue>
                      </ResultSummaryLegend>
                    );
                  })}
                </ResultSummary>
              </ResultDivColumn>

              <ResultDivColumn>
                <ResultParameter>
                  <InputParameterDiv
                    ParameterIcon={RiTestTubeFill}
                    value={parTest}
                    setValue={(v: any) => {
                      setParTest(validate(v, 1, testValuesMax));
                    }}
                    title={
                      t("riskcalculator", "par-testing-title", lang) as string
                    }
                    description={
                      t(
                        "riskcalculator",
                        "par-testing-description",
                        lang
                      ) as string
                    }
                    extendedText={false}
                    valueMin={1}
                    valueStep={1}
                    valueMax={testValuesMax}
                  // displayWarning={parTest > 5}
                  // warningText={
                  //   translate(
                  //     "riskcalculator",
                  //     "par-testing-warning"
                  //   ) as string
                  // }
                  />
                </ResultParameter>
                <ResultChart>
                  <OptimizationLineChart
                    data={strategies.byTests[parTest]}
                    thresholds={{
                      high: strategies.riskThresholdsTest["high"][parTest],
                      mid: strategies.riskThresholdsTest["mid"][parTest],
                      low: strategies.riskThresholdsTest["low"][parTest],
                    }}
                    categories={contactRates}
                    height={400}
                    width={wrapperWidth / 2 - 50}
                    backgroundRisk={strategies.backgroundRisk}
                    tickXValues={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
                    xAxisText={
                      t("riskcalculator", "par-presence-title", lang) as string
                    }
                  />
                </ResultChart>

                <ResultSummary>
                  <ResultSummaryTitle>
                    {t("riskcalculator", "summary-title-preposition", lang)}
                    <ResultSummaryTitleParX>
                      <span className="value">{parTest}</span>
                      {t("riskcalculator", "par-testing-title", lang)}
                      <RiTestTubeFill size={25} />
                    </ResultSummaryTitleParX>
                    {t("riskcalculator", "summary-title-right", lang)}
                    <ResultSummaryTitleParY>
                      {t("riskcalculator", "par-presence-title", lang)}
                      <GiOfficeChair size={25} />
                    </ResultSummaryTitleParY>
                  </ResultSummaryTitle>
                  {contactRates.map((categoryRate: any) => {
                    const lw = 40;
                    const lh = 20;
                    return (
                      <ResultSummaryLegend key={categoryRate.id}>
                        <ResultSummaryLegendLabel>
                          <Stage height={lh} width={lw}>
                            <Layer>
                              <Line
                                points={[0, lh / 2, lw, lh / 2]}
                                stroke={categoryRate.color}
                                strokeWidth={2}
                                shadowEnabled={true}
                                shadowColor={"black"}
                                shadowBlur={3}
                                shadowOffset={{ x: 2, y: 2 }}
                                shadowOpacity={0.5}
                              />
                              <Circle
                                y={lh / 2}
                                x={lw / 2}
                                radius={5}
                                stroke={"black"}
                                fill={categoryRate.color}
                                strokeWidth={2}
                              />
                            </Layer>
                          </Stage>
                          {/* <BiNetworkChart size={25} /> */}
                          <span>
                            {t(
                              "riskcalculator",
                              "summary-contactrate-name-" + categoryRate.id,
                              lang
                            )}
                          </span>
                        </ResultSummaryLegendLabel>
                        <ResultSummaryLegendValue>
                          <span className="value">
                            {Math.floor(
                              strategies.riskThresholdsTest[categoryRate.id][
                              parTest
                              ]
                            ) || "100"}
                          </span>
                          {t("riskcalculator", "par-presence-title", lang)}
                          {/* <GiOfficeChair size={25} /> */}
                        </ResultSummaryLegendValue>
                      </ResultSummaryLegend>
                    );
                  })}
                </ResultSummary>
              </ResultDivColumn>
            </ResultDivColumns>
          </ResultDiv>
        </BoxContent>
      </Box>
    </WrapperDiv>
  );
};

interface InputParameterProps {
  ParameterIcon: any;
  value: number;
  setValue: Function;
  title: string;
  description: string;
  valueMin: number;
  valueMax: number;
  valueStep: number;
  additional?: any;
  warningText?: string;
  displayWarning?: boolean;
  extendedText: string | false;
  disabled?: boolean;
}

const InputParameterDiv = ({
  ParameterIcon,
  value,
  setValue,
  title,
  description,
  valueMin,
  valueMax,
  valueStep,
  additional,
  warningText,
  displayWarning = false,
  extendedText = false,
  disabled = false,
}: InputParameterProps) => {
  const [openExtended, setOpenExtended] = useState<boolean>(false);

  const [warningTextDisplay, setWarningTextDisplay] = useState<boolean>(false);
  return (
    <ParameterDiv>
      <InputParameterDivMedia>
        <InputParameterDivMediaLeft>
          {/* @ts-ignore */}
          <ParameterIcon size={35} color={COLOR_SECONDARY1} />
        </InputParameterDivMediaLeft>
        <InputParameterDivMediaRight>
          <Title color={COLOR_SECONDARY1} size="sm" marginBottom={0}>
            {title}
          </Title>
          <p>{description}</p>
        </InputParameterDivMediaRight>
      </InputParameterDivMedia>
      <ParameterInputs>
        <input
          disabled={disabled}
          className="slider is-large"
          step={valueStep}
          min={valueMin}
          max={valueMax}
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
          }}
          type="range"
        />
        <input
          disabled={disabled}
          className="short input"
          type="number"
          min={valueMin}
          max={valueMax}
          step={valueStep}
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
          }}
        />
        {warningText && (
          <WarningTextWrapper
            style={{ display: displayWarning ? "block" : "none" }}
          >
            <WarningTextIcon
              onMouseEnter={() => {
                setWarningTextDisplay(true);
              }}
              onMouseLeave={() => {
                setWarningTextDisplay(false);
              }}
            >
              <MdWarning color={COLOR_PRIMARY} size={28} />
            </WarningTextIcon>

            <WarningTextTooltip
              style={{ display: warningTextDisplay ? "block" : "none" }}
            >
              {warningText}
            </WarningTextTooltip>
          </WarningTextWrapper>
        )}

        {extendedText && (
          <ExtendedIcon
            onClick={() => {
              setOpenExtended(!openExtended);
            }}
          >
            {openExtended ? <BsInfoCircle /> : <BsInfoCircleFill />}
          </ExtendedIcon>
        )}
      </ParameterInputs>
      <ParameterAdditional>{additional && additional}</ParameterAdditional>

      {/* <ParameterInfo
        style={{
          display:
            warningText || (openExtended && extendedText) ? "block" : "none",
        }}
      >
        {warningText && (
          <ParameterInfoWarningText
            style={{
              display: displayWarning ? "block" : "none",
            }}
          >
            <MdWarning />
            {warningText}
          </ParameterInfoWarningText>
        )}
        {openExtended && (
          <ParameterInfoExtendedText>
            <BsInfoCircleFill />
            {extendedText}
          </ParameterInfoExtendedText>
        )}
      </ParameterInfo> */}
    </ParameterDiv>
  );
};

const InputParameterDivMedia = styled.div.attrs(() => ({
  className: "media",
}))`
  margin-bottom: 0.5rem;
`;

const InputParameterDivMediaRight = styled.div.attrs(() => ({
  className: "media-content",
}))``;
const InputParameterDivMediaLeft = styled.div.attrs(() => ({
  className: "media-left",
}))`
  display: flex;

  input {
    margin-right: 0.5em;
  }

  input.slider {
    width: 10em;
  }
`;

/*
INSTRUCTIONS
*/
const InstructionsSection = styled.div.attrs(() => ({}))`
  padding-bottom: 2em;
`;
const InstructionsTitle = styled.div.attrs(() => ({}))``;
const InstructionsParagraph = styled.div.attrs(() => ({}))``;

const InstructionsImagesList = styled.div`
  display: block;
`;
const InstructionsImageDiv = styled.div`
  padding: 1em;
`;
const InstructionsImageImage = styled.div``;
const InstructionsImageCaption = styled.div`
  font-weight: bold;
  text-align: center;
`;

/*
RESULTS
*/

const ResultParameter = styled.div.attrs(() => ({
  className: "",
}))`
  min-height: 11em;
`;
const ResultChart = styled.div.attrs(() => ({
  className: "",
}))`
  padding-bottom: 2em;
`;

const ResultDivColumns = styled.div.attrs(() => ({
  className: "columns",
}))`
  margin-top: 1em;
  display: flex;
`;

const ResultDivColumn = styled.div.attrs(() => ({
  className: "column",
}))`
  // border-right: 2px solid ${COLOR_SECONDARY1};
  padding: 1rem 1.5rem;
  max-width: 50%;
  ${ResultParameter} {
    margin-left: 2em;
  }
`;

const ResultSummary = styled.div.attrs(() => ({
  className: "media-left",
}))`
  margin-left: 3em;
`;

const ResultSummaryTitle = styled.div`
  min-height: 5em;
`;
const ResultSummaryTitleParX = styled.span`
  color: ${COLOR_SECONDARY1};
  font-weight: bolder;
  margin-left: 0.3rem;
  margin-right: 0.3rem;
  span.value {
    margin-right: 0.3rem;
  }
  svg {
    margin: 0.2rem;
    margin-right: 0;
    vertical-align: bottom;
  }
`;

const ResultSummaryTitleParY = styled.span`
  margin-left: 0.3rem;
  margin-right: 0.3rem;
  svg {
    margin: 0.2rem;
    margin-right: -0.2rem;
    vertical-align: bottom;
  }
`;

const ResultSummaryLegend = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-left: 1rem;
`;

const ResultSummaryLegendLabel = styled.div`
  display: inline-flex;
  margin-left: 0rem !important;
  margin-bottom: 0rem !important;
  font-weight: normal;

  svg {
    margin-right: 5px;
    margin-left: 5px;
  }

  span {
    font-weight: bolder;
    margin-left: 5px;
  }
`;

const ResultSummaryLegendValue = styled.div`
  margin-left: 1rem;
  .value {
    margin-right: 0.5rem;
    font-weight: bolder;
  }
  svg {
    vertical-align: bottom;
    margin-right: 5px;
    margin-left: 5px;
  }
`;

const FormDiv = styled.section.attrs(() => ({
  className: "section",
}))`
  padding: 2rem 2.5rem;
  display: block;
  // border-bottom: 2px solid ${COLOR_SECONDARY1};

  button {
    margin-top: -1em;
  }
`;

const ResultDiv = styled.section.attrs(() => ({
  className: "section",
}))`
  display: block;
  padding: 2rem 2.5rem;
`;

const FormDivHeader = styled.div`
  display: flex;
  align-items: center;
  .button {
    margin-left: 1em;
  }
`;

const ParameterDiv = styled.div.attrs(() => ({
  className: "parameter-div",
}))`
  display: block;
  margin-bottom: 2em;
`;

const ParameterInputs = styled.div`
  display: flex;
  align-items: center;
  margin-left: 3em;
  margin-right: 1em;
  .slider {
    width: 20em;
    margin: 0em !important;
  }
  input {
    margin-left: 0.5em;
    width: 5em;
  }
  .input-value {
    margin-left: 0.3em;
    margin-right: 0.3em;
    font-weight: bold;
    font-size: 1.2em;
    width: 2em;
  }
`;

const ParameterAdditional = styled.div.attrs(() => ({}))`
  padding: 1em;
  margin-left: 2em;

  input {
    margin-right: 5px;
  }
`;

const ExtendedIcon = styled.div.attrs(() => ({
  className: "",
}))`
  padding: 0em 0.7em;
  font-size: 1.2em;
  color: ${COLOR_SECONDARY1};
`;

const InputParameterWrapperDiv = styled.div`
  margin-top: 0.5em;
`;

const HeaderSection = styled.div`
  padding: 4rem 2rem 0rem 2rem;
`;

const WrapperDiv = styled.div.attrs(() => ({
  className: "content",
}))``;

interface IBoxContentProps {
  entered?: boolean;
}

const BoxContent = styled.div.attrs((props: IBoxContentProps) => ({
  className: "is-desktop",
}))`
  padding: 0.5em;
  min-height: 12em;
  display: ${(props: any) => {
    return props.entered ? "block" : "hidden";
  }};
`;

/**
 * INPUT MODAL
 */

const ModalSectionInput = styled.div.attrs(({ }) => ({
  className: "",
}))`
  .parameter-div {
    padding-left: 1em;
  }
`;

const ModalSectionMap = styled.div.attrs(({ }) => ({
  className: "",
}))``;

const ErrorLoginMessage = styled.div.attrs(({ }) => ({
  className: "notification is-danger",
}))`
  position: absolute;
  width: 100%;
  left: 0px;
  top: 3em;
`;

const WarningTextWrapper = styled.div``;
const WarningTextIcon = styled.div`
  margin-left: 0.5em;
`;
const WarningTextTooltip = styled.div`
  position: absolute;
  background: ${COLOR_PRIMARY};
  z-index: 200;
  margin-top: 1em;
  margin-left: -20em;
  padding: 0.5rem;
  color: white;
  border-radius: 0.5rem;
  box-shadow: 0.5rem 0.5rem rgb(0 0 0 / 50%);
`;

export default RiskCalculatorPage;

const calculateOptimalStrategy = (
  employeesAll: number,
  nv: number,
  betau: number,
  betav: number,
  contact_rate: number,
  InfectProb: number,
  test_interval: number,
  presence: number
) => {
  // console.log(
  //   employeesAll,
  //   nv,
  //   betau,
  //   betav,
  //   contact_rate,
  //   InfectProb,
  //   test_interval,
  //   presence
  // );

  // Divide a day into scale(=4) time intervals such that (kappa/scale) contacts per intervl is possible
  let scale = 4;
  let workingdays = 5 / 7;
  // Note: Do analysis for (day/scale) intervals and kappa/scale contacts
  let tau_bar = Math.round(scale * workingdays * (test_interval / 2));
  InfectProb = InfectProb / scale;

  let occup = presence / 100;
  // 5 constact is a constant plus some percentage of presented staff
  let kappa = 5 + contact_rate * 0.2 * occup * employeesAll;
  kappa = kappa < contact_rate * 200 ? kappa : contact_rate * 200;
  kappa = kappa / scale;

  // The reason behind of this initial value is the real-world behaviours
  let pu = 0; //betau * occup * occup * kappa * (1 / (employeesAll - 1) / 2);
  let pv = 0; //betav * occup * occup * kappa * (1 / (employeesAll - 1) / 2);
  let n_infect = 0;

  for (let d = 1; d < tau_bar + 1; d++) {
    let A = Math.pow(
      1 - betau,
      occup * occup * kappa * (1 / (employeesAll - 1))
    );
    let B = Math.pow(
      1 - pu * betau,
      kappa *
      (1 - 1 / (employeesAll - 1)) *
      occup *
      ((employeesAll - nv - 1) / (employeesAll - 1))
    );
    let C = Math.pow(
      1 - pv * betau,
      kappa * (1 - 1 / (employeesAll - 1)) * occup * (nv / (employeesAll - 1))
    );
    // (1-pu) is the probability remaining health till yesterday, (d-1)
    let Pr_Healty = A * B * C * (1 - pu);
    pu = 1 - Pr_Healty;

    A = Math.pow(1 - betav, occup * occup * kappa * (1 / (employeesAll - 1)));
    B = Math.pow(
      1 - pu * betav,
      kappa *
      (1 - 1 / (employeesAll - 1)) *
      occup *
      ((employeesAll - nv - 1) / (employeesAll - 1))
    );
    C = Math.pow(
      1 - pv * betav,
      kappa * (1 - 1 / (employeesAll - 1)) * occup * (nv / (employeesAll - 1))
    );
    Pr_Healty = A * B * C * (1 - pv);
    pv = 1 - Pr_Healty;

    let m = 1 + (employeesAll - nv - 1) * pu + nv * pv;
    let x = m * (1 - Math.pow(1 - InfectProb, occup * employeesAll));
    n_infect = n_infect + x - (n_infect * x) / employeesAll;
  }

  n_infect = n_infect * (7 / (tau_bar / scale)); // weekly scaling

  if (n_infect > employeesAll) {
    n_infect = employeesAll;
  }

  let ratio_of_infections = n_infect / employeesAll;

  return ratio_of_infections;
};

// console.log(
//   calculateOptimalStrategy(
//     189,
//     113.4,
//     0.05,
//     0.0075000000000000015,
//     0.12,
//     0.0000675,
//     8,
//     80
//   )
// );
