import React from "react";

import styled from "styled-components";

import { COLOR_SECONDARY1 } from "variables";

export const Title1 = ({ children }: { children: React.ReactNode }) => {
  return <Title1Div>{children}</Title1Div>;
};

const Title1Div = styled.div.attrs(({ color }: { color: string }) => ({
  className: "title is-2",
}))`
  color: ${({ color }) => color || COLOR_SECONDARY1};
  padding-top: 1em;
`;
