import classNames from "classnames";
import {
  formatDate,
  getLanguageById,
  languages,
  t,
  translate,
  translateText,
} from "localization/translate";
import { Helmet } from "react-helmet";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";

import React, { useGlobal, useMemo, useState } from "reactn";
import styled from "styled-components";
//import imageSrc from "./logo.png";
import { COLOR_PRIMARY, COLOR_SECONDARY1, COLOR_SECONDARY2 } from "variables";
import packageObject from "../package.json";
import { tabs } from "./index";
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from "react-query";
import { useEffect } from "react";
import RetirementPage from "pages/retirement";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const { default: logoW2T } = require("./assets/logo-w2t.png");
const { default: logoCasus } = require("./assets/logo-casus.png");

export const App = () => {
  //const DefaultPage = tabs[2].component;
  const [lang, setLang] = useGlobal("lang");

  const lastUpdate = new Date();
  const lastUpdateLocal = formatDate(lastUpdate.toUTCString(), lang);

  const [activeTab, setActiveTab] = useGlobal("activeTab");

  const ActiveTabComponent = useMemo(() => {
    const tab = tabs.find((t) => t.id === activeTab);
    return tab?.component || tabs[0].component;
  }, [activeTab]);

  // return (
  //   <div>
  //     <Tabs />

  //     <Page>
  //       <div className="container">
  //         <ActiveTabComponent />
  //       </div>
  //     </Page>-
  //     <Footer>
  //       <div className="content has-text-centered">
  //         <p>
  //           {translateText("footer", "version")} {version}
  //         </p>
  //         <p>
  //           {translateText("footer", "data-update")} {lastUpdateLocal}
  //         </p>
  //         <p>Copyright © 2021 Where2Test</p>
  //       </div>
  //     </Footer>
  //   </div>
  // );
  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <div>
          <Helmet>
            <meta charSet="utf-8" />
            <title>Where2Test</title>
          </Helmet>
          <Tabs />

          <WholePage>
            {/* <div className="container">
                    <DefaultPage />
                </div> */}
            <Routes>
              <Route
                path="/"
                element={
                  <div className="container">
                    <RetirementPage />
                  </div>
                }
              />
              {tabs.map((tab) => {
                const Page = tab.component;
                return (
                  <Route
                    path={tab.url}
                    key={tab.id}
                    element={
                      <div className="container">
                        <Page />
                      </div>
                    }
                  />
                );
              })}
              <Route
                path="/:page"
                element={
                  <div className="container">
                    <RetirementPage />
                  </div>
                }
              />
            </Routes>
          </WholePage>
          <Footer>
            <div className="content has-text-centered">
              <p>
                {`${translateText("footer", "version")} ${packageObject.version
                  } |
                ${translateText("footer", "data-update")} ${lastUpdateLocal}`}
              </p>
              <p>
                {`Copyright © 2021, Where2Test All rights reserved. |
                Released under the `}
                <a style={{ color: "white" }}>BSD-4-Clause licence</a>.
              </p>
            </div>
          </Footer>
        </div>
      </QueryClientProvider>
    </BrowserRouter>
  );
};

const Tabs = ({ }) => {
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useGlobal("activeTab");
  const [burgerOpen, setBurgerOpen] = useState<boolean>(false);
  const [lang, setLang] = useGlobal("lang");

  const [groups, setGroups] = useState<string[]>([]);
  const handleTabChange = (newTabId: string) => {
    setActiveTab(newTabId);
    navigate(newTabId);
  };

  useEffect(() => {
    const groups: string[] = [];
    tabs
      .filter((tab) => tab.group)
      .forEach((tab) => {
        if (tab.group && !groups.includes(tab.group)) {
          groups.push(tab.group);
        }
      });

    setGroups(groups);
  }, []);

  return (
    <Navbar role="navigation" aria-label="main navigation">
      <NavbarBrand>
        <span className="navbar-item">
          <img src={logoW2T} />
        </span>

        <NavbarBrandBurger
          active={burgerOpen}
          onClick={() => setBurgerOpen(!burgerOpen)}
        >
          <span></span>
          <span></span>
          <span></span>
        </NavbarBrandBurger>
      </NavbarBrand>
      <NavbarMenu active={burgerOpen}>
        <div className="navbar-start">
          {tabs
            .filter((tab) => !tab.group)
            .map((tab) => {
              return (
                <NavbarMenuItem
                  key={tab.id}
                  onClick={() => {
                    handleTabChange(tab.id);
                  }}
                  active={tab.id === activeTab}
                >
                  {t("navbar", tab.id, lang)}
                </NavbarMenuItem>
              );
            })}
          {groups.map((group) => {
            const groupTabs = tabs.filter((tab) => tab.group === group);
            return (
              <div
                className="navbar-item has-dropdown is-hoverable is-primary"
                key={group}
              >
                <NavbarMenuGroup
                  active={!!groupTabs.find((tab) => tab.id === activeTab)}
                >
                  {t("navbar", group, lang)}
                </NavbarMenuGroup>

                <div className="navbar-dropdown">
                  {groupTabs
                    .filter((tab) => !tab.hideInNavBar)
                    .map((tab) => {
                      return (
                        <NavbarMenuItem
                          key={tab.id}
                          onClick={() => {
                            handleTabChange(tab.id);
                          }}
                          active={tab.id === activeTab}
                        >
                          {t("navbar", tab.id, lang)}
                        </NavbarMenuItem>
                      );
                    })}
                </div>
              </div>
            );
          })}
        </div>
        <div className="navbar-end">
          <div
            className="navbar-item has-dropdown is-hoverable"
            style={{ alignItems: "center" }}
          >
            <a
              className="navbar-link"
              style={{ height: "3em", display: "flex" }}
            >
              <FlagDiv>
                {/* <SVG width={24} src={getLanguageById(lang).logo} /> */}
              </FlagDiv>
              {getLanguageById(lang).label}
            </a>

            <div className="navbar-dropdown">
              {languages.map((language) => {
                return (
                  <a
                    className="navbar-item"
                    style={{ height: "3em", display: "flex" }}
                    key={language.id}
                    onClick={() => {
                      setLang(language.id);
                    }}
                  >
                    <FlagDiv>
                      {/* <SVG width={24} src={language.logo} /> */}
                    </FlagDiv>
                    {language.label}
                  </a>
                );
              })}
            </div>
          </div>
        </div>
      </NavbarMenu>
    </Navbar>
  );
};

const WholePage = styled.div`
  padding-top: 5em;
  padding-bottom: 5em;
`;
const Navbar = styled.nav.attrs(() => ({
  className: "navbar is-fixed-top",
}))`
  border-bottom: 2px solid ${COLOR_SECONDARY1};
`;

const NavbarBrand = styled.div.attrs(() => ({
  className: "navbar-brand",
}))`
  min-height: 6em;
  max-height: 6em;
  img {
    max-height: 3em;
    margin-left: 2em;
    margin-right: 2em;
  }
`;

const NavbarMenu = styled.div.attrs<{ active: boolean }>(({ active }) => ({
  className: `navbar-menu ${active && "is-active"}`,
})) <{ active: boolean }>`
  min-height: 6em;
  max-height: 6em;
  div {
    margin-left: 0;
  }
  img {
    max-height: 3em;
    margin-left: 2em;
    margin-right: 2em;
  }
`;
const NavbarBrandBurger = styled.div.attrs<{ active: boolean }>(
  ({ active }) => ({
    className: `navbar-burger ${active && "is-active"}`,
  })
) <{ active: boolean }>`
  height: 6em;
  margin-right: 5px;
  span {
    height: 2px;
  }
`;
const FlagDiv = styled.div.attrs<{}>(({ }) => ({})) <{}>`
  margin-right: 5px;
`;

const NavbarMenuItem = styled.a.attrs<{ active: boolean }>(({ active }) => ({
  className: classNames("navbar-item", { "is-active": active }),
})) <{ active: boolean }>`
  color: ${({ active }) =>
    active ? COLOR_PRIMARY : COLOR_SECONDARY1} !important;
  font-weight: 500;
  text-decoration: ${({ active }) => (active ? "underline" : "none")};
`;

const NavbarMenuGroup = styled.a.attrs<{ active: boolean }>(({ active }) => ({
  className: classNames("navbar-link", { "is-active": active }),
})) <{ active: boolean }>`
  color: ${({ active }) =>
    active ? COLOR_PRIMARY : COLOR_SECONDARY1} !important;
  font-weight: 500;
  text-decoration: ${({ active }) => (active ? "underline" : "none")};
`;

const Footer = styled.div.attrs(() => ({
  className: "footer",
}))`
  background-color: ${COLOR_SECONDARY1};
  color: ${COLOR_SECONDARY2};
  padding: 1em;
  font-size: 12px;
  p {
    margin-bottom: 0px !important;
  }
`;
