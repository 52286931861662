import React from "react";

import styled from "styled-components";
import { BOX_PADDING, BOX_MARGIN } from "variables";

export const Box = ({ children }: { children: React.ReactNode }) => {
    return <BoxDiv>{children}</BoxDiv>;
};

const BoxDiv = styled.div.attrs(() => ({
    className: "box",
}))`
    padding: ${BOX_PADDING}px;
    margin: ${BOX_MARGIN}px;
`;
