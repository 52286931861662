import L, { latLng, LatLngExpression } from "leaflet";
import "leaflet-arrowheads";
import "leaflet-polylinedecorator";
import {
  CircleMarker,
  MapContainer,
  Pane,
  Polygon,
  Polyline,
  TileLayer,
  Tooltip,
  useMap,
} from "react-leaflet";
import { VegaLite } from "react-vega";
import React, { useEffect, useGlobal, useMemo, useRef, useState } from "reactn";

import Select, {
  components,
  OptionProps,
  SingleValueProps,
} from "react-select";

import { GiPerson } from "react-icons/gi";

import chroma from "chroma-js";

import { Box } from "components/box";
import { Title } from "components/title";

import { t } from "localization/translate";

import api from "api";
import { useQuery } from "react-query";
import styled from "styled-components-fast";

import { COLOR_PRIMARY, COLOR_SECONDARY1 } from "variables";

import Slider from "rc-slider";

import {
  MapLegend,
  MapLegendColumn,
  MapLegendColumns,
  MapLegendContent,
  MapLegendLine,
  MapLegendLineBox,
  MapLegendLineText,
  MapLegendTitle,
} from "components/region-map";
import { BiExport, BiImport } from "react-icons/bi";
import { LayerGroup } from "react-leaflet";

const H_MAP_INFO = 800;
const H_MAP_INFO_SELECTED = 250;
const W_INFO = 400;
const W_SLIDER_LF_PADDING = 70;
const H_SLIDER_TB_PADDING = 35;
const W_INFO_PADDING = 10;
const W_MAP_INFO_NEIGH_CHART = 240;
const H_MAP_INFO_NEIGH_CHART = 30;

const COLORS_COUNTRY = {
  cz: "#386cb0",
  de: "#bf5b17",
  pl: "#f0027f",
  default: "black",
};

const getCountryColor = (country: string) => {
  return Object.keys(COLORS_COUNTRY).includes(country)
    ? // @ts-ignore
      COLORS_COUNTRY[country]
    : COLORS_COUNTRY.default;
};

// load geometries
const { default: flip } = require("@turf/flip");
const regions = flip(require("./../../data/spatial/regions.json"));
const regionsAdmin: { [key: string]: LatLngExpression } = {};
flip(require("./../../data/spatial/regions-admin.json")).features.forEach(
  (feat: any) => {
    regionsAdmin[feat.properties.id] = feat.geometry.coordinates;
  }
);

const dataIncidenceList = require("./../../data/spatial/regions_incidence.json");
const dataIncidence: any = {};
dataIncidenceList.map((dataIncidenceRegion: any) => {
  dataIncidence[dataIncidenceRegion.region] = dataIncidenceRegion;
});

const dataNeighbors = require("./../../data/spatial/distances.json");
Object.keys(dataNeighbors).forEach((regionId: string) => {
  dataNeighbors[regionId] = Object.keys(dataNeighbors[regionId]);
});

interface IRegion {
  id: string;
  type: "Feature";
  properties: {
    country: string;
    region: string;
  };
  geometry: {
    type: "Polygon";
    coordinates: LatLngExpression[][];
  };
}

//interface CorrelationPageProps extends RouteComponentProps {}

interface ICorrelationRegionResponse {
  [regid: string]: {
    c_i: number;
    c_e: number;
    // c_s: number;
    dist: number;
    rid: string;
  };
}
type ICorrelationTimelineResponse = string[];
interface ICorrelationRegionListResponse {
  [regid: string]: {
    id: string;
    population: number;
    name: string;
    country: string;
  };
}
interface ICorrelationMapResponse {
  [regid: string]: number;
}

enum EMapMode {
  INCINDENCE = "inc",
  CHANGE = "ch",
  C_EXPORT = "c_e",
  C_IMPORT = "c_i",
  //C_SYNCHRONAL = "c_s",
  B_EXPORT = "b_e",
  B_IMPORT = "b_i",
}

enum ERegionMode {
  C_EXPORT = "c_e",
  C_IMPORT = "c_i",
  //C_SYNCHRONAL = "c_s",
}

const mapModes = {
  inc: {
    id: EMapMode.INCINDENCE,
    legendDomain: [0, 25, 50, 100, 250, 500, 1000],
    colorScheme: chroma
      .scale("YlOrRd")
      .domain([0, 25, 50, 100, 250, 500, 1000]),
    //.domain([0, 1000]),
    //.classes(6),
  },
  ch: {
    id: EMapMode.CHANGE,
    legendDomain: [0, 25, 50, 75, 100],
    colorScheme: chroma.scale("BuPu").domain([0, 100]).classes(9),
  },
  c_e: {
    id: EMapMode.C_EXPORT,
    legendDomain: [0, 25, 50, 75, 100],
    colorScheme: chroma.scale("YlGn").domain([0, 100]).classes(9),
  },
  c_i: {
    id: EMapMode.C_IMPORT,
    legendDomain: [0, 25, 50, 75, 100],
    colorScheme: chroma.scale("YlGnBu").domain([0, 100]).classes(9),
  },
  //c_s: {
  //id: EMapMode.C_SYNCHRONAL,
  //colorScheme: chroma.scale("YlOrBr").domain([0, 100]).classes(9),
  //},
  b_e: {
    id: EMapMode.B_EXPORT,
    legendDomain: [0, 25, 50, 75, 100],
    colorScheme: chroma.scale("RdPu").domain([0, 100]).classes(9),
  },
  b_i: {
    id: EMapMode.B_IMPORT,
    legendDomain: [0, 25, 50, 75, 100],
    colorScheme: chroma.scale("RdPu").domain([0, 100]).classes(9),
  },
};
const regionModes = {
  c_e: {
    id: ERegionMode.C_EXPORT,
    colorScheme: chroma.scale("YlGn").domain([0, 100]).classes(9),
  },
  c_i: {
    id: ERegionMode.C_IMPORT,
    colorScheme: chroma.scale("YlGnBu").domain([0, 100]).classes(9),
  },
  //c_s: {
  // id: ERegionMode.C_SYNCHRONAL,
  // colorScheme: chroma.scale("YlOrBr").domain([0, 100]).classes(9),
  //},
};

export interface ICorrelationParams {
  timeFrom: number;
  timeTo: number;
  regId: string;
  mapMode: EMapMode;
  regionMode: ERegionMode;
}
const initParams: ICorrelationParams = {
  timeFrom: 1,
  timeTo: 72,
  regId: "",
  mapMode: EMapMode.INCINDENCE,
  regionMode: ERegionMode.C_IMPORT,
};

// Hook
function useDebounce(value: any, delay: number) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );

  return debouncedValue;
}

const CorrelationPage = () => {
  const [lang, setLang] = useGlobal("lang");
  const [corrParams, setCorrParams] = useState<ICorrelationParams>(initParams);
  const debouncedParameters: ICorrelationParams = useDebounce(corrParams, 500);

  // handling width
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [wPage, setWPage] = useState<number>(0);

  const wInfo = 400;

  useEffect(() => {
    const handleResize = () => {
      if (wrapperRef.current) {
        //console.log("setting width", wrapperRef.current.offsetWidth);
        setWPage(wrapperRef.current.offsetWidth);
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
  }, [wrapperRef.current]);

  const handleClickedMapMode = (mapModeId: EMapMode) => {
    setCorrParams({ ...corrParams, mapMode: mapModeId });
  };
  const handleClickedRegionMode = (regionModeId: ERegionMode) => {
    setCorrParams({ ...corrParams, regionMode: regionModeId });
  };

  const handleClickedRegion = (regionId: string) => {
    if (regionId !== "16056") {
      setCorrParams({
        ...corrParams,
        regId: regionId === corrParams.regId ? "" : regionId,
      });
    }
  };

  const MIN_TIME_DIFF = 4;
  const handleChangeTime = (newTime: [number, number]) => {
    console.log(newTime);
    let newTime0 = newTime[0];
    let newTime1 = newTime[1];

    if (Math.abs(newTime0 - newTime1) < MIN_TIME_DIFF) {
      if (newTime0 == corrParams.timeFrom) {
        newTime1 = newTime0 + MIN_TIME_DIFF;
      } else {
        newTime0 = newTime1 - MIN_TIME_DIFF;
      }
    }
    if (window && window.getSelection) {
      //@ts-ignore
      window.getSelection().removeAllRanges();
      //@ts-ignore
    } else if (document.selection) {
      //@ts-ignore
      document.selection.empty();
    }
    setCorrParams({ ...corrParams, timeFrom: newTime0, timeTo: newTime1 });
  };

  const {
    status: statusRegion,
    data: dataRegion,
    error: errorRegion,
    isFetching: isFetchingRegion,
  } = useQuery(
    [debouncedParameters],
    async (): Promise<ICorrelationRegionResponse> => {
      const res = await api.correlationRegion(corrParams);
      //console.log(res);
      return res;
    },
    { enabled: !!corrParams.regId, initialData: {} }
  );

  const {
    status: statusMap,
    data: dataMap,
    error: errorMap,
    isFetching: isFetchingMap,
  } = useQuery(
    [
      debouncedParameters.timeFrom,
      debouncedParameters.timeTo,
      debouncedParameters.mapMode,
    ],
    async (): Promise<ICorrelationMapResponse> => {
      console.log("fetching map data");
      const res = await api.correlationMap(corrParams);
      return res;
    },
    { enabled: true, initialData: {} }
  );

  const {
    status: statusTimeline,
    data: dataTimeline,
    error: errorTimeline,
    isFetching: isFetchingTimeline,
  } = useQuery(
    ["timeline"],
    async (): Promise<ICorrelationTimelineResponse> => {
      const res = await api.correlationTimeline();
      return res;
    },
    { enabled: true, initialData: [] }
  );

  //console.log(dataTimeline);

  const {
    status: statusRegionList,
    data: dataRegionList,
    error: errorRegionList,
    isFetching: isFetchingRegionList,
  } = useQuery(
    ["regionList"],
    async (): Promise<ICorrelationRegionListResponse> => {
      const res = await api.correlationRegionList();
      return res;
    },
    { enabled: true, initialData: {} }
  );

  const markerStyle = {
    //transform: "rotate(270deg)",
    marginLeft: "-31px",
    marginTop: "24px",
    minWidth: "70px",
  };

  const timelineMarks: { [key: number]: { style: any; label: string } } =
    useMemo(() => {
      const marks: { [key: number]: { style: any; label: string } } = {
        1: {
          style: {
            marginTop: "0px",
            minWidth: "80px",
            paddingRight: "0px",
            fontWeight: corrParams.timeFrom !== 1 ? "normal" : "bold",
            color: corrParams.timeFrom !== 1 ? "black" : COLOR_SECONDARY1,
          },
          label: dataTimeline ? dataTimeline[0] : "",
        },
        72: {
          style: {
            marginTop: "0px",
            minWidth: "80px",
            paddingRight: "0px",
            fontWeight: corrParams.timeFrom !== 72 ? "normal" : "bold",
            color: corrParams.timeFrom !== 72 ? "black" : COLOR_SECONDARY1,
          },
          label: dataTimeline ? dataTimeline[dataTimeline.length - 1] : "",
        },
      };

      if (corrParams.timeFrom !== 1) {
        marks[corrParams.timeFrom as number] = {
          style: {
            marginTop: `-${H_SLIDER_TB_PADDING}px`,
            paddingRight: "0px",
            minWidth: "80px",
            color: COLOR_SECONDARY1,
            fontWeight: "bold",
          },
          label: dataTimeline ? dataTimeline[corrParams.timeFrom] : "",
        };
      }

      if (corrParams.timeTo !== 72) {
        marks[corrParams.timeTo as number] = {
          style: {
            marginTop: `-${H_SLIDER_TB_PADDING}px`,
            paddingRight: "0px",
            minWidth: "160px",
            textAlign: "center",
            color: COLOR_SECONDARY1,
            fontWeight: "bold",
          },
          label: dataTimeline ? dataTimeline[corrParams.timeTo] : "",
        };
      }

      return marks;
    }, [dataTimeline, corrParams.timeFrom, corrParams.timeTo]);

  return (
    <WrapperDiv ref={wrapperRef}>
      <Title size="xl" marginBottom={0}>
        {t("correlation", "title", lang)}
      </Title>
      <Box>
        <StyledMenuDiv>
          <StyledTimelineDiv>
            <TimelineSliderDiv>
              <Title size="xs" marginBottom={0}>
                {t("correlation", "timeline", lang)}
              </Title>
              <Slider
                range
                style={{
                  margin: `${H_SLIDER_TB_PADDING}px ${W_SLIDER_LF_PADDING}px`,
                  width: `calc(100% - ${W_SLIDER_LF_PADDING * 2}px)`,
                }}
                trackStyle={{
                  backgroundColor: COLOR_SECONDARY1,
                  height: 6,
                  cursor: "pointer",
                }}
                handleStyle={{
                  borderColor: COLOR_SECONDARY1,
                  height: 14,
                  width: 14,
                  // marginLeft: -10,
                  marginTop: -4,
                  backgroundColor: COLOR_SECONDARY1,
                }}
                railStyle={{}}
                draggableTrack
                marks={timelineMarks}
                allowCross={false}
                defaultValue={[initParams.timeFrom, initParams.timeTo]}
                min={initParams.timeFrom}
                max={initParams.timeTo}
                onChange={(newTimeValues: any) => {
                  handleChangeTime(newTimeValues as [number, number]);
                }}
                value={[corrParams.timeFrom, corrParams.timeTo]}
              />
            </TimelineSliderDiv>
            {/* <Title size="sm" marginBottom={0}>
            {t("correlation", "start-date", lang)}
          </Title>
          <div className="select">
            <select
              disabled={!dataTimeline}
              value={dataTimeline && dataTimeline[corrParams.timeFrom]}
              onChange={(e) => {
                if (dataTimeline) {
                  handleChangeTime([
                    dataTimeline.indexOf(e.target.value),
                    debouncedParameters.timeTo,
                  ]);
                }
              }}
            >
              {dataTimeline &&
                dataTimeline.map((weekDate) => {
                  return (
                    <option key={weekDate} value={weekDate}>
                      {weekDate}
                    </option>
                  );
                })}
            </select>
          </div>
          <Title size="sm" marginBottom={0}>
            {t("correlation", "end-date", lang)}
          </Title>
          <div className="select">
            <select
              disabled={!dataTimeline}
              value={dataTimeline && dataTimeline[corrParams.timeTo]}
              onChange={(e) => {
                if (dataTimeline) {
                  handleChangeTime([
                    debouncedParameters.timeFrom,
                    dataTimeline.indexOf(e.target.value),
                  ]);
                }
              }}
            >
              {dataTimeline &&
                dataTimeline.map((weekDate) => {
                  return (
                    <option key={weekDate} value={weekDate}>
                      {weekDate}
                    </option>
                  );
                })}
            </select>
          </div> */}
          </StyledTimelineDiv>

          {/* <div className="buttons has-addons">
            {Object.values(mapModes).map((mapMode: any) => {
              return (
                <button
                  key={mapMode.id}
                  className={`button is-small ${
                    debouncedParameters.mapMode === mapMode.id && "is-link"
                  }`}
                  onClick={() => handleClickedMapMode(mapMode.id)}
                >
                  {t("correlation", `mapparameter-${mapMode.id}`, lang)}
                </button>
              );
            })}
          </div> */}
        </StyledMenuDiv>

        {dataRegionList && (
          <StyledMapInfoDiv>
            <StyledMapDiv>
              <CorrelationMap
                handleClickedRegion={handleClickedRegion}
                handleClickedMapMode={handleClickedMapMode}
                width={wPage - wInfo}
                dataRegion={dataRegion || {}}
                dataMap={dataMap || {}}
                corrParams={debouncedParameters}
                lang={lang}
                dataRegionList={dataRegionList}
              />
            </StyledMapDiv>
            <StyledInfoDiv>
              <CorrelationInfo
                handleClickedRegion={handleClickedRegion}
                dataRegion={dataRegion || {}}
                dataMap={dataMap || {}}
                dataTimeline={dataTimeline || []}
                corrParams={debouncedParameters}
                width={wInfo}
                debouncedParameters={debouncedParameters}
                lang={lang}
                dataRegionList={dataRegionList}
                handleClickedRegionMode={handleClickedRegionMode}
              />
            </StyledInfoDiv>
          </StyledMapInfoDiv>
        )}
      </Box>
    </WrapperDiv>
  );
};
const WrapperDiv = styled.div.attrs(() => ({
  className: "content",
}))``;

const StyledTimelineDiv = styled.div.attrs(() => ({}))`
  margin-bottom: 1em;
`;

const TimelineSliderDiv = styled.div.attrs(() => ({}))`
  .rc-slider-mark-text {
    margin-right: -40px !important;
  }
  .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
    box-shadow: 0 0 0 5px ${COLOR_PRIMARY};
    background-color: ${COLOR_PRIMARY};
  }

  .rc-slider-track:hover {
    background-color: ${COLOR_PRIMARY};
  }
`;
const StyledMenuDiv = styled.div.attrs(() => ({}))`
  margin-top: 20px;
  margin-bottom: 20px;
`;
const StyledMapPanelDiv = styled.div.attrs(() => ({}))`
  //display: grid;
  //grid-template-columns: auto 200px;
  height: 700px;
`;

// map + info panel
type IStyledMapInfoDiv = {
  wInfo: number;
};
const StyledMapInfoDiv = styled.div.attrs(() => ({}))`
  display: grid;
  grid-template-columns: auto ${W_INFO}px;
`;

const StyledMapDiv = styled.div.attrs(() => ({}))``;
const StyledInfoDiv = styled.div.attrs(() => ({}))`
  background-color: white;
`;
const StyledInfo = styled.div.attrs(() => ({}))`
  overflow-x: hidden;
  overflow-y: hidden;
  max-height: ${H_MAP_INFO};
  padding: ${W_INFO_PADDING};
`;

const StyledInfoButtonGroup = styled.div.attrs(() => ({}))`
  margin-bottom: 10px;
  button svg {
    margin-right: 5px;
  }
`;

const PolylineDecorator = (props: any) => {
  const map = useMap();

  useEffect(() => {
    const polyline = L.polyline([props.positions], {
      color: COLOR_SECONDARY1,
      weight: props.lineWidth,
      opacity: 1,
      pane: "selection-polylines",
    }).arrowheads({
      frequency: "endonly",
      yawn: 40,
      size: `${props.arrowHeadWidth}px`,
      fill: true,
      stroke: false,
      offsets: { end: "-50px" },
      fillOpacity: 1,
    });
    polyline.addTo(map);

    return () => {
      if (polyline) {
        polyline.removeFrom(map);
      }
    };
  }, []);

  return null;
};

const CorrelationInfo = ({
  corrParams,
  width,
  dataRegion,
  dataMap,
  dataTimeline,
  handleClickedRegion,
  lang,
  debouncedParameters,
  dataRegionList,
  handleClickedRegionMode,
}: {
  corrParams: ICorrelationParams;
  width: number;
  dataRegion: ICorrelationRegionResponse;
  dataMap: ICorrelationMapResponse;
  dataTimeline: ICorrelationTimelineResponse;
  handleClickedRegion: Function;
  lang: string;
  debouncedParameters: ICorrelationParams;
  dataRegionList: ICorrelationRegionListResponse;
  handleClickedRegionMode: Function;
}) => {
  const [hlNeighborId, setHlNeighborId] = useState<string>("");

  const [displayChange, setDisplayChange] = useState<boolean>(true);
  const [displayIncidence, setDisplayIncidence] = useState<boolean>(true);

  const selectedRegion = useMemo(() => {
    return corrParams.regId &&
      Object.keys(dataIncidence).includes(corrParams.regId)
      ? dataIncidence[corrParams.regId]
      : false;
  }, [corrParams.regId]);

  const neighborsSorted = useMemo(() => {
    const neighborIdsSorted = Object.keys(dataRegion);
    neighborIdsSorted.sort((a, b) => {
      return dataRegion[a][corrParams.regionMode] >
        dataRegion[b][corrParams.regionMode]
        ? -1
        : 1;
    });
    return neighborIdsSorted.map((neighborId) => {
      return {
        ...dataRegion[neighborId],
        ...dataIncidence[neighborId],
      };
    });
  }, [dataRegion, corrParams.regionMode]);

  const rData = useMemo(() => {
    return corrParams.regId && dataTimeline
      ? {
          values: selectedRegion.incidence.map(
            (incidenceValue: number, iIndex: number) => {
              const notFirstWeek = iIndex !== 0;
              const incidenceChange = notFirstWeek
                ? incidenceValue - selectedRegion.incidence[iIndex - 1]
                : 0;

              const neighborsIncidence = Object.keys(dataRegion).map(
                (regionId) => dataIncidence[regionId].incidence[iIndex]
              );
              const neighborsChangeVals = notFirstWeek
                ? Object.keys(dataRegion).map((regionId) => {
                    return (
                      dataIncidence[regionId].incidence[iIndex] -
                      dataIncidence[regionId].incidence[iIndex - 1]
                    );
                  })
                : [];

              const neighborsIncidenceAvg = neighborsIncidence
                .filter((ncv) => ncv > 0)
                .reduce((p, c, _, a) => p + c / a.length, 0);
              const neighborsChangeAvg = neighborsChangeVals
                .filter((ncv) => ncv > 0)
                .reduce((p, c, _, a) => p + c / a.length, 0);

              const hlNeighborValue = hlNeighborId
                ? dataIncidence[hlNeighborId].incidence[iIndex]
                : 0;
              const hlNeighborChangeValue = hlNeighborId
                ? dataIncidence[hlNeighborId].incidence[iIndex] -
                  dataIncidence[hlNeighborId].incidence[iIndex - 1]
                : 0;

              return iIndex >= corrParams.timeFrom &&
                iIndex <= corrParams.timeTo
                ? {
                    date: dataTimeline[iIndex],
                    incidenceValue: incidenceValue,
                    incidenceChange: incidenceChange > 0 ? incidenceChange : 0,
                    neighborsChangeAvg: neighborsChangeAvg,
                    neighborsIncidenceAvg: neighborsIncidenceAvg,

                    hlNeighborValue: hlNeighborValue,
                    hlNeighborChangeValue:
                      hlNeighborChangeValue > 0 ? hlNeighborChangeValue : 0,
                  }
                : {};
            }
          ),
        }
      : { values: [] };
  }, [corrParams, dataRegion, hlNeighborId]);

  const spec = useMemo(() => {
    const specObj = {
      $schema: "https://vega.github.io/schema/vega-lite/v5.json",
      description: "A simple bar chart with embedded data.",
      width: width - 90,
      height: 100,
      data: { name: "values" },
      encoding: {
        x: {
          field: "date",
          type: "nominal",
          title: null,
          axis: {
            labelAngle: 0,
            values:
              dataTimeline && dataTimeline.length > 2
                ? [
                    dataTimeline[corrParams.timeFrom],
                    dataTimeline[corrParams.timeTo],
                  ]
                : [],
          },
        },
      },
      resolve: { scale: { y: "independent" } },
      layer: [
        {
          mark: {
            type: "bar",
            opacity: displayChange ? 0.4 : 0,
            blend: "multiply",
            color: COLOR_SECONDARY1,
          },
          encoding: {
            y: {
              field: "incidenceChange",
              type: "quantitative",
              title: displayChange ? "increase" : null,
              axis: displayChange,
            },
          },
        },
        {
          mark: {
            type: "line",
            opacity: displayIncidence ? 0.8 : 0,
            color: COLOR_SECONDARY1,
          },
          encoding: {
            y: {
              field: "incidenceValue",
              type: "quantitative",
              title: displayIncidence ? "incidence" : null,
              axis: displayIncidence,
            },
          },
        },
      ],
    };

    if (displayIncidence && hlNeighborId) {
      specObj.layer.push({
        mark: {
          type: "line",
          opacity: 0.8,
          color: COLOR_PRIMARY,
          blend: "multiply",
        },
        encoding: {
          y: {
            field: "hlNeighborValue",
            type: "quantitative",
            title: null,
            axis: false,
          },
        },
      });
    }
    if (displayChange && hlNeighborId) {
      specObj.layer.push({
        mark: {
          type: "bar",
          opacity: 0.4,
          color: COLOR_PRIMARY,
          blend: "multiply",
        },
        encoding: {
          y: {
            field: "hlNeighborChangeValue",
            type: "quantitative",
            title: null,
            axis: false,
          },
        },
      });
    }
    return specObj;
  }, [rData, hlNeighborId, displayChange, displayIncidence]);

  interface IOption {
    value: string;
    label: string;
  }
  const regionOptions: IOption[] = [
    {
      label: "no region selected",
      value: "",
    },
  ];

  Object.keys(dataIncidence).forEach((diId: any) => {
    regionOptions.push({
      label: dataIncidence[diId].name,
      value: dataIncidence[diId].region,
    });
  });

  const CustomSelection = (props: SingleValueProps<IOption, false>) => {
    console.log(props);

    const { data } = props;
    const region = dataIncidence[props.data.value];
    return (
      // @ts-ignore
      <components.SingleValue {...props}>
        {data.value ? (
          <StyledTooltipContent>
            <StyledTooltipCountryTag countryCode={region.country}>
              {region.country}
            </StyledTooltipCountryTag>
            <StyledTooltipName>{region.name}</StyledTooltipName>
          </StyledTooltipContent>
        ) : (
          <StyledTooltipContent>{data.label}</StyledTooltipContent>
        )}
      </components.SingleValue>
    );
  };
  const CustomOption = (props: OptionProps<IOption, false>) => {
    const { onMouseMove, onMouseOver, ...rest } = props.innerProps;

    //const newProps = Object.assign(props, { innerProps: rest });

    const data: any = props.data;
    const region = dataIncidence[data.value];

    return (
      <components.Option {...props}>
        {/* {...newProps}> */}
        <StyledOption
          onClick={() => {
            handleClickedRegion(data.value);
          }}
          //isSelected={corrParams.regId === data.value}
          //{...innerProps}
        >
          {region ? (
            <StyledTooltipContent>
              <StyledTooltipCountryTag countryCode={region.country}>
                {region.country}
              </StyledTooltipCountryTag>
              <StyledTooltipName>{region.name}</StyledTooltipName>
            </StyledTooltipContent>
          ) : (
            <StyledTooltipContent>{data.label}</StyledTooltipContent>
          )}
        </StyledOption>
      </components.Option>
    );
  };

  return (
    <StyledInfo>
      <StyledInfoButtonGroup>
        <div className="buttons has-addons">
          <button
            key="import"
            className={`button is-small  ${
              debouncedParameters.regionMode === regionModes.c_i.id && "is-link"
            }`}
            onClick={() => handleClickedRegionMode(regionModes.c_i.id)}
          >
            <BiImport />
            {t("correlation", `regionparameter-${regionModes.c_i.id}`, lang)}
          </button>
          <button
            key="export"
            className={`button is-small  ${
              debouncedParameters.regionMode === regionModes.c_e.id && "is-link"
            }`}
            onClick={() => handleClickedRegionMode(regionModes.c_e.id)}
          >
            <BiExport />
            {t("correlation", `regionparameter-${regionModes.c_e.id}`, lang)}
          </button>
        </div>
      </StyledInfoButtonGroup>
      <StyledInfoSelectedRegion>
        <StyledRegionSelector>
          <Title size="xs" marginBottom={0}>
            {t("correlation", "region-list", lang)}
          </Title>
          <Select
            //defaultValue={regionOptions[0]}
            options={regionOptions}
            components={{ Option: CustomOption, SingleValue: CustomSelection }}
            // onChange={(value) => {
            //   console.log("onchange", value);
            // }}
            // onInputChange={(value) => {
            //   console.log("onInputChange", value);
            // }}
            value={regionOptions.find(
              //(ro: any) => ro.value === debouncedParameters.regId
              (ro: any) => ro.value === corrParams.regId
            )}
          />
        </StyledRegionSelector>
        {selectedRegion && (
          <StyledInfoRow>
            <StyledInfoRowItem>
              <GiPerson /> {parseInt(selectedRegion.population)}
            </StyledInfoRowItem>
            <StyledInfoRowItem>
              {/* <CiExport /> {dataRegion[corrParams.regId]["c_e"]} */}
            </StyledInfoRowItem>
            <StyledInfoRowItem>
              {/* <CiImport /> {dataRegion[corrParams.regId]["c_i"]}  */}
            </StyledInfoRowItem>
          </StyledInfoRow>
        )}

        {selectedRegion && (
          <StyledMainTimelineChart>
            {/* @ts-ignore */}
            <VegaLite spec={spec} data={rData} actions={false} />
          </StyledMainTimelineChart>
        )}
        {selectedRegion && (
          <StyledChartOptions>
            <label className="checkbox">
              <input
                type="checkbox"
                onClick={(e) => {
                  setDisplayChange(!displayChange);
                }}
                checked={displayChange}
              />
              {t("correlation", "display-change", lang)}
            </label>
            <label className="checkbox">
              <input
                type="checkbox"
                onClick={(e) => {
                  setDisplayIncidence(!displayIncidence);
                }}
                checked={displayIncidence}
              />
              {t("correlation", "display-incidence", lang)}
            </label>
          </StyledChartOptions>
        )}
      </StyledInfoSelectedRegion>
      <StyledInfoNeighbors>
        {selectedRegion && (
          <Title size="xs" marginBottom={0}>
            {t("correlation", "neighborhood-regions", lang)}
          </Title>
        )}
        {selectedRegion &&
          neighborsSorted.map((neighbor) => {
            const nHl = hlNeighborId === neighbor.region;

            const nSpec = {
              $schema: "https://vega.github.io/schema/vega-lite/v5.json",
              description: "",
              width: W_MAP_INFO_NEIGH_CHART,
              height: H_MAP_INFO_NEIGH_CHART,
              data: { name: "values" },
              encoding: {},
              config: { view: { stroke: null } },
              resolve: { scale: { y: "independent" } },
              layer: [
                {
                  mark: {
                    type: "bar",
                    opacity: 0.5,
                    color: nHl ? COLOR_PRIMARY : COLOR_SECONDARY1,
                    blend: "multiply",
                  },
                  encoding: {
                    y: {
                      field: "incidenceChange",
                      type: "quantitative",
                      title: null,
                      axis: false,
                    },
                    x: {
                      field: "date",
                      type: "nominal",
                      title: null,
                      axis: false,
                    },
                  },
                },
                {
                  mark: {
                    type: "line",
                    opacity: 0.8,
                    color: nHl ? COLOR_PRIMARY : COLOR_SECONDARY1,
                  },
                  encoding: {
                    y: {
                      field: "incidenceValue",
                      type: "quantitative",
                      title: null,
                      axis: false,
                    },
                    x: {
                      field: "date",
                      type: "nominal",
                      title: null,
                      axis: false,
                    },
                  },
                },
              ],
            };

            const nData = {
              values: neighbor.incidence.map(
                (incidenceValue: number, iIndex: number) => {
                  const notFirstWeek = iIndex !== 0;
                  const incidenceChange = notFirstWeek
                    ? incidenceValue - neighbor.incidence[iIndex - 1]
                    : 0;

                  return iIndex >= corrParams.timeFrom &&
                    iIndex <= corrParams.timeTo
                    ? {
                        date: dataTimeline[iIndex],
                        incidenceValue: incidenceValue,
                        incidenceChange:
                          incidenceChange > 0 ? incidenceChange : 0,
                      }
                    : {};
                }
              ),
            };

            return (
              <div key={neighbor.region}>
                <StyledInfoNeighbor
                  onMouseOver={() => {
                    if (neighbor.id !== hlNeighborId) {
                      setHlNeighborId(neighbor.region);
                    }
                  }}
                  onMouseOut={() => {
                    setHlNeighborId("");
                  }}
                  selected={nHl}
                >
                  <StyledInfoNeighborColumn>
                    <StyledInfoNeighborLabel
                      onClick={() => {
                        handleClickedRegion(neighbor.region);
                      }}
                    >
                      <StyledTooltipContent>
                        <StyledTooltipCountryTag countryCode={neighbor.country}>
                          {neighbor.country}
                        </StyledTooltipCountryTag>
                        <StyledTooltipName>{neighbor.name}</StyledTooltipName>
                      </StyledTooltipContent>
                    </StyledInfoNeighborLabel>
                    <StyledInfoRow>
                      <StyledInfoRowItem>
                        <GiPerson /> {Math.round(neighbor.population / 1000)}
                      </StyledInfoRowItem>
                      <StyledInfoRowItem
                        bold={corrParams.regionMode === ERegionMode.C_EXPORT}
                      >
                        <BiExport /> {neighbor["c_e"].toPrecision(2)}
                      </StyledInfoRowItem>
                      <StyledInfoRowItem
                        bold={corrParams.regionMode === ERegionMode.C_IMPORT}
                      >
                        <BiImport /> {neighbor["c_i"].toPrecision(2)}
                      </StyledInfoRowItem>
                    </StyledInfoRow>
                  </StyledInfoNeighborColumn>
                  <StyledInfoNeighborColumn>
                    <StyledNeighborChart>
                      {/* @ts-ignore */}
                      <VegaLite spec={nSpec} data={nData} actions={false} />
                    </StyledNeighborChart>
                  </StyledInfoNeighborColumn>
                </StyledInfoNeighbor>
              </div>
            );
          })}
      </StyledInfoNeighbors>
    </StyledInfo>
  );
};

const StyledMainTimelineChart = styled.div.attrs(() => ({}))``;

const StyledChartOptions = styled.div.attrs(() => ({}))`
  label {
    margin-right: 8px;
    input {
      margin-right: 3px;
    }
  }
`;

const StyledInfoSelectedRegion = styled.div.attrs(() => ({}))`
  overflow-x: visible;
  overflow-y: visible;
`;

const StyledRegionSelector = styled.div.attrs(() => ({}))`
  display: grid;
  grid-template-columns: 130px auto;
  align-items: center;

  Title {
    text-align: right;
    margin-right: 10px;
  }
`;
const StyledInfoNeighbors = styled.div.attrs(() => ({}))`
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: ${W_INFO_PADDING}px;
  height: ${H_MAP_INFO - H_MAP_INFO_SELECTED - 3 * W_INFO_PADDING}px;
  margin-right: -${W_INFO_PADDING}px;
  cursor: default;
`;

const StyledInfoNeighbor = styled.div.attrs(
  ({ selected = false }: { selected?: boolean }) => ({})
)<{ selected?: boolean }>`
  display: grid;
  grid-template-columns: ${W_INFO -
    W_MAP_INFO_NEIGH_CHART -
    2 * W_INFO_PADDING}px ${W_MAP_INFO_NEIGH_CHART}px;
  overflow-x: hidden;
  margin-top: 5px;
  color: ${({ selected = false }) => (selected ? COLOR_PRIMARY : "black")};
`;
const StyledInfoNeighborColumn = styled.div.attrs(() => ({}))``;
const StyledInfoNeighborLabel = styled.div.attrs(() => ({}))`
  cursor: pointer;
`;

const StyledInfoNeighborName = styled.div.attrs(() => ({}))`
  font-size: 13px;
  font-weight: bold;
`;
const StyledNeighborChart = styled.div.attrs(() => ({}))``;

const StyledInfoRow = styled.div.attrs(() => ({}))`
  display: inline-flex;
  font-size: 11.5px;
`;
const StyledInfoRowItem = styled.div.attrs(
  ({ bold = false }: { bold?: boolean }) => ({})
)<{ bold?: boolean }>`
  margin-right: 7px;
  color: ${(props) => (props.bold ? "black" : "grey")};
  font-weight: ${(props) => (props.bold ? "bold" : "normal")};
`;

const CorrelationMap = ({
  corrParams,
  width,
  dataRegion,
  dataMap,
  handleClickedRegion,
  handleClickedMapMode,
  lang,
  dataRegionList,
}: {
  corrParams: ICorrelationParams;
  width: number;
  dataRegion: ICorrelationRegionResponse;
  dataMap: ICorrelationMapResponse;
  handleClickedRegion: Function;
  handleClickedMapMode: Function;
  lang: string;
  dataRegionList: ICorrelationRegionListResponse;
}) => {
  const center = latLng(51, 16);

  const radiusSmall = 7;
  const radiusFull = 12;
  const radiusInner = 6;

  const [hlRegionId, setHlRegionId] = useState<false | string>(false);

  const selectedRegionAdmin: false | LatLngExpression = useMemo(() => {
    if (corrParams.regId) {
      return regionsAdmin[corrParams.regId];
    }
    return false;
  }, [corrParams.regId]);

  const neighborhoodIds = useMemo(() => {
    return Object.keys(dataRegion);
  }, [dataRegion]);

  const createMapElementsEventHandlers = (regionId: string) => ({
    click: (e: any) => {
      handleClickedRegion(regionId);
    },
    mouseover: (e: any) => {
      setHlRegionId(regionId);
    },
    mouseout: (e: any) => {
      setHlRegionId(false);
    },
  });

  const regionTooltip = (region: any) => {
    return (
      <Tooltip sticky pane="region-tooltip">
        {region ? (
          <StyledTooltipContent>
            <StyledTooltipCountryTag countryCode={region.country}>
              {region.country}
            </StyledTooltipCountryTag>
            <StyledTooltipName>{region.name}</StyledTooltipName>
          </StyledTooltipContent>
        ) : (
          ""
        )}
      </Tooltip>
    );
  };

  return (
    <StyledMapWrapper>
      <MapContainer
        center={center}
        zoom={7}
        scrollWheelZoom={false}
        style={{ width: width, height: H_MAP_INFO }}
      >
        <MapLegend width={350}>
          <MapLegendColumns>
            <MapLegendColumn>
              <MapLegendTitle>
                {t("correlation", "map-mode", lang)}
              </MapLegendTitle>
              <MapLegendContent>
                <div style={{ textAlign: "right", marginBottom: "5px" }}>
                  <div className="select">
                    <select
                      style={{ width: "200px" }}
                      value={corrParams.mapMode}
                      onChange={(e) => handleClickedMapMode(e.target.value)}
                    >
                      {Object.values(mapModes).map((mapMode: any) => {
                        return (
                          <option key={mapMode.id} value={mapMode.id}>
                            {t(
                              "correlation",
                              `mapparameter-${mapMode.id}`,
                              lang
                            )}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div>
                  {mapModes[corrParams.mapMode].legendDomain.map(
                    (val: number) => {
                      const color =
                        mapModes[corrParams.mapMode].colorScheme(val);
                      return (
                        <MapLegendLine
                          key={val}
                          style={{ display: "inline-grid" }}
                        >
                          <MapLegendLineText>{val}</MapLegendLineText>
                          <MapLegendLineBox color={color.hex()} />
                        </MapLegendLine>
                      );
                    }
                  )}
                </div>
              </MapLegendContent>
            </MapLegendColumn>
          </MapLegendColumns>
        </MapLegend>
        <Pane
          name="regions-selected"
          style={{ zIndex: 600, mixBlendMode: "multiply" }}
        ></Pane>
        <Pane name="region-tooltip" style={{ zIndex: 1000 }}></Pane>
        <Pane name="selection-polylines" style={{ zIndex: 300 }}></Pane>
        <Pane
          name="region-circles"
          style={{ zIndex: 500, mixBlendMode: "normal" }}
        >
          {regions.features.map((feature: IRegion) => {
            const regionId = feature.properties.region;

            const regionValue = dataMap[regionId];
            const regionColor = regionValue
              ? mapModes[corrParams.mapMode].colorScheme(regionValue).hex()
              : "darkgrey";

            let mode: string = "normal";
            const regionAdmin = regionsAdmin[regionId];

            const country = Object.keys(dataIncidence).includes(regionId)
              ? dataIncidence[regionId].country
              : "de";

            const neighborIds = dataNeighbors[regionId];

            const hightlighted = hlRegionId === regionId;
            const hightlightedNeighbor =
              hlRegionId && dataNeighbors[hlRegionId].includes(regionId);

            if (regionId === corrParams.regId) {
              mode = "selected";
            } else if (corrParams.regId && neighborhoodIds.includes(regionId)) {
              mode = "selectedNeighbor";
            } else if (
              corrParams.regId &&
              !neighborhoodIds.includes(regionId)
            ) {
              mode = "dimm";
            }

            const fillOpacity: { [key: string]: number } = {
              selected: 1,
              selectedNeighbor: 1,
              dimm: 0.8,
              normal: 0.9,
            };

            const weight: { [key: string]: number } = {
              selected: 2,
              selectedNeighbor: 1.5,
              dimm: 0.5,
              normal: 0.8,
            };
            // const color: { [key: string]: string } = {
            //   selected: COLOR_SECONDARY1,
            //   selectedNeighbor: COLOR_SECONDARY1,
            //   dimm: "black",
            //   normal: "black",
            // };

            const radius: { [key: string]: number } = {
              selected: radiusFull,
              selectedNeighbor: radiusFull,
              dimm: radiusSmall,
              normal: radiusSmall,
            };
            const region = dataRegionList[regionId];

            return regionAdmin ? (
              <LayerGroup key={`variable-${regionId}`}>
                <CircleMarker
                  eventHandlers={createMapElementsEventHandlers(regionId)}
                  center={regionAdmin}
                  radius={radius[mode]}
                  pathOptions={{
                    stroke: true,
                    weight: weight[mode],
                    color: getCountryColor(country),
                    opacity: 1,
                    fillOpacity: fillOpacity[mode],
                    fillColor: regionColor,
                  }}
                >
                  {regionTooltip(region)}
                </CircleMarker>
              </LayerGroup>
            ) : null;
          })}
        </Pane>
        <Pane name="polygons" style={{ zIndex: 200, mixBlendMode: "multiply" }}>
          {regions.features.map((feature: IRegion) => {
            const regionId = feature.properties.region;

            const country = Object.keys(dataIncidence).includes(regionId)
              ? dataIncidence[regionId].country
              : "de";

            return (
              <Polygon
                key={regionId}
                positions={feature.geometry.coordinates as LatLngExpression[][]}
                pathOptions={{
                  color: "white",
                  weight: 2,
                  stroke: true,
                  fillColor: getCountryColor(country),
                  fillOpacity: 0.07,
                }}
              />
            );
          })}
        </Pane>

        {/* hl links */}
        {hlRegionId && (
          <Pane
            name="highlight-links"
            style={{ zIndex: 400, mixBlendMode: "normal" }}
          >
            {dataNeighbors[hlRegionId].map((linkId: string) => {
              const hlAdmin = regionsAdmin[hlRegionId];
              const linkAdmin = regionsAdmin[linkId];

              if (hlAdmin && linkAdmin) {
                return (
                  <Polyline
                    key={`arrows-${hlRegionId}-${linkId}`}
                    pathOptions={{
                      weight: 0.5,
                      color: COLOR_PRIMARY,
                    }}
                    positions={[hlAdmin, linkAdmin]}
                  />
                );
              } else {
                console.log(hlRegionId);
                console.log(linkId);
                return null;
              }
            })}
          </Pane>
        )}
        {/* <Pane
          name="background-links"
          style={{ zIndex: 200, mixBlendMode: "normal" }}
        >
          {Object.keys(dataNeighbors).map((regionId: string) => {
            const regionAdmin = regionsAdmin[regionId];
            const neighborIds = dataNeighbors[regionId];

            return (
              <LayerGroup key={`links-background-${regionId}`}>
                {neighborIds.map((neighborId: string) => {
                  return (
                    <Polyline
                      key={`arrows-${regionId}-${neighborId}`}
                      pathOptions={{
                        weight: 0.1,
                        color: "black",
                      }}
                      positions={[regionsAdmin[neighborId], regionAdmin]}
                    />
                  );
                })}{" "}
              </LayerGroup>
            );
          })}
        </Pane> */}
        <Pane
          name="corr-circles"
          style={{ zIndex: 500, mixBlendMode: "normal" }}
        >
          {corrParams.regId &&
            Object.keys(dataRegion).map((regionId: string) => {
              const regionFeature = regions.features.find(
                (feat: any) => feat.properties.region === regionId
              );
              const region = dataIncidence[regionId];
              const regionAdmin = regionsAdmin[regionId];
              const linkValue = dataRegion[regionId][corrParams.regionMode];
              if (regionFeature && regionAdmin) {
                //console.log(regionAdmin);

                //const lineW = 10 * linkValue;
                //const arrowHeadW = 30 * linkValue;
                const radius = radiusInner * linkValue;

                const coordinates =
                  corrParams.regionMode === ERegionMode.C_EXPORT
                    ? [selectedRegionAdmin, regionAdmin]
                    : [regionAdmin, selectedRegionAdmin];

                return (
                  <LayerGroup key={`arrows-${regionId}`}>
                    <PolylineDecorator
                      key={`arrows-${regionId}-${Math.random()}`}
                      lineWidth={2}
                      arrowHeadWidth={15}
                      positions={coordinates}
                    />
                    <CircleMarker
                      eventHandlers={createMapElementsEventHandlers(regionId)}
                      center={regionAdmin}
                      radius={radiusInner}
                      pathOptions={{
                        stroke: true,
                        color: "black", //getCountryColor(region.country),
                        fillOpacity: 1,
                        opacity: 0.7,
                        weight: 0.5,
                        fillColor: "white",
                      }}
                    >
                      {regionTooltip(region)}
                    </CircleMarker>
                    <CircleMarker
                      eventHandlers={createMapElementsEventHandlers(regionId)}
                      center={regionAdmin}
                      radius={radius}
                      pathOptions={{
                        stroke: false,
                        fillOpacity: 0.7,
                        fillColor: "black",
                      }}
                    >
                      {regionTooltip(region)}
                    </CircleMarker>
                  </LayerGroup>
                );
              } else {
                return null;
              }
            })}
        </Pane>
        <Pane
          name="symbols"
          style={{ zIndex: 500, mixBlendMode: "normal" }}
        ></Pane>

        <Pane
          name="boundaries"
          style={{ zIndex: 200, mixBlendMode: "normal" }}
        ></Pane>

        <TileLayer
          url="https://stamen-tiles-{s}.a.ssl.fastly.net/toner-lite/{z}/{x}/{y}{r}.png"
          attribution='Map tiles by <a href="http://stamen.com">Stamen Design</a>, <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a> &mdash; Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          subdomains="abcd"
          minZoom={0}
          maxZoom={20}
        />
      </MapContainer>
    </StyledMapWrapper>
  );
};

const StyledTooltipContent = styled.div.attrs(() => ({}))`
  display: inline-flex;
  align-items: center;
  font-size: 10px;
`;
const StyledTooltipCountryTag = styled.div.attrs(
  ({ countryCode }: { countryCode: string }) => ({
    className: "",
  })
)<{ countryCode: string }>`
  background-color: ${(props) => getCountryColor(props.countryCode)};
  margin-right: 3px;
  display: inline-block;

  border-radius: 5px;
  padding: 1px 6px;
  font-weight: 800;
  color: white;
`;
const StyledTooltipName = styled.div.attrs(() => ({}))`
  font-weight: 500;
  display: inline-block;
`;

const StyledOption = styled.div.attrs(() => ({}))`
  cursor: pointer;
`;
const StyledControl = styled.div.attrs(() => ({}))`
  cursor: pointer;
`;

const StyledMapWrapper = styled.div``;

export default CorrelationPage;
