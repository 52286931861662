import React, {
  setGlobal,
  useGlobal,
  useState,
  useLayoutEffect,
  useEffect,
  useRef,
  useMemo,
} from "reactn";
import classNames from "classnames";

import { COLOR_SECONDARY1, COLOR_PRIMARY } from "variables";

import { Box } from "components/box";
import { Title1 } from "components/title1";
import { Title2 } from "components/title2";

import { translate, translateText } from "localization/translate";

//import { withRouter, RouteComponentProps } from "react-router-dom";

import styled from "styled-components";

//interface AboutPageProps extends RouteComponentProps {}
interface AboutPageProps {}

interface ISection {
  id: string;
  content: string;
  title: string;
}
const sections = [
  {
    id: "background",
    content: "background-content",
    title: "background-title",
  },
  {
    id: "platform",
    content: "platform-content",
    title: "platform-title",
  },
  {
    id: "capabilities",
    content: "capabilities-content",
    title: "capabilities-title",
  },
  {
    id: "development",
    content: "development-content",
    title: "development-title",
  },
];

const AboutPage = ({}: AboutPageProps) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [wrapperWidth, setWrapperWidth] = useState(0);

  const [activeSection, setActiveSection] = useState<ISection>(sections[0]);

  useEffect(() => {
    const handleResize = () => {
      if (wrapperRef.current) {
        //console.log("setting width", wrapperRef.current.offsetWidth);
        setWrapperWidth(wrapperRef.current.offsetWidth);
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
  }, [wrapperRef.current]);

  return (
    <WrapperDiv ref={wrapperRef}>
      <HeaderSection>
        <Title1>
          {translate("about", "title", {
            date: "",
          })}
        </Title1>
      </HeaderSection>
      <Box>
        <AboutWrapper>
          <MenuDiv>
            <aside className="menu">
              <p className="menu-label"></p>
              <ul className="menu-list">
                {sections.map((section: ISection) => {
                  return (
                    <li key={section.id}>
                      <MenuLink
                        active={section.id === activeSection.id}
                        onClick={() => setActiveSection(section)}
                      >
                        {translate("about", section.title)}
                      </MenuLink>
                    </li>
                  );
                })}
              </ul>
            </aside>
          </MenuDiv>
          <AboutBody>
            <AboutSection>
              <Title2>{translate("about", activeSection.title)}</Title2>
              <AboutSectionContent>
                {translate("about", activeSection.content)}
              </AboutSectionContent>
            </AboutSection>

            {/* <AboutContentSection>
                            <Title2>Financing</Title2>
                            <AboutFinances>
                                <div>
                                    <img
                                        src={
                                            require("assets/saxony-state.gif")
                                                .default
                                        }
                                        width="60px"
                                    />
                                </div>
                                <div>
                                    <p>{translate("about", "financing")}</p>
                                </div>
                            </AboutFinances>
                        </AboutContentSection> */}
          </AboutBody>
        </AboutWrapper>
      </Box>
    </WrapperDiv>
  );
};

const HeaderSection = styled.div`
  margin: 10px;
  padding-top: 20px;
  padding-bottom: 10px;
`;

const WrapperDiv = styled.div``;
const AboutWrapper = styled.div`
  padding: 0.5em;
  display: grid;
  grid-template-columns: 200px auto;
  grid-gap: 20px;
`;

const AboutBody = styled.div`
  p {
    padding-bottom: 1em;
  }
`;
const AboutSection = styled.div`
  p {
    padding-bottom: 1em;
  }
`;
const AboutSectionContent = styled.div`
  p {
    padding-bottom: 1em;
  }
`;

const MenuDiv = styled.div``;

type MenuLinkProps = {
  active: boolean;
};
const MenuLink = styled.a.attrs(({ active }: MenuLinkProps) => ({
  //className: classNames({ "is-active": active }),
}))`
  color: ${({ active }: MenuLinkProps) =>
    active ? COLOR_PRIMARY : COLOR_SECONDARY1} !important;

  font-weight: 500;
  text-decoration: ${({ active }) => (active ? "underline" : "none")};
`;

const AboutFinances = styled.div`
  display: flex;
  padding: 0.5em;

  div {
    margin-left: 0.5em;
    display: table-cell;
    p {
      max-width: 380px;
      padding-bottom: 1em;
      font-size: 1em;
      font-style: italic;
      color: grey;
    }
  }
`;
export default AboutPage;
//export default withRouter(AboutPage);
