import { Box } from "components/box";
import { Modal } from "components/modal";
import { Title } from "components/title";
import { getLanguageById, t, tDate } from "localization/translate";
import { BiNetworkChart } from "react-icons/bi";
import { BsFillCalendarRangeFill, BsInfoCircle } from "react-icons/bs";
import {
  FaBusinessTime,
  FaClock,
  FaInfo,
  FaUserClock,
  FaUserMd,
} from "react-icons/fa";

import { Grid } from "react-loading-icons";
import { MdElderly, MdGroups } from "react-icons/md";
import { Group, Layer, Rect, Stage, Text } from "react-konva";

//import { withRouter, RouteComponentProps } from "react-router-dom";
import de from "date-fns/locale/de";
import en from "date-fns/locale/en-US";
import DatePicker, { registerLocale } from "react-datepicker";
import React, { useEffect, useGlobal, useMemo, useRef, useState, useLayoutEffect } from "reactn";
import styled from "styled-components";
import {
  COLOR_GREY,
  COLOR_PRIMARY,
  COLOR_SECONDARY1,
  COLOR_SECONDARY2,
  COLOR_WHITE,
} from "variables";

registerLocale("de-DE", de);
registerLocale("en-US", en);

import api from "api";

import { ImEnter, ImWarning } from "react-icons/im";
import { RiRestartLine } from "react-icons/ri";
import { useQuery, useQueryClient } from "react-query";

// Hook
function useDebounce(value: any, delay: number) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );

  return debouncedValue;
}

type ISolution = [
  number, // expected detection time
  number, // number of groups
  number, // test interval
  number[], // group sizes
  number[], // group testing days
  number // work time
];

//interface OptimizationPageProps extends RouteComponentProps {}
interface RetirementPageProps { }

const validate = (
  value: number | string,
  minValue: number,
  maxValue: number,
  defaultValue: number
) => {
  let newValue = parseFloat(value as string);

  if (!newValue) {
    newValue = defaultValue;
  }
  if (newValue > maxValue) {
    newValue = maxValue;
  }
  if (newValue < minValue) {
    newValue = minValue;
  }
  return newValue;
};

const isValid = (
  value: number | string,
  minValue: number,
  maxValue: number
): boolean => {
  let newValue = parseFloat(value as string);
  return newValue <= maxValue && newValue >= minValue;
};

interface IInputParameterItem {
  id: string;
  defaultValue: number;
  minValue: number;
  maxValue: number;
  stepValue: number;
  group: string;
  icon: any;
  showWarningFn?: Function;
}
const inputParametersItems: IInputParameterItem[] = [
  // home group
  {
    id: "no_residents",
    group: "home",
    defaultValue: 100,
    minValue: 10,
    maxValue: 200,
    stepValue: 1,
    icon: MdElderly,
  },
  {
    id: "no_employees",
    group: "home",
    defaultValue: 20,
    minValue: 5,
    maxValue: 40,
    stepValue: 1,
    icon: FaUserMd,
  },
  {
    id: "no_contacts",
    group: "home",
    defaultValue: 5,
    minValue: 2,
    maxValue: 30,
    stepValue: 1,
    icon: BiNetworkChart,
  },
  // time group
  {
    id: "time_group",
    group: "time",
    defaultValue: 120,
    minValue: 15,
    maxValue: 180,
    stepValue: 1,
    icon: FaClock,
  },
  {
    id: "time_resident",
    group: "time",
    defaultValue: 10,
    minValue: 5,
    maxValue: 20,
    stepValue: 1,
    icon: FaUserClock,
  },
  // others group
  {
    id: "min_testing_interval",
    group: "others",
    defaultValue: 5,
    minValue: 2,
    maxValue: 7,
    stepValue: 1,
    icon: BsFillCalendarRangeFill,
  },
  {
    id: "max_group_size",
    group: "others",
    defaultValue: 30,
    minValue: 10,
    maxValue: 200,
    stepValue: 1,
    icon: MdGroups,
    showWarningFn: (params: any) => {
      if (
        params["max_group_size"] <
        params["no_residents"] / params["min_testing_interval"]
      ) {
        return "parameter-max_group_size-warning1";
      } else if (params["max_group_size"] > params["no_residents"]) {
        return "parameter-max_group_size-warning2";
      } else return false;
    },
  },
  {
    id: "max_workload",
    group: "others",
    defaultValue: 5,
    minValue: 1,
    maxValue: 25,
    stepValue: 1,
    icon: FaBusinessTime,
  },
];

const groupColors = [
  "#fbb4ae",
  "#b3cde3",
  "#ccebc5",
  "#decbe4",
  "#fed9a6",
  "#ffffcc",
  "#e5d8bd",
];

const getGroupColor = (groupNo: number) => {
  return groupColors[groupNo];
};

const entryPassword = "n2PGztBp";

const RetirementPage = ({ }: RetirementPageProps) => {
  const queryClient = useQueryClient();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [wPage, setWPage] = useState<number>(0);

  const [lang, setLang] = useGlobal("lang");

  const [helpModalOpen, setHelpModalOpen] = useState<boolean>(false);
  const [exampleModalOpen, setExampleModalOpen] = useState<boolean>(false);
  const [warningModalOpen, setWarningModalOpen] = useState<boolean>(false);

  const [entered, setEntered] = useState<boolean>(true);
  const [enterPasswordTyped, setEnterPasswordTyped] = useState<string>("");
  const [passwordModalOpen, setPasswordModalOpen] = useState<boolean>(true);
  const [displayErrorLogin, setDisplayErrorLogin] = useState<boolean>(false);

  const [extendedParamsDisplay, setExtendedParamsDisplay] =
    useState<boolean>(false);

  const tryToEnter = () => {
    if (enterPasswordTyped === entryPassword) {
      setEntered(true);
      setPasswordModalOpen(true);
    } else {
      setDisplayErrorLogin(true);
      setTimeout(() => {
        setDisplayErrorLogin(false);
      }, 2000);
    }
    setEnterPasswordTyped("");
  };

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [noWeeks, setNoWeeks] = useState<number>(4);

  const handleSelectData = (newDate: Date) => setSelectedDate(newDate);
  const handleSetNoWeeks = (newNoWeeks: number) => setNoWeeks(newNoWeeks);

  const handleChangeInputValue = (parameterId: string, newValue: number) => {
    // validate value
    const parameterItem = inputParametersItems.find(
      (ipi) => ipi.id === parameterId
    );

    if (parameterItem) {
      const validatedValue = validate(
        newValue,
        parameterItem.minValue,
        parameterItem.maxValue,
        parameterItem.defaultValue
      );
      const newParameters = { ...parameters };
      newParameters[parameterId] = validatedValue;
      setParameters(newParameters);
    }
    // set state
  };

  const parameterItemGroups: string[] = useMemo(() => {
    const nameList: string[] = [];
    inputParametersItems.forEach((inputP) => {
      if (!nameList.includes(inputP.group)) {
        nameList.push(inputP.group);
      }
    });
    return nameList;
  }, []);

  const defaultParameterItemValues = useMemo(() => {
    const defaultValues: { [key: string]: number } = {};
    inputParametersItems.forEach((inputP) => {
      defaultValues[inputP.id] = inputP.defaultValue;
    });
    return defaultValues;
  }, []);

  const [parameters, setParameters] = useState<{ [key: string]: number }>(
    defaultParameterItemValues
  );

  const handleRestartParameters = () => {
    setParameters(defaultParameterItemValues);
  };

  const parametersChanged: boolean = useMemo(() => {
    const changedParameters = inputParametersItems.filter((inputP) => {
      return parameters[inputP.id] !== inputP.defaultValue;
    });
    return changedParameters.length > 0;
  }, [parameters]);

  const debouncedParameters = useDebounce(parameters, 500);

  const {
    status: status,
    data: solution,
    error: solutionError,
    isFetching: isFetchingSolution,
  } = useQuery(
    [debouncedParameters],
    async (): Promise<ISolution> => {
      const res = await api.retirement(debouncedParameters);

      return res;
    },
    { enabled: true }
  );

  const noSolution = useMemo(() => {
    return solution && solution[0] === -1;
  }, [solution]);

  useEffect(() => {
    const handleResize = () => {
      if (wrapperRef.current) {
        //console.log("setting width", wrapperRef.current.offsetWidth);
        setWPage(wrapperRef.current.offsetWidth);
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
  }, [wrapperRef.current]);


  const renderWarningModal = () => {
    return (
      <Modal
        heading={t("retirement", "warning-title", lang) as string}
        headerColor={COLOR_PRIMARY}
        handleExit={() => {
          setWarningModalOpen(false);
        }}
        buttons={[
          {
            handleClick: () => {
              setWarningModalOpen(false);
            },
            class: "is-primary",
            text: t("retirement", "warning-agree-button", lang) as string,
          },
        ]}
        renderBody={() => (
          <React.Fragment>
            <InstructionsSection>
              <InstructionsParagraph>
                {t("retirement", "warning-text-1", lang)}
              </InstructionsParagraph>
            </InstructionsSection>
            <InstructionsSection>
              <InstructionsTitle>
                <Title size="m">
                  {t("retirement", "warning-title-2", lang)}
                </Title>
              </InstructionsTitle>
              <InstructionsParagraph>
                {t("retirement", "warning-text-2", lang) as string}
              </InstructionsParagraph>
            </InstructionsSection>
          </React.Fragment>
        )}
      />
    );
  };

  const renderExampleModal = () => {
    return (
      <Modal
        buttons={[]}
        heading={t("optimization", "example-title", lang)}
        headerColor={COLOR_SECONDARY2}
        renderBody={() => t("optimization", "example-content", lang)}
        handleExit={() => {
          setExampleModalOpen(false);
        }}
      />
    );
  };

  const renderHelpModal = () => {
    return (
      <Modal
        buttons={[]}
        heading={t("retirement", "title", lang)}
        headerColor={COLOR_SECONDARY2}
        renderBody={() => {
          return (
            <div>
              <div style={{ marginBottom: "1em" }}>
                {t("retirement", "instructions-1-text", lang)}
              </div>

              <Title size="lg">
                {t("retirement", "instructions-2-heading", lang)}
              </Title>
              <div style={{ marginBottom: "1em" }}>
                {t("retirement", "instructions-2-text", lang)}
              </div>
              <Title size="sm">
                {t("retirement", "instructions-21-heading", lang)}
              </Title>
              <div style={{ marginBottom: "1em" }}>
                {t("retirement", "instructions-21-text", lang)}
              </div>
              <Title size="sm">
                {t("retirement", "instructions-22-heading", lang)}
              </Title>
              <div style={{ marginBottom: "1em" }}>
                {t("retirement", "instructions-22-text", lang)}
              </div>
              <Title size="sm">
                {t("retirement", "instructions-23-heading", lang)}
              </Title>
              <div style={{ marginBottom: "1em" }}>
                {t("retirement", "instructions-23-text", lang)}
              </div>
              <div style={{ marginBottom: "1em" }}>
                {t("retirement", "instructions-24-text", lang)}
              </div>

              <Title size="lg">
                {t("retirement", "instructions-3-heading", lang)}
              </Title>
              <div>{t("retirement", "instructions-3-text", lang)}</div>
            </div>
          );
        }}
        handleExit={() => {
          setHelpModalOpen(false);
        }}
      />
    );
  };

  return (
    <WrapperDiv ref={wrapperRef} id="printable-content">
      {helpModalOpen && renderHelpModal()}
      {exampleModalOpen && renderExampleModal()}
      {warningModalOpen && renderWarningModal()}

      <HeaderSection>
        <HeaderSectionButtons className="field has-addons">
          {/* Instruction button */}
          <p className="control" style={{ marginBottom: "0.25rem", marginTop: "1.25rem" }}>
            <button
              className="button is-info information-button"
              onClick={() => setHelpModalOpen(true)}
            >
              <span className="icon">
                <FaInfo />
              </span>
              <span>{t("retirement", "instructions-button", lang)}</span>
            </button>
            <button
              style={{ marginLeft: '10px' }}
              className="button is-warning information-button"
              onClick={() => setWarningModalOpen(true)}
            >
              <span className="icon">
                <FaInfo />
              </span>
              <span>{t("retirement", "warning-title", lang)}</span>
            </button>
          </p>


        </HeaderSectionButtons>
        <HeaderSectionTitle>
          <Title size="xl" marginBottom={0} noTopPadding>
            {t("retirement", "title", lang)}
          </Title>
        </HeaderSectionTitle>
      </HeaderSection>
      <Box>
        {entered ? (
          <React.Fragment>
            <SColumns>
              <SInputColumn>
                <Title
                  size="m"
                  marginBottom={1}
                  noTopPadding
                  color={COLOR_SECONDARY1}
                >
                  {t("retirement", "input-section-header", lang)}
                </Title>
                {parameterItemGroups.map((parameterItemGroup) => {
                  return (
                    <StyledParameterInputGroup key={parameterItemGroup}>
                      <Title
                        size="sm"
                        marginBottom={0.5}
                        color={COLOR_SECONDARY1}
                      >
                        {t("retirement", `group-${parameterItemGroup}`, lang)}
                      </Title>
                      {inputParametersItems
                        .filter((ipi) => ipi.group === parameterItemGroup)
                        .map((parameterItem) => {
                          return (
                            <ParameterInput
                              showWarning={
                                parameterItem.showWarningFn &&
                                parameterItem.showWarningFn(parameters)
                              }
                              key={parameterItem.id}
                              value={parameters[parameterItem.id]}
                              parameter={parameterItem}
                              onChange={handleChangeInputValue}
                              ParameterIcon={parameterItem.icon}
                            />
                          );
                        })}
                    </StyledParameterInputGroup>
                  );
                })}
                <StyledRestartButton>
                  <button
                    className="button is-primary example-button"
                    onClick={() => handleRestartParameters()}
                    disabled={!parametersChanged}
                  >
                    <span className="icon">
                      <RiRestartLine />
                    </span>
                    <span>{t("retirement", "restart-button", lang)}</span>
                  </button>
                </StyledRestartButton>
              </SInputColumn>
              <SResultColumn>
                <Title
                  size="m"
                  marginBottom={1}
                  noTopPadding
                  color={COLOR_SECONDARY1}
                >
                  {t("retirement", "solution-header", lang)}
                </Title>

                {!!solutionError && (
                  <DbErrorArticleDiv>
                    <article className="message is-danger">
                      <div className="message-header">
                        <p>{t("general", "dataproblem-title", lang)}</p>
                      </div>
                      <div className="message-body">
                        {t("general", "dataproblem-text", lang)}
                      </div>
                    </article>
                  </DbErrorArticleDiv>
                )}

                {isFetchingSolution && (
                  <IsFetchingArticleDiv>
                    <Grid
                      fill={COLOR_SECONDARY1}
                      width={`${wPage / 3}px`}
                      style={{
                        margin: "auto",
                        width: "100%",
                        marginTop: "50px",
                      }}
                    />
                    <StyledLoadingText>
                      {t("retirement", "loading-text", lang)}
                    </StyledLoadingText>
                  </IsFetchingArticleDiv>
                )}

                {noSolution && (
                  <NoSolutionArticleDiv>
                    <article className="message is-primary">
                      <div className="message-header">
                        <p>{t("retirement", "no-solution-heading", lang)}</p>
                      </div>
                      <div className="message-body">
                        {t("retirement", "no-solution-text", lang)}
                      </div>
                    </article>
                  </NoSolutionArticleDiv>
                )}
                {solution && !noSolution && !isFetchingSolution && !solutionError && (
                  <React.Fragment>
                    <GroupsWrapper solution={solution} wPage={wPage} />
                    <SummaryWrapper solution={solution} />
                    <CalendarWrapper
                      handleSelectData={handleSelectData}
                      handleSetNoWeeks={handleSetNoWeeks}
                      noWeeks={noWeeks}
                      selectedDate={selectedDate}
                      solution={solution}
                      wPage={wPage}
                    />
                  </React.Fragment>
                )}
              </SResultColumn>
            </SColumns>
          </React.Fragment>
        ) : (
          // login input
          <React.Fragment>
            <Title size="sm">{t("riskcalculator", "login-text", lang)}</Title>

            <div className="field is-horizontal ">
              <div className="field-label is-normal">
                <label className="label">
                  {t("riskcalculator", "login-inputlabel", lang)}
                </label>
              </div>
              <div className="field-body has-addons">
                <div className="field">
                  <p className="control">
                    <input
                      className="input"
                      type="text"
                      value={enterPasswordTyped}
                      onChange={(e) => {
                        setEnterPasswordTyped(e.target.value);
                      }}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          tryToEnter();
                        }
                      }}
                    />
                  </p>
                  <div className="control">
                    <button
                      className="button is-link"
                      onClick={() => tryToEnter()}
                    >
                      <span className="icon">
                        <ImEnter />
                      </span>
                      <span>{t("riskcalculator", "login-accept", lang)}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <ErrorLoginMessage
              style={{ display: displayErrorLogin ? "block" : "none" }}
            >
              {t("riskcalculator", "login-error", lang)}
            </ErrorLoginMessage>
          </React.Fragment>
        )}
      </Box>
    </WrapperDiv>
  );
};

const StyledLoadingText = styled.div`
  color: ${COLOR_SECONDARY1};
  text-align: center;
  margin-top: 10px;
`;

const SColumns = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
`;
const SInputColumn = styled.div`
  border-right: 3px solid ${COLOR_SECONDARY1};
  margin: 20px;
`;
const SResultColumn = styled.div`
  margin: 20px;
`;

const ErrorLoginMessage = styled.div.attrs(({ }) => ({
  className: "notification is-danger",
}))`
  position: absolute;
  width: 100%;
  left: 0px;
  top: 3em;
`;

const DbErrorArticleDiv = styled.div`
`
const NoSolutionArticleDiv = styled.div`
`
const IsFetchingArticleDiv = styled.div`
`

//const StyledDownloadButton = styled.div``;

/**
 * Wrapper component representing one input value with slider, icon and text
 */
interface IParameterInput {
  parameter: IInputParameterItem;
  value: number;
  onChange: Function;
  ParameterIcon: React.ReactElement;
  showWarning: false | string;
}
const ParameterInput = ({
  parameter,
  value,
  onChange,
  ParameterIcon,
  showWarning,
}: IParameterInput) => {
  const [lang, setLang] = useGlobal("lang");

  const [inputValue, setInputValue] = useState<string | number>(value);

  const inputValueIsValid: boolean = useMemo(() => {
    return isValid(inputValue, parameter.minValue, parameter.maxValue);
  }, [inputValue]);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <StyledParameterInput>
      <StyledParameterInputIconTitle>
        <StyledParameterInputIcon>
          {/* @ts-ignore */}
          <ParameterIcon size={25} />
        </StyledParameterInputIcon>
        <StyledParameterInputTitle
          data-tooltip={t(
            "retirement",
            `parameter-${parameter.id}-description`,
            lang
          )}
        >
          {t("retirement", `parameter-${parameter.id}`, lang)}
        </StyledParameterInputTitle>
      </StyledParameterInputIconTitle>
      <StyledParameterInputInputs>
        <input
          className={`short input is-small ${!inputValueIsValid && "is-danger"
            }`}
          type="number"
          style={{ borderWidth: inputValueIsValid ? "0px" : "2px" }}
          step={parameter.stepValue}
          min={parameter.minValue}
          max={parameter.maxValue}
          value={inputValue}
          onBlur={(e) => {
            onChange(parameter.id, e.target.value);
          }}
          onChange={(e) => {
            setInputValue(e.target.value);

            // reset the calculation if the given value is valid
            if (
              isValid(e.target.value, parameter.minValue, parameter.maxValue)
            ) {
              onChange(parameter.id, e.target.value);
            }
          }}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              onChange(parameter.id, inputValue);
            }
          }}
        />
        <input
          className="slider is-medium"
          step={parameter.stepValue}
          min={parameter.minValue}
          max={parameter.maxValue}
          value={value}
          onChange={(e) => {
            onChange(parameter.id, e.target.value);
          }}
          type="range"
        />
        {showWarning && (
          <StyledParameterWarning>
            <StyledParameterWarningIcon>
              <ImWarning />
            </StyledParameterWarningIcon>
            <StyledParameterWarningText>
              {t("retirement", showWarning, lang)}
            </StyledParameterWarningText>
          </StyledParameterWarning>
        )}
      </StyledParameterInputInputs>
    </StyledParameterInput>
  );
};

const StyledParameterWarning = styled.div`
  color: ${COLOR_PRIMARY};
  display: inline-flex;
  align-items: center;
  margin-top: 3px;
  margin-left: 3px;
`;
const StyledParameterWarningIcon = styled.div``;
const StyledParameterWarningText = styled.div`
  margin-left: 3px;
`;

/**
 *
 */
interface IGroupsWrapper {
  solution: ISolution;
  wPage: number;
}
const GroupsWrapper = ({ solution, wPage }: IGroupsWrapper) => {
  const [lang, setLang] = useGlobal("lang");
  return (
    <StyledGroupsWrapper>
      <Title size="sm" marginBottom={0} noTopPadding color={COLOR_SECONDARY1}>
        {t("retirement", "solution-groups-heading", lang)}
      </Title>
      <StyledGroupsInfo>
        <span key={0}>{t("retirement", "solution-groups-info1", lang)}</span>
        <span key={1}>
          <b>{solution[3].length}</b>
        </span>
        <span>{t("retirement", "solution-groups-info2", lang)}</span>
        <span key={2}>
          <b>{solution[2]}</b>
        </span>
        <span key={3}>{t("retirement", "solution-groups-info3", lang)}</span>
      </StyledGroupsInfo>
      {/* @ts-ignore */}
      <StyledGroupLines wColumn={wPage}>
        {solution[3].map((solutionGroup, si) => {
          return (
            <GroupLine key={si} groupNo={si + 1} groupSize={solutionGroup} />
          );
        })}
      </StyledGroupLines>
    </StyledGroupsWrapper>
  );
};

const StyledGroupsWrapper = styled.div`
  margin-bottom: 20px;
`;

const StyledGroupLines = styled.div`
  display: ${(props: any) => (props.wColumn > 1200 ? "grid" : "block")};
  grid-template-columns: 50% 50%;
  margin: 10px;
`;

/**
 *
 */
interface IGroupLine {
  groupNo: number;
  groupSize: number;
}
const GroupLine = ({ groupNo, groupSize }: IGroupLine) => {
  const [lang, setLang] = useGlobal("lang");
  return (
    <StyledGroupLine>
      <StyledGroupLineSymbol color={getGroupColor(groupNo - 1)}>
        {`${t("retirement", "solution-groups-group", lang)} ${groupNo}`}
      </StyledGroupLineSymbol>
      <StyledGroupLineText>
        <span>{t("retirement", "solution-groups-consists", lang)}</span>
        <span>
          <b>{groupSize}</b>
        </span>
        <span>{t("retirement", "solution-groups-residents", lang)}</span>
      </StyledGroupLineText>
    </StyledGroupLine>
  );
};

const StyledGroupLine = styled.div`
  display: block;
  margin-left: 10px;
  margin-bottom: 5px;
`;

const StyledGroupLineSymbol = styled.div`
  background-color: ${(props: any) => props.color};
  padding: 2px 5px;
  color: ${COLOR_GREY};
  display: inline-block;
  margin-right: 5px;
  font-weight: 500;
  font-size: 0.9em;
`;
const StyledGroupLineText = styled.div`
  display: inline-block;
  span {
    margin-right: 2.5px;
  }
`;

/**
 *
 */
interface ISummaryWrapper {
  solution: ISolution;
}
const SummaryWrapper = ({ solution }: ISummaryWrapper) => {
  const [lang, setLang] = useGlobal("lang");
  return (
    <React.Fragment>
      <Title size="sm" marginBottom={0} noTopPadding color={COLOR_SECONDARY1}>
        {t("retirement", "solution-summary-heading", lang)}
      </Title>
      <StyledSummaryValues>
        <SummaryValue
          barColor="is-primary"
          key="detection"
          label={t("retirement", "solution-summary-detection", lang) as string}
          value={solution[0] * 24}
          valueMin={12}
          valueMax={84}
          unit={t("retirement", "solution-hours-abbreviation", lang) as string}
          infoTooltip={
            t(
              "retirement",
              "solution-summary-detection-tooltip",
              lang
            ) as string
          }
        />
        <SummaryValue
          barColor="is-info"
          key="workload"
          label={t("retirement", "solution-summary-workload", lang) as string}
          value={solution[5] * 100}
          valueMin={1}
          valueMax={25}
          unit="%"
        />
      </StyledSummaryValues>
    </React.Fragment>
  );
};
/**
 *
 */
interface ISummaryValue {
  label: string;
  value: number;
  valueMin: number;
  valueMax: number;
  barColor: string;
  unit: string;
  infoTooltip?: string;
}
const SummaryValue = ({
  label,
  value,
  valueMin,
  valueMax,
  barColor,
  unit,
  infoTooltip = "",
}: ISummaryValue) => {
  const valueBar = ((value - valueMin) / (valueMax - valueMin)) * 100;
  return (
    <StyledSummaryValue>
      <StyledSummaryValueText>
        <StyledSummaryValueTextValue>
          {value.toPrecision(2)}
        </StyledSummaryValueTextValue>
        <StyledSummaryValueTextRest>
          {label}
          {infoTooltip && (
            <StyledSummaryValueTextRestInfo
              className="has-tooltip-arrow text-center has-tooltip-multiline"
              data-tooltip={infoTooltip}
            >
              <BsInfoCircle />
            </StyledSummaryValueTextRestInfo>
          )}
        </StyledSummaryValueTextRest>
      </StyledSummaryValueText>
      <StyledSummaryValueProgress>
        <StyledSummaryValueProgressValueMin>
          {`${valueMin}${unit}`}
        </StyledSummaryValueProgressValueMin>
        <StyledSummaryValueProgressBar>
          <progress
            className={`progress ${barColor}`}
            value={valueBar}
            max={100}
          >
            {value.toPrecision(2)}
          </progress>
        </StyledSummaryValueProgressBar>
        <StyledSummaryValueProgressValueMax>
          {`${valueMax}${unit}`}
        </StyledSummaryValueProgressValueMax>
      </StyledSummaryValueProgress>
    </StyledSummaryValue>
  );
};

const StyledSummaryValue = styled.div`
  margin-bottom: 20px;
`;
const StyledSummaryValueText = styled.div`
  display: block;
`;
const StyledSummaryValueTextValue = styled.div`
  display: inline-flex;
  font-weight: bold;
`;
const StyledSummaryValueTextRest = styled.div`
  display: inline;
  margin-left: 5px;
`;
const StyledSummaryValueTextRestInfo = styled.div`
  color: ${COLOR_SECONDARY2};
  display: inline-flex;
  svg {
    vertical-align: text-top;
    margin-left: 5px;
  }
`;

const StyledSummaryValueProgress = styled.div`
  display: grid;
  grid-template-columns: 50px 250px 50px;
  width: min-content;
  margin-top: 3px;
`;
const StyledSummaryValueProgressValueMin = styled.div`
  text-align: right;
  margin-right: 3px;
  font-weight: 300;
  font-size: 0.9em;
  color: ${COLOR_GREY};
`;
const StyledSummaryValueProgressValueMax = styled.div`
  text-align: left;
  margin-left: 3px;
  font-weight: 300;
  font-size: 0.9em;
  color: ${COLOR_GREY};
`;
const StyledSummaryValueProgressBar = styled.div`
  width: 250px;
  margin-top: 3.5px;
`;

const StyledRestartButton = styled.div``;

/**
 * Calendar Wrapper
 */
interface ICalendarWrapper {
  solution: ISolution;
  wPage: number;
  selectedDate: Date;
  handleSelectData: Function;
  noWeeks: number;
  handleSetNoWeeks: Function;
}
const CalendarWrapper = ({
  solution,
  wPage,
  selectedDate,
  handleSelectData,
  noWeeks,
  handleSetNoWeeks,
}: ICalendarWrapper) => {
  const [lang, setLang] = useGlobal("lang");

  const timeFormat = getLanguageById(lang).timeFormat;

  const calendarFirstDate = useMemo(() => {
    const firstDayInWeek = new Date(selectedDate);
    const day = firstDayInWeek.getDay();
    const diff = firstDayInWeek.getDate() - day + (day == 0 ? -6 : 1);
    return new Date(firstDayInWeek.setDate(diff));
  }, [selectedDate]);

  const calendarDays = useMemo(() => {
    const days: any = [];

    const noDisplayWeeks =
      calendarFirstDate.getDate() === selectedDate.getDate()
        ? noWeeks
        : noWeeks + 1;

    const lastDateOfAnalysis = new Date(selectedDate);
    lastDateOfAnalysis.setDate(selectedDate.getDate() + 7 * noWeeks - 1);

    [...Array(noDisplayWeeks).keys()].forEach((weekNo) => {
      [...Array(7).keys()].forEach((dayNo) => {
        const dayDelta = weekNo * 7 + dayNo;

        const cellDate = new Date(calendarFirstDate);
        cellDate.setDate(cellDate.getDate() + dayDelta);

        const pastDate =
          cellDate < selectedDate || cellDate > lastDateOfAnalysis;

        //const pastDelta = selectedDate.getDate() - calendarFirstDate.getDate();

        const afterStartDelta =
          (cellDate.valueOf() - selectedDate.valueOf()) / (1000 * 60 * 60 * 24);

        let cellGroup: false | number = false;
        const solutionInterval = solution[2];

        solution[4].forEach((groupDay, groupId: number) => {
          const groupDelta = solution[4][groupId] - 1;

          if (!pastDate) {
            //console.log(cellDate, afterStartDelta % solutionInterval);
            if (Math.round(afterStartDelta % solutionInterval) === groupDelta) {
              cellGroup = groupId;
            }
          }
        });

        days.push({
          dayNo,
          weekNo,
          cellGroup,
          pastDate,
          cellDate,
          dateText: tDate(calendarFirstDate, lang, {
            delta: {
              days: dayDelta,
            },
            format: {
              month: "short",
              year: undefined,
            },
          }),
        });
      });
    });
    return days;
  }, [solution, noWeeks, selectedDate]);

  return (
    <React.Fragment>
      <Title size="sm" marginBottom={0} noTopPadding color={COLOR_SECONDARY1}>
        {t("retirement", "solution-calendar-heading", lang)}
      </Title>
      <StyledCalendarInputRow>
        <StyledCalendarInput>
          <StyledCalendarInputLabel>
            {t("retirement", "solution-calendar-startingdate", lang)}
          </StyledCalendarInputLabel>
          <StyledCalendarInputValue>
            <DatePicker
              customInput={
                <button className="button is-small is-link is-outlined">
                  <span className="icon is-small">
                    <i className="fas fa-calendar"></i>
                  </span>
                  <span>{tDate(selectedDate, lang)}</span>
                </button>
              }
              selected={selectedDate}
              onChange={(date: any) => {
                handleSelectData(date);
              }}
              locale={timeFormat}
            />
          </StyledCalendarInputValue>
        </StyledCalendarInput>
        <StyledCalendarInput>
          <StyledCalendarInputLabel>
            {t("retirement", "solution-calendar-noweeks", lang)}
          </StyledCalendarInputLabel>
          <StyledCalendarInputValue>
            <div className="select is-small is-link">
              <select
                value={noWeeks}
                onChange={(e) => handleSetNoWeeks(parseInt(e.target.value))}
              >
                {[3, 4, 5, 6, 7, 8].map((weekNo) => {
                  return (
                    <option key={weekNo} value={weekNo}>
                      {weekNo}
                    </option>
                  );
                })}
              </select>
            </div>
          </StyledCalendarInputValue>
        </StyledCalendarInput>
      </StyledCalendarInputRow>
      <StyledCalendar>
        <Calendar
          wPage={wPage}
          calendarDays={calendarDays}
          calendarFirstDate={calendarFirstDate}
          selectedDate={selectedDate}
          noWeeks={noWeeks}
        />
      </StyledCalendar>
    </React.Fragment>
  );
};

const StyledCalendarInputRow = styled.div`
  display: inline-block;
`;
const StyledCalendarInput = styled.div`
  display: inline-flex;
  margin-right: 1em;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
`;
const StyledCalendarInputLabel = styled.div`
  margin-right: 10px;
  align-self: center;
`;
const StyledCalendarInputValue = styled.div`
  input,
  select {
    width: 100px;
  }
`;
const StyledCalendar = styled.div``;

/**
 *  Calendar
 */
interface ICalendar {
  selectedDate: Date;
  calendarFirstDate: Date;
  calendarDays: any[];
  noWeeks: number;
  wPage: number;
}
const Calendar = ({
  selectedDate,
  calendarFirstDate,
  calendarDays,
  noWeeks,
  wPage,
}: ICalendar) => {
  const [lang, setLang] = useGlobal("lang");

  const sizes = {
    weekH: 60,
    marginR: 100,
    marginL: 0,
    marginT: 30,
    weekNoTextW: 0,
    dayNoTextH: 10,
    cellTextMarginTop: 15,
    groupM: 4,
  };

  const cellW: number = useMemo(() => {
    return (wPage / 2 - sizes.marginR - sizes.marginL - sizes.weekNoTextW) / 7;
  }, [wPage]);

  const cellX = (dayNo: number): number => {
    return sizes.marginL + sizes.weekNoTextW + cellW * dayNo;
  };
  const cellY = (weekNo: number): number => {
    return sizes.marginT + sizes.dayNoTextH + sizes.weekH * weekNo;
  };

  return (
    <div>
      <Stage
        width={wPage / 2}
        height={
          sizes.marginT + sizes.dayNoTextH + sizes.weekH * (noWeeks + 1) + 10
        }
      >
        <Layer key="weekdays-labels">
          {[...Array(7).keys()].map((dayNoInWeek) => {
            return (
              <Text
                key={`day${dayNoInWeek}`}
                width={cellW}
                height={sizes.dayNoTextH}
                align="center"
                verticalAlign="middle"
                fill={COLOR_GREY}
                y={sizes.marginT - sizes.dayNoTextH}
                x={cellX(dayNoInWeek)}
                text={tDate(calendarFirstDate, lang, {
                  delta: {
                    days: dayNoInWeek,
                  },
                  format: {
                    day: undefined,
                    month: undefined,
                    year: undefined,
                    weekday: "short",
                  },
                })}
              />
            );
          })}
        </Layer>
        <Layer>
          {calendarDays.map((cellData) => {
            const x = cellX(cellData.dayNo);
            const y = cellY(cellData.weekNo);

            const groupLabel =
              cellW > 60
                ? `${t("retirement", "solution-groups-group", lang)} ${cellData.cellGroup + 1
                }`
                : `G ${cellData.cellGroup + 1}`;

            return (
              <Group key={`cell-${cellData.weekNo}|${cellData.dayNo}`}>
                {!cellData.pastDate && (
                  <Rect
                    y={y}
                    x={x}
                    width={cellW}
                    height={sizes.weekH}
                    stroke={COLOR_GREY}
                    fill={cellData.pastDate ? "white" : COLOR_WHITE}
                    strokeWidth={cellData.pastDate ? 0 : 2}
                  />
                )}
                {cellData.cellGroup !== false && (
                  <Group key={`group-${cellData.weekNo}|${cellData.dayNo}`}>
                    <Rect
                      y={y + sizes.weekH / 2 + sizes.groupM}
                      x={x + sizes.groupM}
                      width={cellW - 2 * sizes.groupM}
                      height={sizes.weekH / 2 - 2 * sizes.groupM}
                      fill={getGroupColor(cellData.cellGroup)}
                      strokeWidth={0}
                    />
                    <Text
                      y={y + sizes.weekH / 2 + sizes.groupM + 2}
                      x={x + sizes.groupM}
                      height={sizes.weekH / 2 - 2 * sizes.groupM}
                      width={cellW - 2 * sizes.groupM}
                      text={groupLabel}
                      fill={COLOR_GREY}
                      fontStyle="bold"
                      verticalAlign="middle"
                      align="center"
                    />
                  </Group>
                )}
                <Text
                  y={y + sizes.cellTextMarginTop}
                  x={x}
                  text={cellData.dateText}
                  fill={COLOR_GREY}
                  width={cellW}
                  align="center"
                />
              </Group>
            );
          })}
        </Layer>
      </Stage>
    </div>
  );
};

const StyledParameterInputGroup = styled.div`
  margin-bottom: 1em;
`;
const StyledParameterInput = styled.div`
  margin-bottom: 10px;
`;
const StyledParameterInputIconTitle = styled.div`
  margin-bottom: 5px;
  margin-left: 2px;
`;
const StyledParameterInputIcon = styled.div`
  display: inline-flex;
  vertical-align: middle;
  margin-right: 5px;
`;
const StyledParameterInputTitle = styled.div.attrs(() => ({
  className: "has-tooltip-arrow text-center has-tooltip-multiline",
}))`
  display: inline-flex;
  vertical-align: middle;
`;
const StyledParameterInputInputs = styled.div`
  display: inline;
  input.short {
    width: 5em;
    font-weight: bold;
  }
  input.slider {
    width: 20em;
    margin-left: 9px;
    margin-top: 3px;
    margin-bottom: 0px;
  }
`;

const StyledGroupsInfo = styled.div`
  display: inline;
  margin-bottom: 5px;
  span {
    margin-right: 2.5px;
  }
`;

const StyledSummaryValues = styled.div`
  margin-botton: 10px;
`;

/**
 * INSTRUCTIONS
 */

const InstructionsSection = styled.div.attrs(() => ({}))`
padding - bottom: 2em;
`;
const InstructionsTitle = styled.div.attrs(() => ({}))``;
const InstructionsParagraph = styled.div.attrs(() => ({}))``;

const HeaderSection = styled.div`
margin: 10px;
padding - top: 20px;
padding - bottom: 10px;
`;

const HeaderSectionTitle = styled.div`
padding - top: 0;
`;
const HeaderSectionButtons = styled.div``;

const WrapperDiv = styled.div.attrs(() => ({
  className: "content",
}))``;

export default RetirementPage;
