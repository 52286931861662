import React, {
  setGlobal,
  useGlobal,
  useState,
  useLayoutEffect,
  useEffect,
  useRef,
  useMemo,
} from "reactn";

import parse from "html-react-parser";

import { BsNewspaper } from "react-icons/bs";

import { Box } from "components/box";
import { Title } from "components/title";

import { t } from "localization/translate";

import { Link, useParams } from "react-router-dom";

import styled from "styled-components";
import { COLOR_PRIMARY, COLOR_SECONDARY1 } from "variables";
import axios from "axios";
import { GiConsoleController } from "react-icons/gi";
import { FaBook, FaCalendarAlt } from "react-icons/fa";
import { HiArrowSmLeft, HiArrowSmRight } from "react-icons/hi";

import newsData from "./../../data/news.json";
import sourcesData from "./../../data/news-sources.json";
const { default: logoW2T } = require("./../../assets/logo-w2t.png");

const NewsPage = () => {
  const [lang, setLang] = useGlobal("lang");
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [wrapperWidth, setWrapperWidth] = useState(0);

  const params = useParams();

  //const [activeNew, setActiveNew] = useState<false | string>(false);

  useEffect(() => {
    const handleResize = () => {
      if (wrapperRef.current) {
        //console.log("setting width", wrapperRef.current.offsetWidth);
        setWrapperWidth(wrapperRef.current.offsetWidth);
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
  }, [wrapperRef.current]);

  const sortedNews = useMemo(() => {
    const sortedNewsData = newsData.sort((a: any, b: any) => {
      return (
        new Date(b.date as string).valueOf() -
        new Date(a.date as string).valueOf()
      );
    });
    return sortedNewsData;
  }, [newsData]);

  const activeNew: any = useMemo(() => {
    const hash = location.hash.substring(1);

    return newsData.find((nd) => nd.id === hash);
  }, [newsData, location.hash]);

  const activePictures: any = useMemo(() => {
    return activeNew && activeNew.pictures ? activeNew.pictures : [];
  }, [activeNew]);

  const activeSources: any[] = useMemo(() => {
    return activeNew && activeNew["sources"]
      ? sourcesData.filter((sd: any) => activeNew["sources"].includes(sd.id))
      : [];
  }, [activeNew]);

  return (
    <WrapperDiv ref={wrapperRef}>
      <HeaderSection>
        <Title size="xl" iconBefore={<BsNewspaper />} marginBottom={1}>
          {t("news", "title", lang)}
        </Title>
      </HeaderSection>
      <Box>
        <BoxContent ref={wrapperRef}>
          {activeNew ? (
            <div>
              <BackToListLink>
                <Link to={{ pathname: "/blog", hash: "" }}>
                  <HiArrowSmLeft />
                  {t("news", "back-to-list", lang)}
                </Link>
              </BackToListLink>
              <div className="box is-4" key={activeNew.id}>
                <article className="media">
                  <div className="media-content">
                    <NewHeader className="media-content">
                      <NewDate className="tag is-light has-addons">
                        <FaCalendarAlt />
                        <time>{activeNew.date}</time>
                      </NewDate>
                      <NewAuthor>
                        {t("news", "written-by", lang)}{" "}
                        <b>{activeNew.author}</b>
                      </NewAuthor>

                      <NewTitle>
                        <Title size="lg" marginBottom={0}>
                          {lang === "de"
                            ? activeNew["title-de"]
                            : activeNew["title-en"]}
                        </Title>
                      </NewTitle>
                    </NewHeader>

                    <NewText className="content">
                      {lang === "de"
                        ? parse(activeNew["long-de"])
                        : parse(activeNew["long-en"])}
                    </NewText>
                    <NewPictureList>
                      {activePictures.map(
                        (activeNewPicture: any, anpi: number) => {
                          console.log("picture");
                          return <img key={anpi} src={activeNewPicture.url} />;
                        }
                      )}
                    </NewPictureList>
                    {activeSources.length > 0 && (
                      <NewSources>
                        <NewSourceListTitle>
                          <Title
                            iconBefore={<FaBook></FaBook>}
                            size="sm"
                            marginBottom={0}
                          >
                            {t("news", "sources", lang)}
                          </Title>
                        </NewSourceListTitle>
                        <NewSourceList>
                          {activeSources.map((as: any) => {
                            return (
                              <NewSourceListItem key={as.id}>
                                <NewSourceListItemPreLabel>
                                  {lang === "de"
                                    ? as["prelabel-de"]
                                    : as["prelabel-en"]}
                                </NewSourceListItemPreLabel>
                                <NewSourceListItemLabel>
                                  <a href={as.url}>
                                    {lang === "de"
                                      ? as["label-de"]
                                      : as["label-en"]}
                                  </a>
                                </NewSourceListItemLabel>
                              </NewSourceListItem>
                            );
                          })}
                        </NewSourceList>
                      </NewSources>
                    )}
                  </div>
                </article>
              </div>
            </div>
          ) : (
            <div>
              {sortedNews.map((newData: any) => {
                const mainPicture =
                  newData["pictures"] && newData["pictures"].length > 0
                    ? (
                        newData["pictures"][0] as {
                          url: string;
                          rawUrl: string;
                          name: string;
                        }
                      ).url
                    : logoW2T;
                return (
                  <div className="box is-4 " key={newData.id}>
                    <article className="media">
                      <NewsMediaLeft className="media-left">
                        <NewsImgWrapper className="image ">
                          <NewsImg src={mainPicture} />
                        </NewsImgWrapper>
                      </NewsMediaLeft>
                      <NewMediaRight>
                        <NewHeader className="media-content">
                          <NewDate className="tag is-light has-addons">
                            <FaCalendarAlt />
                            <time>{newData.date}</time>
                          </NewDate>
                          <NewAuthor>
                            {t("news", "written-by", lang)}{" "}
                            <b>{newData.author}</b>
                          </NewAuthor>

                          <NewTitle>
                            <Title size="m" marginBottom={0}>
                              <Link
                                to={{ pathname: "/blog", hash: newData.id }}
                              >
                                {lang === "de"
                                  ? newData["title-de"]
                                  : newData["title-en"]}
                              </Link>
                            </Title>
                          </NewTitle>
                        </NewHeader>
                        <NewShortText className="content">
                          {lang === "de"
                            ? newData["short-de"]
                            : newData["short-en"]}
                          <br />
                        </NewShortText>
                        <NewLink>
                          <Link to={{ pathname: "/blog", hash: newData.id }}>
                            {t("news", "read-more", lang)}
                            <HiArrowSmRight />
                          </Link>
                        </NewLink>
                      </NewMediaRight>
                    </article>
                  </div>
                );
              })}
            </div>
          )}
        </BoxContent>
      </Box>
    </WrapperDiv>
  );
};

const BackToListLink = styled.div`
  svg {
    vertical-align: middle;
    font-size: 1.5em;
  }
`;

const NewHeader = styled.div``;
const NewAuthor = styled.div`
  font-style: italic;
`;
const NewDate = styled.div`
  svg {
    margin-right: 5px;
  }
`;
const NewTitle = styled.div`
  margin-top: 0.5em;
`;
const NewText = styled.div`
  margin-top: 1em;

  p {
    margin-bottom: 0.5em;
  }
`;
const NewShortText = styled.div``;
const NewPictures = styled.div``;

const NewsMediaLeft = styled.div`
  width: 300px;
`;
const NewMediaRight = styled.div``;
const NewLink = styled.div`
  svg {
    vertical-align: middle;
    font-size: 1.5em;
  }
`;

const NewPictureList = styled.div`
  img {
    display: inline;
    max-width: 800px;
    max-height: 250px;
    padding: 5px;
    margin: 10px 0 5px 0;
    border: 1px solid #ccc;
    object-fit: contain;
  }
`;
const NewSources = styled.div`
  padding-top: 1em;
`;
const NewSourceListTitle = styled.div`
  display: inline;
`;
const NewSourceList = styled.ul`
  list-style: inside;
`;
const NewSourceListItem = styled.li`
  padding-left: 1em;
`;
const NewSourceListItemLabel = styled.span``;
const NewSourceListItemPreLabel = styled.span`
  margin-right: 0.2em;
`;
const NewsImgWrapper = styled.figure``;
const NewsImg = styled.img`
  max-height: 250px;
  display: block;
  height: auto;
  width: 100%;
  height: 200px;
  object-fit: scale-down;
  object-position: top;
`;

const HeaderSection = styled.div`
  padding: 4rem 2rem 0rem 2rem;
`;

const BoxContent = styled.div`
  padding: 0.5em;
  min-height: 12em;
`;

const WrapperDiv = styled.div``;
const AboutContent = styled.div``;

export default NewsPage;
