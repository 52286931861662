module.exports = {
  COLOR_PRIMARY: "#e3428a",
  COLOR_SECONDARY1: "#17508b",
  COLOR_SECONDARY2: "#3bc0fc",
  COLOR_BACKGROUND: "#F3F2F9",
  COLOR_BLACK: "black",
  COLOR_GREY: "grey",
  COLOR_WHITE: "#F3F2F9",
  BOX_PADDING: 20,
  BOX_MARGIN: 10,

  INTERVALS_CASES: [
    {
      intervalNo: 1,
      from: -1000,
      to: 25,
      middleValue: 12.5,
      label: "0–⁠25",
      color: "#ffffcc",
    },
    {
      intervalNo: 2,
      from: 25,
      to: 50,
      middleValue: 37.5,
      label: "25-50",
      color: "#c7e9b4",
    },
    {
      intervalNo: 3,
      from: 50,
      to: 100,
      middleValue: 75,
      label: "50-100",
      color: "#7fcdbb",
    },
    {
      intervalNo: 4,
      from: 100,
      to: 250,
      middleValue: 175,
      label: "100-250",
      color: "#41b6c4",
    },
    {
      intervalNo: 5,
      from: 250,
      to: 500,
      middleValue: 375,
      label: "250-500",
      color: "#1d91c0",
    },
    {
      intervalNo: 6,
      from: 500,
      to: 1000,
      middleValue: 750,
      label: "500-1000",
      color: "#225ea8",
    },
    {
      intervalNo: 7,
      from: 1000,
      to: 1000000,
      middleValue: 1500,
      label: "1000-",
      color: "#0c2c84",
    },
  ],
  INTERVALS_TESTS: [
    {
      intervalNo: 1,
      from: 0,
      to: 25,
      middleValue: 12.5,

      label: "0-25",
      color: "#ffffcc",
    },
    {
      intervalNo: 2,
      from: 25,
      to: 50,
      middleValue: 37.5,
      label: "25-50",
      color: "#a1dab4",
    },
    {
      intervalNo: 3,
      from: 50,
      to: 100,
      middleValue: 75,
      label: "50-100",
      color: "#41b6c4",
    },
    {
      intervalNo: 4,
      from: 100,
      to: 250,
      middleValue: 175,
      label: "⁠100-250",
      color: "#2c7fb8",
    },
    {
      intervalNo: 5,
      from: 250,
      to: 500,
      middleValue: 375,
      label: "250-500",
      color: "#1d91c0",
    },
    {
      intervalNo: 6,
      from: 500,
      to: 1000,
      middleValue: 750,
      label: "500-1000",
      color: "#225ea8",
    },
    {
      intervalNo: 7,
      from: 1000,
      to: 1000000,
      middleValue: 1500,
      label: "1000-",
      color: "#0c2c84",
    },
  ],
};
