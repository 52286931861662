import React, {
  setGlobal,
  useGlobal,
  useState,
  useLayoutEffect,
  useEffect,
  useMemo,
  useRef,
} from "reactn";

import {
  Stage,
  Layer,
  Line,
  Arrow,
  Text,
  Circle,
  Group,
  Rect,
  TextPath,
} from "react-konva";

import Colors, {
  BOX_PADDING,
  COLOR_PRIMARY,
  COLOR_SECONDARY1,
  COLOR_SECONDARY2,
} from "variables";

import styled from "styled-components";
import { translate } from "localization/translate";

type IOptimizationLineChartProps = {
  width: number;
  height: number;
  data: any;
  tickXValues?: number[];
  thresholds: any;
  categories: { id: string; value: number; color: string }[];
  backgroundRisk: number;
  skipXSlices?: number;
  xStart?: number;
  xAxisText?: string;
  yAxisText?: string;
};

export const OptimizationLineChart = ({
  width,
  height,
  data,
  tickXValues = [],
  thresholds,
  categories,
  backgroundRisk,
  skipXSlices = 0,
  xStart = 0,
  xAxisText = "",
  yAxisText = "",
}: IOptimizationLineChartProps) => {
  const [lang, setLang] = useGlobal("lang");

  const margins = {
    t: 0,
    l: 60,
    r: 20,
    b: 40,
  };

  const tickFontSize = 14;

  const topValue = useMemo(() => {
    return backgroundRisk * 2;
  }, [backgroundRisk]);

  const tickYValues: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((v) => {
    const firstChar = Number(topValue.toExponential(0).charAt(0));

    const multiplier = firstChar < 5 ? 0.5 : 1;
    return (
      multiplier *
      Number(((v * Number(topValue.toPrecision(1))) / firstChar).toPrecision(1))
    );
  });

  const xSlices: number = useMemo(() => {
    return Object.keys(data["high"]).length || 0;
  }, [width, data]);

  const chartW = useMemo(() => {
    return width - margins.r - margins.l;
  }, [width]);

  const chartH = useMemo(() => {
    return height - margins.t - margins.b;
  }, [height]);

  const oneValueH: number = useMemo(() => {
    return chartH / topValue;
  }, [height, width, topValue]);

  const oneSliceW: number = useMemo(() => {
    return chartW / xSlices;
  }, [width, height, xSlices]);

  const xCoord = (val: number) => margins.l + oneSliceW * (val - xStart);
  const yCoord = (val: number) => margins.t + (topValue - val) * oneValueH;

  //console.log(width, xSlices, oneSliceW);
  // console.log(xCoord(1));

  const backgroundRiskY: number = useMemo(() => {
    return yCoord(backgroundRisk);
  }, [backgroundRisk]);

  //console.log(backgroundRisk, backgroundRiskY);

  const lines = useMemo(() => {
    return Object.keys(data).map((contactRateId: string) => {
      return Object.keys(data[contactRateId]).map((byValue, bi) => {
        const val = data[contactRateId][byValue];
        //console.log(val, contactRateId, byValue);
        return [xCoord(bi + skipXSlices + xStart), yCoord(val)];
      });
    });
  }, [width, data, backgroundRisk]);

  //console.log(lines);

  return (
    <ChartWrapper>
      <Stage height={height} width={width}>
        <Layer key="axis">
          <Arrow
            pointerWidth={5}
            pointerLength={5}
            points={[
              margins.l,
              height - margins.b,
              width - margins.r,
              height - margins.b,
            ]}
            stroke="black"
            fill="black"
          />
          <Arrow
            pointerWidth={5}
            pointerLength={5}
            points={[margins.l, height - margins.b, margins.l, margins.t]}
            stroke="black"
            fill="black"
          />
          {tickXValues.map((tick, ti) => {
            const tickX = xCoord(tick);
            const tw = 30;
            return (
              <Group key={ti}>
                <Text
                  fontSize={tickFontSize}
                  text={tick.toString()}
                  x={tickX - tickFontSize / 2 - tw / 2}
                  y={margins.t + chartH + tickFontSize / 2}
                  align="right"
                  width={tw}
                  fontFamily="Ubuntu"
                />
                <Line
                  points={[
                    tickX,
                    margins.t + chartH,
                    tickX,
                    margins.t + chartH + 5,
                  ]}
                  stroke="black"
                  fill="black"
                  strokeWidth={0.5}
                />
              </Group>
            );
          })}

          {tickYValues.map((tick, ti) => {
            const tickY = yCoord(tick);
            return (
              <Group key={ti}>
                <Text
                  fontSize={tickFontSize}
                  text={"" + parseFloat((tick * 100).toPrecision(2))}
                  x={0}
                  y={tickY}
                  width={margins.l - 5}
                  align="right"
                  fontFamily="Ubuntu"
                />
                <Line
                  points={[margins.l - 5, tickY, margins.l, tickY]}
                  stroke="black"
                  fill="black"
                  strokeWidth={0.5}
                />
              </Group>
            );
          })}
        </Layer>

        <Layer key="critical-area">
          <Rect
            x={margins.l}
            y={margins.t}
            width={chartW}
            height={backgroundRiskY - margins.t}
            fill={COLOR_PRIMARY}
            opacity={0.2}
          />
          <Line
            points={[
              margins.l,
              backgroundRiskY,
              chartW + margins.l,
              backgroundRiskY,
            ]}
            dash={[10, 5, 2, 5]}
            stroke={COLOR_PRIMARY}
            strokeWidth={2}
            lineJoin="round"
            lineCap="round"
          />
        </Layer>

        <Layer key="lines">
          {lines.map((line, li) => {
            return (
              <Group key={li}>
                <Line
                  key={li}
                  shadowEnabled={true}
                  shadowColor={"black"}
                  shadowBlur={3}
                  shadowOffset={{ x: 2, y: 2 }}
                  shadowOpacity={0.5}
                  stroke={categories[li].color}
                  //globalCompositeOperation="multiply"
                  points={
                    line ? line.reduce((flat, val) => flat.concat(val), []) : []
                  }
                />
              </Group>
            );
          })}
        </Layer>

        <Layer key="thresholds">
          {categories.map((crCategory) => {
            const threshold = thresholds[crCategory.id];
            const thresholdX = xCoord(threshold);
            const thresholdY = backgroundRiskY;

            //console.log(threshold, thresholdX, thresholdY);
            return thresholdX < chartW + margins.l ? (
              <Group key={crCategory.id}>
                <Circle
                  x={thresholdX}
                  y={thresholdY}
                  radius={5}
                  stroke={"black"}
                  fill={crCategory.color}
                  strokeWidth={2}
                />
                <Circle
                  x={thresholdX}
                  y={yCoord(0)}
                  radius={5}
                  stroke={"black"}
                  fill={crCategory.color}
                  strokeWidth={2}
                />
                <Line
                  stroke={crCategory.color}
                  //globalCompositeOperation="multiply"
                  points={[thresholdX, thresholdY, thresholdX, yCoord(0)]}
                  strokeWidth={1}
                  dash={[1, 1]}
                />
              </Group>
            ) : (
              false
            );
          })}
        </Layer>

        <Layer key="texts">
          <Text
            text={translate("riskcalculator", "results-yaxis-label") as string}
            x={margins.l - 60}
            y={margins.t + chartH - 20}
            fontSize={15}
            rotation={270}
            fontStyle={"normal"}
            fontFamily="Ubuntu"
          />
          <Text
            text={
              translate(
                "riskcalculator",
                "results-backgroundrisk-title"
              ) as string
            }
            x={margins.l + 20}
            y={backgroundRiskY - 25}
            fontSize={15}
            fill={COLOR_PRIMARY}
            fontStyle={"normal"}
            fontFamily="Ubuntu"
          />
          <Text
            text={xAxisText as string}
            x={margins.l + 20}
            y={chartH - margins.b + 65}
            fontSize={15}
            fontStyle={"normal"}
            fontFamily="Ubuntu"
          />
        </Layer>
      </Stage>
    </ChartWrapper>
  );
};

const ChartYAxis = styled.div`
  transform: rotate(-90deg);
  font-weight: normal;
  margin-left: 2rem;
  margin-top: 0.5rem;
  position: relative;
  top: -23em;
  left: -19em;
  svg {
    margin-right: 0.3rem;
    vertical-align: text-bottom;
  }
`;

const ChartXAxis = styled.div`
  font-weight: normal;
  margin-left: 4rem;
  margin-top: 0.5rem;
  svg {
    margin-right: 0.3rem;
    vertical-align: text-bottom;
  }
`;

const ChartWrapper = styled.div`
  position: relative;
`;
