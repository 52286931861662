import styled from "styled-components";

import React, {
  setGlobal,
  useGlobal,
  useState,
  useLayoutEffect,
  useEffect,
  useRef,
  useMemo,
} from "reactn";
import { t } from "localization/translate";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { COLOR_PRIMARY } from "variables";
import { Title } from "./title";

interface ModalProps {
  heading: string | JSX.Element | JSX.Element[];
  headerColor?: string;
  renderBody: () => React.ReactElement | string | JSX.Element | JSX.Element[];
  buttons: {
    handleClick: Function;
    class: string;
    text: string;
  }[];
  handleExit?: Function | false;
}

export const Modal = ({
  heading,
  headerColor = COLOR_PRIMARY,
  renderBody,
  buttons,
  handleExit = false,
}: ModalProps) => {
  return (
    <div className={"modal is-active"}>
      <div
        className="modal-background"
        onClick={() => {
          handleExit && handleExit();
        }}
      />
      <ModalCardDiv color={headerColor}>
        <header className={"modal-card-head"}>
          <div className="modal-card-title">
            <Title size="m" color="white" marginBottom={0}>
              {heading}
            </Title>
          </div>
          {handleExit && (
            <button
              className="delete"
              aria-label="close"
              onClick={() => {
                handleExit();
              }}
            ></button>
          )}
        </header>
        <ModalCardBody>{renderBody()}</ModalCardBody>
        {buttons.length && (
          <ModalCardFooter>
            {buttons.map((button, bi) => {
              return (
                <button
                  key={bi}
                  className={`button ${button.class}`}
                  onClick={() => {
                    button.handleClick();
                  }}
                >
                  <span className="icon">
                    <BsFillCheckCircleFill />
                  </span>
                  <span>{button.text}</span>
                </button>
              );
            })}
          </ModalCardFooter>
        )}
      </ModalCardDiv>
    </div>
  );
};

const ModalCardDiv = styled.div.attrs((props: { color: string }) => ({
  className: "modal-card",
}))`
  z-index: 40;
  header {
    background-color: ${(props) => props.color};
  }
`;

const ModalCardBody = styled.section.attrs(({}) => ({
  className: "modal-card-body",
}))`
  z-index: 40;
  .button-like {
    background-color: ${COLOR_PRIMARY};
    color: white;
    justify-content: center;
    padding: 4px 8px;
    border-radius: 2px;
    text-align: center;
  }
`;

const ModalCardFooter = styled.div.attrs(({}) => ({
  className: "modal-card-footer",
}))`
  z-index: 40;
  background-color: darkgrey;
  height: 4em;
  padding: 0.7em;
  button {
    float: right;
  }
`;
